import { createPathComponent } from "@react-leaflet/core";
import {
  MarkerClusterGroupOptions,
  markerClusterGroup as leafletMarkerClusterGroup,
} from "leaflet";
import { PropsWithChildren } from "react";

import "leaflet.markercluster";

const MarkerClusterGroup = createPathComponent(
  (props: PropsWithChildren<MarkerClusterGroupOptions>, ctx) => {
    const markerClusterGroup = leafletMarkerClusterGroup(props);

    return {
      instance: markerClusterGroup,
      context: { ...ctx, layerContainer: markerClusterGroup },
    };
  },
);

export default MarkerClusterGroup;
