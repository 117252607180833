import React, { memo, useState, useCallback } from "react";

const svgPlaceholdeUri = `data:image/svg+xml;utf8,<svg width="77" height="77" xmlns="http://www.w3.org/2000/svg"><path d="M24.5 16.25v19.333h4.833V30.75h2.417v-4.833h-2.417v-4.834h4.834v4.834H39v-4.834h4.833v4.834h4.834V16.25H24.5zm12.083 14.5v29h4.834v-4.833h4.833v4.833h4.833v-4.833h4.834v4.833h4.833v-29H36.583zm4.834 4.833h4.833v4.834h-4.833v-4.834zm9.666 0h4.834v4.834h-4.834v-4.834zM17.25 40.417V59.75h4.833v-4.833h4.834v4.833h4.833V40.417h-14.5zm4.833 4.833h4.834v4.833h-4.834V45.25zm19.334 0h4.833v4.833h-4.833V45.25zm9.666 0h4.834v4.833h-4.834V45.25z" fill-opacity="0.05" /></svg>`;

export const ImageWithLoader = memo(
  (
    props: React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >
  ) => {
    const { src, alt, className, ...rest } = props;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const handleImageLoaded = useCallback(() => {
      setLoading(false);
    }, []);

    const handleImageError = useCallback(() => {
      setLoading(false);
      setError(true);
    }, []);

    const showPlaceholder = loading || error || !src;

    return (
      <>
        {showPlaceholder && (
          <img
            alt={alt}
            src={svgPlaceholdeUri}
            className={`${className ?? ""} object-fit-contain bg-secondary`}
            {...rest}
          />
        )}
        {src ? (
          <img
            alt={alt}
            src={src}
            className={`${className ?? ""} ${
              showPlaceholder ? "d-none" : "d-block"
            }`}
            {...rest}
            onError={handleImageError}
            onLoad={handleImageLoaded}
          />
        ) : null}
      </>
    );
  }
);
