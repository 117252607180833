import React from "react";

export type CustomEventWindowProps = {
  width: number;
  x: number;
  y: number;
  height: number;
  stroke?: string;
  strokeWidth?: string | number;
  strokeDasharray?: string;
  fill?: string;
  showLeftBorder?: boolean;
  showRightBorder?: boolean;
};

const CustomEventWindow = (props: any) => {
  const {
    width,
    x,
    y,
    height,
    stroke,
    strokeWidth,
    strokeDasharray,
    fill,
    showLeftBorder = true,
    showRightBorder = true,
  } = props as CustomEventWindowProps;

  const commonStrokeProps = {
    stroke,
    strokeWidth,
    strokeDasharray,
  };

  return (
    <svg x={x} y={y} width={width} height={height}>
      {/* Fill */}
      <rect x={0} width={width} y={0} height={height} fill={fill} />

      {/* Top border */}
      <line x1={0} x2={width} y1={0} y2={0} {...commonStrokeProps} />

      {/* Bottom border */}
      <line x1={0} x2={width} y1={height} y2={height} {...commonStrokeProps} />

      {/* Left border */}
      {showLeftBorder && (
        <line x1={0} x2={0} y1={0} y2={height} {...commonStrokeProps} />
      )}

      {/* Right border */}
      {showRightBorder && (
        <line x1={width} x2={width} y1={0} y2={height} {...commonStrokeProps} />
      )}
    </svg>
  );
};

export default CustomEventWindow;
