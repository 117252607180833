import { addYears, parse, isBefore, isAfter } from "date-fns";
import { Chart } from "src/components/Portfolio/PortfolioBuilding";

export const maxFutureDate = addYears(new Date(), 5);

export function isNumber(n: any) {
  return typeof n == "number" && !isNaN(n) && isFinite(n);
}

export function isiOS() {
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    /* Hackfix for iOS 13 since it's impossible to tell there */ (navigator.platform ===
      "MacIntel" &&
      navigator.maxTouchPoints > 1)
  );
}

// We need to check for browsers on iOS since only Safari will work with downloads
export function notUsingSafariOniOS() {
  if (isiOS()) {
    // List of blacklisted browsers
    return /FxiOS|CriOS|OPR|OPT|Opera|Puffin|Edg/.test(navigator.userAgent);
  }

  return false;
}

/**
 * Function will return charts ordered based on defined assets order.
 * Charts without defined assets are ordered at the end of an array.
 * All the main charts are ordered at the begining.
 * In case the asset's order numbers are not defiend, the reltive order of chart is kept.
 */
export const orderCharts = (charts: Chart[]): Chart[] => {
  return [...charts].sort((first, second) => {
    const firstValue =
      first?.asset?.isMainGas || first?.asset?.isMainLoad || first?.showAsMain
        ? -1
        : typeof first.asset?.order === "number"
        ? first.asset.order
        : null;
    const secondValue =
      second?.asset?.isMainGas ||
      second?.asset?.isMainLoad ||
      second?.showAsMain
        ? -1
        : typeof second.asset?.order === "number"
        ? second.asset.order
        : null;

    if (
      (firstValue === null && secondValue !== null) ||
      (firstValue !== null && secondValue !== null && firstValue > secondValue)
    ) {
      return 1;
    }

    if (
      (secondValue === null && firstValue !== null) ||
      (firstValue !== null && secondValue !== null && firstValue < secondValue)
    ) {
      return -1;
    }

    return 0;
  });
};

export const lightenDarkenColor = (col: string, amt: number) => {
  let usePound = false;
  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

export const setAttributes = (
  element: HTMLElement | Element,
  attributes: Record<string, string>
): void => {
  Object.entries(attributes).forEach(([attribute, value]) => {
    element.setAttribute(attribute, value);
  });
};

export interface DateTimeFormatOptions {
  userDate?: string;
  userTime?: string;
  use24hour?: boolean;
  includeAMPM?: boolean;
  includeTimezone?: boolean;
}

const emptyPojo = {};

const getDateElements = (userDate: string) => {
  /**
   * getDateElements separates delimiters from date numbers
   * input: string e.g. "01/21/2022"
   * return: ['01', '/', '21', '/', '2022']
   */
  const splitDate: any[] = [];

  let numSearch = true;

  for (let i = 0, j = 0; i < userDate.length; i++) {
    while (
      numSearch
        ? !isNaN(parseInt(userDate[i]))
        : isNaN(parseInt(userDate[i])) && i < userDate.length
    ) {
      i++;
    }

    splitDate.push(userDate.substring(j, i));
    numSearch = !numSearch;
    j = i;
  }

  return splitDate;
};

const determineMDYOrder = (dates: Array<string>) => {
  /**
   * determineMDYOrder estimates month, day, year order based
   * on character length and number value
   * input: [string]
   * return: [1,2,0]
   */
  let m, d, y;

  for (let i = 0; i < dates.length; i += 2) {
    let n = dates[i];

    if (n.length >= 4) {
      y = i;
    } else if (parseInt(n) > 12 || m !== undefined) {
      d = i;
    } else {
      m = i;
    }
  }

  return [m, d, y];
};

export const getDateFormat = (options: DateTimeFormatOptions = emptyPojo) => {
  /**
   * getDateFormat guesses the date format from an example date string
   * it assumes the example string indicates the usage of double or single digit
   * input: string e.g. '6,15,2021'
   * return: datefns format string e.g. 'M,dd,yyyy'
   */
  const { userDate } = options;
  const defaultDateFormat = "yyyy-MM-dd";
  if (!userDate) return defaultDateFormat;

  const dateElements = getDateElements(userDate.trim());
  if (dateElements.length < 5) return defaultDateFormat;

  const order = determineMDYOrder(dateElements) || [0, 1, 2];
  const letters = ["M", "d"];

  for (let i = 0; i < letters.length; i++) {
    let count = dateElements[order[i]!].length;
    let letter = letters[i];

    while (--count) {
      letter += letter;
    }
    dateElements[order[i]!] = letter;
  }

  dateElements[order[2]!] = "yyyy";

  return dateElements.join("");
};

export const isValidTime = (userTime: string) =>
  userTime.match(regex12hr) ||
  userTime.match(regex24hr) ||
  userTime.match(regex24hrSec);

export const getTimeFormat = (options: DateTimeFormatOptions = emptyPojo) => {
  /**
   * h:mm
   * hh:mm
   * hh:mm:ss
   * 24hr use capital H
   * include am/pm by default for 12hr
   * include timezone
   */
  const { userTime, use24hour, includeAMPM, includeTimezone } = options;

  if (!userTime) return "hh:mm:ss";

  let timeElements, implied24hr;
  const letters = ["h", "m", "s"];
  const matchTimeFormat = isValidTime(userTime);

  if (Array.isArray(matchTimeFormat) && matchTimeFormat[0]) {
    timeElements = matchTimeFormat[0].split(":");

    implied24hr = parseInt(timeElements[0]) > 12;

    for (let i = 0; i < timeElements.length; i++) {
      let letter = letters[i];

      for (let j = 0; j < timeElements[i].length - 1; j++) {
        letter += letter;
      }
      timeElements[i] = letter;
    }
    timeElements = timeElements.join(":");
  } else {
    timeElements = "hh:mm:ss";
  }

  if (use24hour || implied24hr) timeElements = timeElements.replace(/h/g, "H");
  if (includeAMPM && (!implied24hr || use24hour)) timeElements += " a";
  if (includeTimezone) timeElements += " xxx";

  return timeElements;
};

// hh:mm 12-hour format, optional leading 0
const regex12hr = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/;
// HH:mm 24-hour format, optional leading 0
const regex24hr = /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
// HH:mm:ss 24-hour format with leading 0
const regex24hrSec = /(0?[0-9]|1[0-9]|2[0123]):(?:[012345]\d):(?:[012345]\d)/;

export interface LocaleOptions extends DateTimeFormatOptions {
  isoDate?: string;
  locale?: string;
}

export const getLocaleDate = (options: LocaleOptions = emptyPojo) => {
  const { isoDate, locale = "en-US" } = options;

  return isoDate
    ? new Intl.DateTimeFormat(locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(Date.parse(isoDate))
    : undefined;
};

export const getLocaleTime = (options: LocaleOptions = emptyPojo) => {
  const { isoDate, locale = "en-US" } = options;

  return isoDate
    ? new Intl.DateTimeFormat(locale, {
        timeStyle: "short",
      }).format(Date.parse(isoDate))
    : undefined;
};

export const getLocaleNumber = (
  locale: string,
  number: number,
  options?: Intl.NumberFormatOptions
) => {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    ...options,
  }).format(number);
};

export const getLocaleUSDCurrency = (
  locale: string,
  number: number,
  options?: Intl.NumberFormatOptions
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
    ...options,
  }).format(number);
};

// TODO: this should be refactored/typed in a way that it's clear it's about dates
export const compareDates = (
  dateA: string | Date | number | undefined | null,
  dateB: string | Date | number | undefined | null,
  dateFormat: string,
  direction: number
) => {
  if (dateA && dateB) {
    const dateStringA = typeof dateA !== "string" ? dateA.toString() : dateA;
    const dateStringB = typeof dateB !== "string" ? dateB.toString() : dateB;

    return direction === -1
      ? isBefore(
          parse(dateStringA, dateFormat, new Date()),
          parse(dateStringB, dateFormat, new Date())
        )
      : isAfter(
          parse(dateStringA, dateFormat, new Date()),
          parse(dateStringB, dateFormat, new Date())
        );
  }

  return false;
};
