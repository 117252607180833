import type { MarkerCluster, PointExpression } from "leaflet";
import { divIcon } from "leaflet";
import { Marker } from ".";

export const getMarkerClusterCustomIcon = (options: {
  markers: Array<Marker>;
  areMarkersSelectable: boolean;
  markersPadding?: PointExpression;
}) => {
  const getMarkerClusterIconFn = (cluster: MarkerCluster) => {
    // NOTE: Must be spread in this function, otherwise the reference is lost
    const { markers, areMarkersSelectable, markersPadding } = options;

    cluster.addEventListener("click", function () {
      cluster.zoomToBounds({
        padding: markersPadding,
      });
    });

    const count = cluster.getChildCount();

    let size = 100;
    if (count < 10) {
      size = 40;
    } else if (count >= 10 && count < 100) {
      size = 60;
    } else if (count >= 100 && count < 500) {
      size = 80;
    }

    size = areMarkersSelectable ? size : size - 10;
    const radius = size / 2;
    let clusterInnerHtmlValue = `<div class="marker-cluster" style="width:${size}px; height:${size}px;"><svg width="${size}" height="${size}" >
    <circle r="${radius}" cx="${radius}" cy=${radius} fill="#32413e" />
    <text x="${radius}" y=${
      radius + 5
    } text-anchor="middle" fill="white" font-size="0.75rem" transform="rotate(90,${radius},${radius})">${count}</text>
  </svg></div>`;

    if (areMarkersSelectable) {
      const clusterMarkers = cluster.getAllChildMarkers();
      const clusterMarkersLatLngs = clusterMarkers.map((cM) => cM.getLatLng());
      const selectedClusterMarkersCount = markers.filter(
        (marker) =>
          clusterMarkersLatLngs.filter(
            (latLng) => marker.lat === latLng.lat && marker.long === latLng.lng
          ).length > 0 && marker.selected
      ).length;

      const circumference = 2 * Math.PI * radius;
      const progress = circumference * (selectedClusterMarkersCount / count);
      const strokeWidth = 2;

      clusterInnerHtmlValue = `<div class="marker-cluster ${
        selectedClusterMarkersCount === count
          ? "marker-cluster--all-selected"
          : selectedClusterMarkersCount >= 1
          ? "marker-cluster--some-selected"
          : "marker-cluster--none-selected"
      }" style="width:${size}px; height:${size}px;"><svg width="${size}" height="${size}" >
    <circle r="${
      radius - strokeWidth
    }" cx="${radius}" cy=${radius} fill="transparent"
    stroke-width="${
      strokeWidth * 2
    }" stroke-dasharray="${progress} ${circumference}"/>
    <text x="${radius}" y=${
        radius + 5
      } text-anchor="middle" fill="white" font-size="0.75rem" transform="rotate(90,${radius},${radius})">${selectedClusterMarkersCount}/${count}</text>
  </svg></div>`;
    }

    return divIcon({
      html: clusterInnerHtmlValue,
      className: `bp-marker-cluster`,
    });
  };

  return getMarkerClusterIconFn;
};
