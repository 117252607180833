import { memo, useCallback, useMemo } from "react";
import {
  formatDistanceToNow,
  isSameYear,
  format,
  differenceInDays,
} from "date-fns";
import isEqual from "lodash-es/isEqual";
import { isBefore } from "date-fns";
import { ImageWithLoader } from "@elements/ImageWithLoader";
import { Badge, Tooltip } from "@bphxd/ds-core-react";
import { Right24, Star24 } from "@bphxd/ds-core-react/lib/icons";

export interface BuildingListItemProps {
  /**
   * Unique id,
   */
  id: string;
  /**
   * Building image.
   */
  imageUrl: string;
  /**
   * Building title.
   */
  name: string;
  /**
   * Subtitle.
   */
  siteName: string;
  /**
   * True if building is marked as favorite.
   */
  isFavorite?: boolean;
  /**
   * Date a building was last visited.
   */
  timestamp?: Date;
  /**
   * Date format for timestamp tooltip (days months years)
   */
  dateFormat?: string;
  /**
   * Time format for timestamp tooltip
   */
  timeFormat?: string;
  /**
   * Called when a item is clicked.
   */
  onClick?: () => void;
}

export const BuildingListItem = memo(
  (props: BuildingListItemProps) => {
    const {
      id,
      imageUrl,
      name,
      siteName,
      isFavorite,
      timestamp,
      dateFormat = "MMM dd",
      timeFormat = "HH:mm",
      onClick,
    } = props;

    const handleClick = useCallback(() => {
      if (typeof onClick == "function") {
        onClick();
      }
    }, [onClick]);

    const lastVisitedTime = useMemo(() => {
      if (!timestamp) return;
      const currentDate = new Date();

      if (!isSameYear(timestamp, currentDate)) {
        return format(timestamp, "MMM dd, yyyy");
      } else if (
        differenceInDays(currentDate, timestamp) <= 1 &&
        isBefore(timestamp, currentDate)
      ) {
        return `${formatDistanceToNow(timestamp)} ago`;
      } else {
        return format(timestamp, "MMM dd");
      }
    }, [timestamp]);

    return (
      <li
        className={`x5-px-4 py-4 building-list-item border-bottom d-flex align-items-center gap-4 ${
          typeof onClick === "function" ? "building-list-item--link" : ""
        }`}
        role="button"
        onClick={handleClick}
      >
        <div
          className="position-relative flex-shrink-0"
          style={{ width: "48px", height: "48px" }}
          aria-label="Building Image"
        >
          <ImageWithLoader
            src={imageUrl}
            alt={name}
            className="top-0 h-100 w-100 position-absolute"
            style={{ objectFit: "cover" }}
          />
        </div>
        <div className="flex-1 d-flex flex-wrap align-items-center gap-2 justify-content-between">
          <div className="d-flex flex-column">
            <div className="d-flex gap-2 align-items-center">
              <h3 className="fs-base m-0 fw-normal lh-sm">{name}</h3>
              {isFavorite && <Star24 className="flex-shrink-0" />}
            </div>
            <h4 className="text-secondary fw-light fs-6 lh-sm m-0">
              {siteName}
            </h4>
          </div>

          {timestamp ? (
            <>
              <Badge id={`last-visited-time-tooltip-${id}`}>
                {lastVisitedTime}
              </Badge>
              <Tooltip fade={false} target={`last-visited-time-tooltip-${id}`}>
                {format(timestamp, `${dateFormat} ${timeFormat}`)}
              </Tooltip>
            </>
          ) : null}
        </div>

        {typeof onClick === "function" && (
          <Right24 className="building-list-item__caret" />
        )}
      </li>
    );
  },
  (prev, next) => isEqual(prev, next)
);
