import React from "react";
import { fromPromise } from "@apollo/client";
import { useKeycloak } from "src/lib/KeycloakProvider";
import EnhancedApolloProvider from "src/lib/EnhancedApolloProvider";
import AuthWrapper from "src/lib/AuthWrapper";
import Routes from "./Routes";
import Dashboard from "../Dashboard/Dashboard";
import { toast } from "react-hot-toast";

function AppContent() {
  const { getTokens, tokenRefresh, logout } = useKeycloak();

  return (
    <AuthWrapper>
      <EnhancedApolloProvider
        uri={process.env.REACT_APP_API_URL as string}
        onAuthRequest={getTokens}
        // Error handler that can trigger a token refresh
        onAuthError={(_networkError, operation, forward) => {
          return fromPromise(
            tokenRefresh(1, true).catch(() => {
              logout().catch((err) => {
                return toast.error(`Logout failed. ${err}`, {
                  id: err, // toastId to prevent duplicates
                });
              });
            })
          )
            .filter((response) => response !== undefined && response !== null)
            .flatMap((tokens) => {
              const oldHeaders = operation.getContext().headers;

              const { token } = tokens as {
                idTokenParsed: Keycloak.KeycloakTokenParsed | undefined;
                refreshToken: string | undefined;
                token: string | undefined;
              };

              operation.setContext({
                headers: {
                  ...oldHeaders,
                  Authorization: `Bearer ${token || ""}`,
                },
              });

              return forward(operation);
            });
        }}
        onInit={(_client) => {
          // Here we can initialize queries with mock data if needed
        }}
        cacheConfig={{
          /**
           * config object for inMemoryCache
           * By default, an object's cache ID is the concatenation of the object's
           * __typename and id (or _id) fields, separated by a colon (:)
           * */
          typePolicies: {
            Site: {
              keyFields: ["siteId"],
            },
            Building: {
              keyFields: ["buildingId"],
            },
            AssetV1: {
              keyFields: ["assetId"],
            },
            AssetV2: {
              keyFields: ["assetId"],
            },
            CalculationsAsset: {
              keyFields: ["assetId"],
            },
            Market: {
              keyFields: ["marketId"],
            },
            MarketEvent: {
              keyFields: ["id"],
              // TODO: uncomment to set custom value for the test
              // fields: {
              //   startTime: {
              //     read() {
              //       return "2022-10-27T14:00:00+01:00";
              //     },
              //   },
              //   endTime: {
              //     read() {
              //       return null;
              //     },
              //   },
              //   expectedEndTime: {
              //     read() {
              //       return "2022-10-28T19:00:00+01:00";
              //     },
              //   },
              //   marketName: {
              //     read() {
              //       return "ERCOT";
              //     }
              //   }
              // },
            },
            MarketRevenueReading: {
              keyFields: ["market", "revenue", "timestamp"],
            },
            MeasurementParameter: {
              keyFields: ["id"],
            },
            ReadingV2: {
              keyFields: ["id"],
            },
            Submeter: {
              keyFields: ["submeterId"],
            },
            Query: {
              fields: {
                site: {
                  keyArgs: ["siteId"],
                },
              },
            },
            MarketData: {
              keyFields: ["id"],
            },
          },
        }}
      >
        <Dashboard>
          <Routes />
        </Dashboard>
      </EnhancedApolloProvider>
    </AuthWrapper>
  );
}

export default AppContent;
