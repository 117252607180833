import { differenceInDays } from "date-fns";
import inRange from "lodash-es/inRange";
import invert from "lodash-es/invert";
import {
  endOfWeek,
  startOfWeek,
  subSeconds,
  subMinutes,
  subHours,
  subDays,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns";
import {
  IntervalType,
  DateRangeType,
  MarketEventStatus,
  LabelWithDescription,
} from "ui-core";
import { uniqueId } from "lodash-es";
import { EventCategoryType } from "src/graphql/code";

export interface DownloadOptionData {
  id: string;
  label: string;
  icon: string;
  downloadParams: string[];
}

export interface MeasurementDownloadOption extends DownloadOptionData {}

export interface AssetTypeDownloadOption extends DownloadOptionData {
  alias: string;
}

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// TODO: This could be better named using a suffix "map"
export const marketEventStatus = {
  [MarketEventStatus.Announced]: 0,
  [MarketEventStatus.Confirmed]: 1,
  [MarketEventStatus.Acknowledged]: 2,
  [MarketEventStatus.Cancelled]: 3,
  [MarketEventStatus.Closed]: 10,
};

export const marketEventCategoryMap = new Map<
  EventCategoryType,
  LabelWithDescription
>([
  [
    EventCategoryType.NotificationTest,
    {
      label: "Notification Test",
      descriptionLines: [
        "Dispatch notifications test only.",
        "Curtailment is not required!",
      ],
    },
  ],
  [
    EventCategoryType.DispatchReadinessTest,
    {
      label: "Dispatch Readiness Test",
      descriptionLines: [
        "Full event dispatch test.",
        "Curtailment is required!",
      ],
    },
  ],
]);

// TODO: This could be better named using a suffix "map"
export const marketEventStatusName = invert(marketEventStatus);

export const getAssetTypeParams = (asset: string) => {
  const assetType = assetTypes.find((assetType) =>
    assetType.downloadParams.includes(asset)
  );

  if (assetType) {
    const indexOfAssetTypeInArray = assetTypes.indexOf(assetType);

    return {
      id: indexOfAssetTypeInArray,
      alias: assetType.alias,
      label: assetType.label,
      icon: assetType.icon,
    };
  }

  return {
    id: assetTypes.length + 1,
    alias: asset,
    label: asset,
    icon: "help",
  };
};

export const getAssetTypeDownloadParams = (
  asset: string
): AssetTypeDownloadOption => {
  const assetType = assetTypes.find((assetType) =>
    assetType.downloadParams.includes(asset)
  );

  if (assetType) {
    return assetType;
  }

  return {
    id: uniqueId("asset-type-download-option"),
    alias: asset,
    label: asset,
    icon: "help",
    downloadParams: [asset],
  };
};

// TODO: move & refactor entire file with TS after foldre structure changes
export const assetTypes: Array<AssetTypeDownloadOption> = [
  {
    id: uniqueId("asset-type-download-option"),
    alias: "chp",
    label: "CHP",
    icon: "engine",
    downloadParams: ["chp", "chp_v2"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "pv",
    label: "Solar",
    icon: "solarpanel",
    downloadParams: ["pv"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "fc",
    label: "Fuel Cell",
    icon: "carradiator",
    downloadParams: ["fc"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "ess",
    label: "Energy Storage",
    icon: "batteries",
    downloadParams: ["ess"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "tes",
    label: "Thermal Storage",
    icon: "heating",
    downloadParams: ["tes"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "ev",
    label: "Electric Vehicle Charging",
    icon: "chargingstation",
    downloadParams: ["ev"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "wind",
    label: "Wind",
    icon: "windturbine",
    downloadParams: ["wind"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "eload",
    label: "Electrical Load",
    icon: "energymeter",
    downloadParams: ["load", "eload"], // TODO eload only, load will be removed
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "dslgen",
    label: "Diesel-fired generator",
    icon: "dslgen",
    downloadParams: ["dslgen"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "nggen",
    label: "Gas-fired generator",
    icon: "nggen",
    downloadParams: ["nggen"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "invt",
    label: "Inverter",
    icon: "dynamo",
    downloadParams: ["invt"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "ngload",
    label: "Natural Gas Distribution",
    icon: "gasindustry",
    downloadParams: ["ngload", "ngdist"],
  },
  {
    id: uniqueId("asset-type-download-option"),
    alias: "stload",
    label: "Steam Distribution",
    icon: "watersteam",
    downloadParams: ["stload", "stdist"],
  },
];

export const getMeasurementTypesParams = (
  measurement: string
): MeasurementDownloadOption => {
  const measurementType = measurementTypes.find((measurementType) =>
    measurementType.downloadParams.includes(measurement)
  );

  if (measurementType) {
    return measurementType;
  }

  return {
    id: uniqueId("measurement-download-option"),
    label: measurement,
    icon: "help",
    downloadParams: [measurement],
  };
};

export const measurementTypes: Array<MeasurementDownloadOption> = [
  {
    id: uniqueId("measurement-download-option"),
    label: "Load & Energy asset interval data",
    icon: "load",
    downloadParams: ["load_and_energy"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Temperature sensor data",
    icon: "temperatureinside",
    downloadParams: ["inside_temperature"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Humidity sensor data",
    icon: "humidity",
    downloadParams: ["inside_humidity"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Market participation revenue",
    icon: "usdollarcircled",
    downloadParams: ["market"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Submetering",
    icon: "dashboard",
    downloadParams: ["submeter"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Gas meter data",
    icon: "gasindustry",
    downloadParams: ["gas"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Steam meter data",
    icon: "watersteam",
    downloadParams: ["steam"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Carbon emissions",
    icon: "co2",
    downloadParams: ["carbon_emissions"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Emission factors",
    icon: "co2factor",
    downloadParams: ["emission_factors"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Pressure sensor data",
    icon: "pressure",
    downloadParams: ["inside_pressure"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Charging session data",
    icon: "chargingsession",
    downloadParams: ["charging_session"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Power",
    icon: "electrical",
    downloadParams: ["power", "power_v2"],
  },
  {
    id: uniqueId("measurement-download-option"),
    label: "Energy",
    icon: "lightningbolt",
    downloadParams: ["energy", "energy_v2"],
  },
];

export const dateFormatByResolution = (
  resolution: string,
  formats: { dateFormat?: string; timeFormat?: string } = {},
  showTimeOnlyForUnderDayResolution: boolean = true
) => {
  const { dateFormat = "yyyy-MM-dd", timeFormat = "HH:mm" } = formats;

  const datetimeFormat = `${dateFormat} ${timeFormat}`;

  switch (resolution) {
    case "1min":
    case "5min":
    case "15min":
    case "1hour":
      return showTimeOnlyForUnderDayResolution ? timeFormat : datetimeFormat;
    case "1day":
    case "1week":
      return dateFormat;
    case "1month":
      return "MMM yyyy";
    case "1year":
      return "yyyy";
    default:
      return datetimeFormat;
  }
};

export const mapCustomRangeToResolution = (numOfDays: number) => {
  // 1 => 5min
  // 1-6 => 1hour
  // 7-30 => 1day
  // 31-90 => 1week
  // 91-2*365 => 1month
  // >2*365 => 1year
  if (inRange(numOfDays, 0, 2)) {
    return "5min";
  } else if (inRange(numOfDays, 2, 7)) {
    return "1day";
  } else if (inRange(numOfDays, 7, 31)) {
    return "1day";
  } else if (inRange(numOfDays, 31, 91)) {
    return "1month";
  } else if (inRange(numOfDays, 91, 731)) {
    return "1month";
  } else {
    return "1day";
  }
};

export const mapIntervalTypeToResolution = ({
  intervalType,
  startDate,
  endDate,
}: DateRangeType): "5min" | "1day" | "1month" | "1hour" => {
  switch (intervalType) {
    case "Live":
    case "Day":
      return "5min";
    case "Week":
    case "Month":
      return "1day";
    case "Quarter":
    case "Year":
    case "All":
      return "1month";
    case "Custom":
      return mapCustomRangeToResolution(
        differenceInDays(endDate || new Date(), startDate)
      );
    default:
      return "1hour";
  }
};

export const mapResolutionToHour = (
  resolution: ReturnType<typeof mapIntervalTypeToResolution>
) => {
  switch (resolution) {
    case "1day":
      return 24;
    case "1hour":
      return 1;
    case "5min":
      return 5 / 60;
    case "1month":
      return 24 * 30;
  }
};

export function domainClip(resolution: string, endDate: Date) {
  let idx = 0;
  while (parseInt(resolution[idx])) {
    idx++;
  }
  const n: number = parseInt(resolution.substr(0, idx + 1));
  resolution = resolution.substr(idx);

  switch (resolution) {
    case "sec":
      return subSeconds(endDate, n);
    case "min":
      return subMinutes(endDate, n);
    case "hour":
      return subHours(endDate, n);
    case "day":
      return subDays(endDate, n);
    case "week":
      return subWeeks(endDate, n);
    case "month":
      return subMonths(endDate, n);
    case "quarter":
      return subQuarters(endDate, n);
    case "year":
      return subYears(endDate, n);
    default:
      return endDate;
  }
}

export const mapResolutionToIntervalLabel = (resolution: string) => {
  switch (resolution) {
    case "1min":
      return "minute";
    case "5min":
      return "5 minutes";
    case "15min":
      return "15 minutes";
    case "1hour":
      return "hour";
    case "1day":
      return "day";
    case "1week":
      return "week";
    case "1month":
      return "month";
    case "1year":
      return "year";
    default:
      return "?";
  }
};

export const getInitialDateRange = (
  referenceDate = new Date()
): DateRangeType => ({
  startDate: startOfWeek(referenceDate, { weekStartsOn: 0 }),
  endDate: endOfWeek(referenceDate, { weekStartsOn: 0 }),
  intervalType: IntervalType.Week,
});

export const customAssetsMapping = new Map([
  ["chp_v2", "chp"],
  ["stdist", "stload"],
  ["ngdist", "ngload"],
  ["load", "eload"],
]);

export const filterUniqueAssets = (
  assets: string[],
  assetsMapping: Map<string, string> = customAssetsMapping
) => {
  const mappedUniqueAssets = new Set(
    assets.map(
      (originalAssetName) =>
        assetsMapping?.get(originalAssetName) || originalAssetName
    )
  );

  return assets.reduce((uniqueAssets: string[], originalAssetName) => {
    const mappedAssetName =
      assetsMapping?.get(originalAssetName) || originalAssetName;

    if (mappedUniqueAssets.has(mappedAssetName)) {
      uniqueAssets.push(originalAssetName);
      mappedUniqueAssets.delete(mappedAssetName);
    }

    return uniqueAssets;
  }, []);
};
