import React from "react";

export default function FullPageWrapper(props: React.PropsWithChildren<{}>) {
  const { children } = props;

  return (
    <div className="d-flex align-items-center justify-content-center text-center p-4 position-absolute top-0 start-0 end-0 bottom-0">
      {children}
    </div>
  );
}
