/** @format */

import React, {
  ReactNode,
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  MapContainer,
  MapContainerProps,
  ScaleControl,
  TileLayerProps,
  useMap,
} from "react-leaflet";
import L, {
  LatLngBounds,
  LatLngExpression,
  MarkerClusterGroup as LMarkerClusterGroup,
} from "leaflet";
import { Icon } from "@elements/Icon";
import TileLayerWithHeaders from "./TileLayerWithHeaders";
import AreaSelect, { AreaSelectSettings } from "./AreaSelect";
import MarkerClusterGroup from "./MarkerClusterGroup";
import { Markers } from "./Markers";
import { getMarkerClusterCustomIcon } from "./MarkerClusterCustomIcon";
import { Button, ButtonGroup } from "@bphxd/ds-core-react";

export enum ControlItem {
  "undo" = "undo",
  "redo" = "redo",
  "hand" = "hand",
  "marker" = "marker",
  "layers" = "layers",
  "radarPlot" = "radarplot",
}

export interface Tooltip {
  title: string;
  button?: {
    label: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
  icons?: string[];
  imageUrl: string;
}

export interface Marker {
  id: string | number;
  lat: number;
  long: number;
  tooltip?: Tooltip;
  selected?: boolean;
}

export interface MapProps
  extends Pick<MapContainerProps, "zoom" | "minZoom" | "maxZoom" | "center">,
    Partial<
      Pick<
        MapContainerProps,
        | "boundsOptions"
        | "bounceAtZoomLimits"
        | "boxZoom"
        | "dragging"
        | "trackResize"
        | "zoomDelta"
        | "zoomSnap"
        | "wheelPxPerZoomLevel"
        | "scrollWheelZoom"
        | "zoomControl"
      >
    >,
    Pick<
      TileLayerProps,
      | "attribution"
      | "noWrap"
      | "tileSize"
      | "zoomOffset"
      | "minNativeZoom"
      | "maxNativeZoom"
    > {
  /**
   * optional list of control menu items e.g. hand cursor
   */
  controls?: "all" | ControlItem[];
  /**
   * optional array containing bounding box corners in lat-long tuple format for visible map area
   */
  maxBounds?: LatLngExpression[];
  /**
   * optional array of marker objects containing lat & long coordinates, tooltip object
   */
  markers?: Marker[];
  /**
   * optional value to use as markers padding at initial map load and on each markers cluster selection
   * if value is not provided default value of [30, 30] is used
   */
  markersPadding?: L.PointExpression;
  /**
   * optional callback to get authorization headers for private map tile server
   */
  headersGetter?: () => Record<string, any>;
  /**
   * the zoom level at which the cluster group render will not expand further
   */
  disableClusteringAtZoom?: number;
  /**
   * enable cluster groups on map
   */
  enableClustering?: boolean;
  /**
   * optional map load callback
   */
  onInit?: (mapInstance: L.Map) => void;
  /**
   * optional array containing bounding box corners in lat-long tuple format for visible map tiles' area
   */
  tileLayerBounds?: LatLngExpression[];
  /**
   * optional url string specifying the tile server address
   */
  url?: TileLayerProps["url"];
  /**
   * enables user to select markers
   */
  selectableMarkers?: boolean;
  /**
   * called when user select/unselects a marker(s)
   */
  onMarkersSelectionChange?: (markers: Marker[]) => void;
  /**
   * optional customization of AreaSelect tooltip texts
   */
  areaSelectSettings?: AreaSelectSettings;
  /**
   * optional specify false if want to disable this feature, deafult is true
   */
  fitToBoundsOnMarkersChange?: boolean;
}

interface UpdateBoundsProps {
  markers: Marker[];
  bounds: LatLngBounds;
  markersPadding: L.PointExpression;
}

const allControls = [
  ControlItem.undo,
  ControlItem.redo,
  ControlItem.hand,
  ControlItem.marker,
  ControlItem.layers,
  ControlItem.radarPlot,
];

const emptyTooltip: Tooltip = {
  title: "",
  imageUrl: "",
  icons: undefined,
  button: undefined,
};

const defaultHeaders = {
  // NOTE: put required default headers here if needed
};

const defaultMarkersPadding = L.point(30, 30);

const renderControlButtons = (controls: ControlItem[]): ReactNode =>
  controls.map((controlItem) => (
    <Button level="tertiary" key={controlItem} className="px-3">
      <Icon name={controlItem} size={16} />
    </Button>
  ));

const UpdateBounds = ({
  markers,
  bounds,
  markersPadding,
}: UpdateBoundsProps) => {
  const map = useMap();
  const [markersIds, setMarkersIds] = useState(
    new Set<string>(markers.map((marker) => String(marker.id)))
  );

  const updateBoundsFn = useCallback(() => {
    if (markersIds.size && bounds.isValid()) {
      map.fitBounds(bounds, {
        padding: markersPadding,
        animate: true,
        duration: 1,
      });
    }
  }, [bounds, map, markersIds.size, markersPadding]);

  useEffect(() => {
    setMarkersIds((ids) => {
      const markersIdsCopy = new Set(ids);

      const deleteFailed = markers.some(
        (marker) => !markersIdsCopy.delete(String(marker.id))
      );

      if (deleteFailed || markersIdsCopy.size) {
        return new Set<string>(markers.map((marker) => String(marker.id)));
      }

      // NOTE: Return intial ids in case if nothing has changed, in order to prevent further updates
      return ids;
    });
  }, [markers]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateBoundsFn, [markersIds]);

  return null;
};

export const Map = (props: MapProps) => {
  const {
    boundsOptions = { padding: [20, 20] },
    bounceAtZoomLimits = true,
    boxZoom = true,
    center,
    dragging = !L.Browser.mobile,
    controls,
    minZoom = 3,
    maxZoom = 15,
    maxBounds,
    trackResize = true,
    zoom = 3,
    zoomControl = !L.Browser.mobile,
    zoomDelta = 1,
    zoomSnap = 0.25,
    wheelPxPerZoomLevel = 50,
    scrollWheelZoom = true,
  } = props;

  return (
    <div className="w-100 h-100">
      {!!controls && (
        <div className="map-gis">
          <ButtonGroup size="sm" rounded="0" className="bg-white shadow-sm">
            <React.Fragment>
              {renderControlButtons(
                controls === "all" ? allControls : controls
              )}
            </React.Fragment>
          </ButtonGroup>
        </div>
      )}
      <MapContainer
        bounceAtZoomLimits={bounceAtZoomLimits}
        boundsOptions={boundsOptions}
        boxZoom={boxZoom}
        center={center}
        className="map-container"
        dragging={dragging}
        maxBounds={maxBounds ? L.latLngBounds(maxBounds) : undefined}
        maxZoom={maxZoom}
        minZoom={minZoom}
        trackResize={trackResize}
        zoom={zoom}
        zoomControl={zoomControl}
        zoomDelta={zoomDelta}
        zoomSnap={zoomSnap}
        wheelPxPerZoomLevel={wheelPxPerZoomLevel}
        scrollWheelZoom={scrollWheelZoom}
        style={{ height: "100%" }}
        tap={false}
      >
        <MapContent {...props} />
      </MapContainer>
    </div>
  );
};

function MapContent({
  attribution = '&copy; <a href = "https://openmaptiles.org/">OpenMapTiles</a>',
  enableClustering = true,
  headersGetter,
  noWrap = true,
  maxZoom = 15,
  minNativeZoom,
  maxNativeZoom,
  zoomOffset,
  disableClusteringAtZoom = maxZoom + 1,
  onInit,
  tileLayerBounds,
  url = "MAP_URL",
  selectableMarkers: areMarkersSelectable = false,
  onMarkersSelectionChange,
  areaSelectSettings,
  tileSize = 256,
  markersPadding = defaultMarkersPadding,
  fitToBoundsOnMarkersChange = true,
  markers: markerList,
}: MapProps) {
  const [markers, setMarkers] = useState(markerList || []);
  const map = useMap();

  const clusterGroupRef = useRef<LMarkerClusterGroup>(null);
  const getIconOptionsRef = useRef<{
    markers: typeof markers;
    areMarkersSelectable: typeof areMarkersSelectable;
    markersPadding: typeof markersPadding;
  }>({
    markers,
    areMarkersSelectable,
    markersPadding,
  });

  useEffect(() => {
    const initializedMarkers =
      areMarkersSelectable && markerList
        ? markerList.map((marker) => ({
            ...marker,
            selected: marker.selected || false,
            id: marker.id,
          }))
        : markerList || [];
    setMarkers(initializedMarkers);
  }, [markerList, areMarkersSelectable]);

  const bounds = useMemo(() => {
    if (markers.length === 0) return L.latLngBounds([]);
    return L.latLngBounds(
      markers.length > 1
        ? markers.map((marker) => [marker.lat, marker.long])
        : [[markers[0].lat, markers[0].long]]
    );
  }, [markers]);

  useEffect(() => {
    if (typeof onInit === "function") {
      onInit(map);
    }
  }, [map, onInit]);

  useEffect(() => {
    getIconOptionsRef.current.markers = markers;
    getIconOptionsRef.current.areMarkersSelectable = areMarkersSelectable;
    getIconOptionsRef.current.markersPadding = markersPadding;

    // trigger cluster refresh when the icon props are updated
    clusterGroupRef.current?.refreshClusters();
  }, [markers, areMarkersSelectable, markersPadding]);

  const handleMarkersSelectionChange = useCallback(
    (markers: Marker[]) => {
      setMarkers(markers);
      if (onMarkersSelectionChange) onMarkersSelectionChange(markers);
    },
    [onMarkersSelectionChange]
  );

  const handleMarkerSelectToggle = (clickedMarker: Marker) => {
    const updatedMarkers = markers.map((marker) =>
      clickedMarker.id === marker.id
        ? { ...marker, selected: !marker.selected }
        : marker
    );
    setMarkers(updatedMarkers);
    if (onMarkersSelectionChange) {
      onMarkersSelectionChange(updatedMarkers);
    }
  };

  return (
    <>
      {fitToBoundsOnMarkersChange && (
        <UpdateBounds
          markers={markers}
          bounds={bounds}
          markersPadding={markersPadding}
        />
      )}
      <TileLayerWithHeaders
        attribution={attribution}
        bounds={tileLayerBounds ? L.latLngBounds(tileLayerBounds) : undefined}
        headersGetter={() => ({
          ...defaultHeaders,
          ...(typeof headersGetter === "function" ? headersGetter() : {}),
        })}
        maxZoom={maxZoom}
        noWrap={noWrap}
        url={url}
        tileSize={tileSize}
        zoomOffset={zoomOffset}
        minNativeZoom={minNativeZoom}
        maxNativeZoom={maxNativeZoom}
      />

      {enableClustering ? (
        <MarkerClusterGroup
          disableClusteringAtZoom={disableClusteringAtZoom}
          showCoverageOnHover={false}
          iconCreateFunction={getMarkerClusterCustomIcon(
            // NOTE: The reference have to be provided to the function in order to correctly get updated properties inside of
            getIconOptionsRef.current
          )}
          ref={clusterGroupRef}
          spiderLegPolylineOptions={{
            weight: 0,
            opacity: 0,
          }}
          zoomToBoundsOnClick={false} // NOTE: custom bounds zoom action on click action is created for marker clusters
        >
          <Markers
            markers={markers}
            areMarkersSelectable={areMarkersSelectable}
            onMarkerSelect={handleMarkerSelectToggle}
          />
        </MarkerClusterGroup>
      ) : (
        <Markers
          markers={markers}
          areMarkersSelectable={areMarkersSelectable}
          onMarkerSelect={handleMarkerSelectToggle}
        />
      )}

      {areMarkersSelectable && markers?.length ? (
        <AreaSelect
          {...(areaSelectSettings ?? {})}
          markers={markers}
          onSelectionChange={handleMarkersSelectionChange}
        />
      ) : null}

      <ScaleControl />
    </>
  );
}
