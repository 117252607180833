/** @format */

import React, { memo } from "react";
import { Nav } from "@bphxd/ds-core-react";

export interface MenuItemProps {
  /**
   * text label
   */
  label: string;
  /**
   * icon key string from the icon library
   */
  icon: React.ReactNode;
  /**
   * menu item onClick callback
   */
  onClick: () => void;
  /**
   * active selection state
   */
  active?: boolean;
}

export interface SidebarProps {
  /**
   * collapses the sidebar menu into single col icon form
   */
  open?: boolean;
  /**
   * array of menu item objects
   */
  items: Array<MenuItemProps>;
  /**
   * onHide callback effect
   */
  onHide?: () => void;
}

function MenuItem({ icon, label, onClick, active = false }: MenuItemProps) {
  return (
    <Nav.Item>
      <Nav.Link
        onClick={onClick}
        active={active}
        tag="button"
        className="w-100 d-flex px-5"
      >
        {icon}
        <span className="nav-link__label text-nowrap">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
}

export const Sidebar = memo((props: SidebarProps) => {
  const { open = false, onHide, items } = props;

  return (
    <div className="sidebar-wrapper">
      <div
        className={`overlay ${open ? "overlay--open" : ""}`}
        onClick={onHide}
      />

      <div
        className={`sidebar nav-sidebar d-flex flex-column flex-shrink-0  ${
          open ? "sidebar--open" : ""
        }`}
      >
        <Nav vertical>
          {items.map((item, index) => (
            <MenuItem key={`sidebar-item-${index}`} {...item} />
          ))}
        </Nav>
      </div>
    </div>
  );
});
