import React, { useEffect, useRef } from "react";

import { Popover, PopoverProps } from "@bphxd/ds-core-react";

type EnhancedPopoverProps = PopoverProps & {
  onClickOutside?: () => void;
};

export function EnhancedPopover({
  onClickOutside,
  children,
  ...rest
}: EnhancedPopoverProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  // e.g. to dismiss the popover when user clicks outside of its content
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        typeof onClickOutside === "function" &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        onClickOutside();
      }
    }

    if (typeof onClickOutside === "function") {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [wrapperRef, onClickOutside]);

  return (
    <Popover {...rest}>
      <div ref={wrapperRef}>{children}</div>
    </Popover>
  );
}
