import { toast, ToastBar, Toaster } from "react-hot-toast";

import { Toast } from "@bphxd/ds-core-react";
import { Alert24 } from "@bphxd/ds-core-react/lib/icons";

export const AppToaster = () => {
  return (
    <Toaster position="top-right">
      {(toastMessage) => (
        <ToastBar toast={toastMessage} style={{ padding: 0 }}>
          {({ message }) => (
            <Toast border isOpen>
              <Toast.Header
                Icon={Alert24}
                onClose={() => toast.dismiss(toastMessage.id)}
              >
                Warning
              </Toast.Header>
              <Toast.Body>
                <span className="me-auto">{message}</span>
              </Toast.Body>
            </Toast>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};
