/** @format */

import React, { memo, useState, useRef, useMemo } from "react";

import { uniqueId } from "lodash-es";
import { Tooltip, TooltipProps } from "@bphxd/ds-core-react";

export interface TooltipIconProps {
  /**
   *  Icon component
   */
  icon: React.ReactNode;
  /**
   * Optional string description of the icon
   */
  label?: string;
  /**
   *  Optional text label
   */
  title: string | React.ReactElement;
  /**
   *  Optional placement alignment
   */
  placement?: TooltipProps["placement"];
  /**
   *  Optional className string for styling
   */
  className?: string;
}

export const TooltipIcon = memo(
  ({
    icon,
    label = "",
    title = "",
    className,
    placement = "top",
  }: TooltipIconProps) => {
    const id = useMemo(() => uniqueId("tooltip-icon"), []);

    return (
      <>
        <span
          className={`d-inline-flex ${className ?? ""}`}
          role="figure"
          aria-label={label}
          id={id}
        >
          {icon}
        </span>
        <Tooltip fade={false} autohide flip placement={placement} target={id}>
          {title}
        </Tooltip>
      </>
    );
  }
);
