declare global {
  interface Window {
    _paq?: [string, ...any[]][];
  }
}

type ActionCustomDimensionType = {
  [key: `dimension${number}`]: string;
};

type TrackInstructions = "trackEvent" | "trackPageView" | "trackContentInteraction";

type MatomoInstructions =
  | TrackInstructions
  | "setCustomDimension"
  | "deleteCustomDimension"
  | "setUserId"
  | "enableLinkTracking"
  | "setCustomUrl";

export function init() {
  if (typeof window === "undefined") {
    return;
  }
  window._paq = window._paq || [];

  if (window._paq.length > 0) {
    return;
  }

  const _paq = window._paq;
  const matomoScriptUrl = `${process.env.REACT_APP_MATOMO_URL}matomo.js`;
  const matomoTrackerUrl = `${process.env.REACT_APP_MATOMO_URL}matomo.php`;
  const matomSiteId: string = "%REACT_APP_MATOMO_SITE_ID%";

  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(["trackPageView"]);
  _paq.push(["enableLinkTracking"]);
  _paq.push(["trackVisibleContentImpressions"]);
  _paq.push(["setTrackerUrl", matomoTrackerUrl]);
  _paq.push(["setSiteId", process.env.REACT_APP_MATOMO_SITE_ID]);
  _paq.push(["setSecureCookie", process.env.NODE_ENV === "production"]);

  const script = document.createElement("script");
  const firstScriptInDocument = document.getElementsByTagName("script")[0];

  script.type = "text/javascript";
  script.async = true;
  script.defer = true;
  script.src = matomoScriptUrl;
  firstScriptInDocument.before(script);
}

export function setUserId(userId: string) {
  push("setUserId", userId);
}

export function setCustomDimension(dimension: number, value: string) {
  push("setCustomDimension", dimension, value);
}

export function deleteCustomDimension(dimension: number) {
  push("deleteCustomDimension", dimension);
}

export function setCustomDimensions(
  ...customDimensions: Array<[number, string]>
) {
  customDimensions.forEach(([dimension, value]) => {
    push("setCustomDimension", dimension, value);
  });
}

export function enableLinkTracking() {
  push("enableLinkTracking");
}

export function trackPageView(page: string) {
  track("trackPageView", page);
}

export function trackEvent(event: {
  category: string;
  action: string;
  name?: string;
  value?: number;
  customDimensions?: Array<[number, string]>;
}) {
  const { category, action, name, value, customDimensions } = event;
  const actionDimensions =
    !!customDimensions &&
    !!customDimensions.length &&
    customDimensions.reduce<ActionCustomDimensionType>(
      (acc, [dimension, value]) => {
        acc[`dimension${dimension}`] = value;
        return acc;
      },
      {}
    );

  track("trackEvent", category, action, name, value, actionDimensions);
}

export function trackContentInteraction(interactionName: string, contentName: string, contentPiece: string) {
  track('trackContentInteraction', interactionName, contentName, contentPiece)
}

function track(instruction: TrackInstructions, ...data: any[]) {
  const href = window.location.href;
  push("setCustomUrl", href);
  push(instruction, ...data);
}

function push(instruction: MatomoInstructions, ...data: any[]) {
  window._paq?.push([instruction, ...data]);
}
