import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory, matchPath, useLocation } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { Sidebar } from "ui-core";
import { useKeycloak } from "src/lib/KeycloakProvider";
import ScrollTopButton from "src/components/widgets/ScrollTopButton";
import Navbar from "./Navbar";
import { toast } from "react-hot-toast";
import { createUrlQueryParams } from "src/lib/createUrlQueryParams";

import {
  Download24,
  Graph24,
  HomeApartment24,
} from "@bphxd/ds-core-react/lib/icons";

function Dashboard(props: React.PropsWithChildren<{}>) {
  const { children } = props;

  const history = useHistory();
  const { pathname } = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [scrollToTopVisible, setScrollToTopVisible] = useState<boolean>(false);

  const client = useApolloClient();

  const { userInfo, logout } = useKeycloak();

  useEffect(() => {
    return () => {
      client.clearStore();
    };
  }, [client]);

  const handleLogout = useCallback(() => {
    logout()
      .then(() => {
        client.clearStore();
      })
      .catch((err) => {
        toast.error(`Logout failed. ${err}`, {
          id: err, // toastId to prevent duplicates
        });
      });
  }, [client, logout]);

  const menuItems = useCallback(() => {
    return [
      {
        onClick: () => {
          setSidebarOpen(false);
          history.push("/overview");
        },
        label: "Overview",
        icon: <Graph24 className="nav-link-icon" />,
        active: Boolean(
          matchPath(pathname, {
            path: "/overview",
            exact: true,
            strict: false,
          })
        ),
      },
      {
        onClick: () => {
          setSidebarOpen(false);
          history.push("/portfolio");
        },
        label: "Portfolio",
        icon: <HomeApartment24 className="nav-link-icon" />,
        active: Boolean(
          matchPath(pathname, {
            path: "/portfolio",
            exact: false,
            strict: false,
          })
        ),
      },
      {
        onClick: () => {
          setSidebarOpen(false);
          history.push("/data");
        },
        label: "Your Data",
        icon: <Download24 className="nav-link-icon" />,
        active: Boolean(
          matchPath(pathname, {
            path: "/data",
            exact: true,
            strict: false,
          })
        ),
      },
    ];
  }, [history, pathname]);

  const handleSidebarToggle = useCallback(
    () => setSidebarOpen((prevSidebarOpen) => !prevSidebarOpen),
    []
  );

  const handleSidebarToggleWithTracking = useCallback(() => {
    setSidebarOpen((prevSidebarOpen) => {
      return !prevSidebarOpen;
    });
  }, []);

  return (
    <>
      <Navbar
        onBurgerPress={handleSidebarToggleWithTracking}
        logout={handleLogout}
        username={userInfo.name}
        avatar={userInfo.picture}
        dateFormat={userInfo.dateFormat}
        timeFormat={userInfo.timeFormat}
      />

      <div
        className={`dashboard-sidebar ${
          sidebarOpen ? "dashboard-sidebar--open" : ""
        }`}
      >
        <Sidebar
          items={menuItems()}
          open={sidebarOpen}
          onHide={handleSidebarToggle}
        />
      </div>
      <div className={`dashboard-content ${scrollToTopVisible ? " pb-4" : ""}`}>
        {children}
      </div>
      <ScrollTopButton onVisibilityChange={setScrollToTopVisible} />
      <footer className="dashboard-footer bg-white border-top d-flex align-items-center justify-content-center gap-3 gap-lg-4">
        <div className="lh-sm text-secondary text-center fs-6 px-4 px-sm-0">
          ©{new Date().getFullYear()} Platform by Blueprint Power Technologies
          LLC, a bp company 
        </div>

        <div className="d-flex gap-3 gap-lg-4">
          <a
            className="link-border fs-6"
            href={`${process.env.REACT_APP_TOS_PAGE_URL}${createUrlQueryParams({
              referrer: process.env.REACT_APP_PP_TOS_CLIENT || undefined,
            })}`}
          >
            Terms of Service
          </a>
          <a
            className="link-border fs-6"
            href={`${process.env.REACT_APP_PP_PAGE_URL}${createUrlQueryParams({
              referrer: process.env.REACT_APP_PP_TOS_CLIENT || undefined,
            })}`}
          >
            Privacy Policy
          </a>
        </div>
      </footer>
    </>
  );
}

export default memo(Dashboard);
