import React from "react";
import { Marker as LMarker, Popup } from "react-leaflet";
import {
  markerLIcon,
  markerSelectedLIcon,
  markerUnselectedLIcon,
} from "./MapIcons";
import { ImageWithLoader } from "@elements/ImageWithLoader";
import { Marker, Tooltip } from ".";
import { Button } from "@bphxd/ds-core-react";
import { Add16, Check16 } from "@bphxd/ds-core-react/lib/icons";
import { Icon } from "@elements/Icon";

const emptyTooltip: Tooltip = {
  title: "",
  imageUrl: "",
  icons: undefined,
  button: undefined,
};

type MarkersProps = {
  markers: Array<Marker>;
  areMarkersSelectable?: boolean;
  onMarkerSelect?: (marker: Marker) => void;
};

export function Markers({
  markers,
  areMarkersSelectable,
  onMarkerSelect,
}: MarkersProps) {
  return (
    <>
      {markers.map((marker, index) => {
        const { lat, long, tooltip = emptyTooltip, selected } = marker;
        const { icons, button, title, imageUrl } = tooltip;

        const hasTooltip = !!title && !!imageUrl;

        return (
          <LMarker
            position={[lat, long]}
            icon={
              selected === true
                ? markerSelectedLIcon
                : selected === false
                ? markerUnselectedLIcon
                : markerLIcon
            }
            key={`${index}-${lat}${long}`}
          >
            {hasTooltip && (
              <Popup className="map-popup" closeButton={false}>
                <ImageWithLoader src={imageUrl} alt={title} />
                <div className="popup-content-info x5-px-2 py-3">
                  <span className="map-popup-title fs-6 lh-sm fw-normal">
                    {title}
                  </span>
                  <div className="assets d-flex flex-wrap gap-2 mb-3">
                    {Array.isArray(icons) &&
                      icons.length > 0 &&
                      icons.map((icon) => (
                        <Icon name={icon} key={icon} size={16} />
                      ))}
                  </div>
                  {button && (
                    <Button
                      rounded="0"
                      block
                      size="sm"
                      level="primary"
                      onClick={
                        button.onClick as React.MouseEventHandler<HTMLElement>
                      }
                    >
                      {button.label}
                    </Button>
                  )}
                  {areMarkersSelectable && (
                    <Button
                      rounded="0"
                      block
                      size="sm"
                      level={marker.selected ? "primary" : "tertiary"}
                      onClick={() => {
                        if (onMarkerSelect) {
                          onMarkerSelect(marker);
                        }
                      }}
                      Icon={marker.selected ? Check16 : Add16}
                      iconPosition="end"
                    >
                      {marker.selected ? "Selected" : "Select"}
                    </Button>
                  )}
                </div>
              </Popup>
            )}
          </LMarker>
        );
      })}
    </>
  );
}
