/** @format */

import { memo, useState, useCallback, useMemo } from "react";
import { PortfolioCard, PortfolioCardProps } from "@blocks/PortfolioCard";
import { SearchBar, SearchBarProps } from "@blocks/SearchFilterView";
import { Widget, WidgetSettings } from "@utility/Widget";
import { Map, MapProps } from "./Map";
import { useResizeDetector } from "react-resize-detector";
import { Badge, Button, ButtonGroup } from "@bphxd/ds-core-react";

export interface BuildingsGridWidgetProps {
  widgetTitle?: string;
  searchBarProps?: SearchBarProps;
  buildings?: Array<PortfolioCardProps>;
  mapProps?: MapProps;
  showMap?: boolean;
  widgetSettings?: WidgetSettings;
  loading?: boolean;
  error?: boolean;
}

const smBreakpoint = 550;
const mdBreakpoint = 650;
const lgBreakpoint = 1050;
const toggleBreakpoint = 700;

export const BuildingsGridWidget = memo((props: BuildingsGridWidgetProps) => {
  const {
    widgetTitle = "Buildings",
    searchBarProps,
    buildings,
    mapProps,
    showMap,
    loading,
    error,
    widgetSettings,
  } = props;

  const [activeTab, setActiveTab] = useState<"listView" | "mapView">(
    "listView"
  );

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  const { width, ref } = useResizeDetector();

  const widgetWidth = useMemo(() => width || 0, [width]);

  const isToggleVisible = useMemo(
    () => showMap && widgetWidth < toggleBreakpoint,
    [showMap, widgetWidth]
  );

  const listWidth = useMemo(
    () => (isToggleVisible || !showMap ? widgetWidth : widgetWidth / 2),
    [isToggleVisible, showMap, widgetWidth]
  );

  const itemWidth = useMemo(
    () =>
      listWidth > lgBreakpoint
        ? 100 / 4
        : listWidth <= lgBreakpoint && listWidth > mdBreakpoint
        ? 100 / 3
        : listWidth <= mdBreakpoint && listWidth > smBreakpoint
        ? 100 / 2
        : 100,
    [listWidth]
  );

  const compactListItem = useMemo(() => listWidth <= smBreakpoint, [listWidth]);

  const isMapVisible = useMemo(
    () =>
      showMap &&
      mapProps &&
      (!isToggleVisible || (isToggleVisible && activeTab === "mapView")),
    [activeTab, isToggleVisible, mapProps, showMap]
  );

  const isListVisible = useMemo(
    () => activeTab === "listView" || !isToggleVisible,
    [activeTab, isToggleVisible]
  );

  return (
    <div
      ref={ref}
      className="buildings-grid-widget bg-white shadow-sm h-100 w-100 d-flex flex-column position-relative overflow-hidden"
      aria-label="Buildings Grid"
    >
      <div>
        <div className="d-flex justify-content-between align-items-center border-primary border-bottom x5-px-4 py-4">
          <h2
            className="fs-base lh-1-5 fw-normal p-0 m-0"
            aria-label="Widget Title"
          >
            {widgetTitle}
          </h2>
          {searchBarProps ? <SearchBar {...searchBarProps} /> : null}
        </div>
        {isToggleVisible && (
          <ButtonGroup color="shadow" rounded="0" className="w-100">
            <Button
              active={activeTab === "listView"}
              onClick={() => handleTabChange("listView")}
              className="z-0"
            >
              List View
            </Button>
            <Button
              active={activeTab === "mapView"}
              onClick={() => handleTabChange("mapView")}
              className="z-0"
            >
              Map View
            </Button>
          </ButtonGroup>
        )}
      </div>

      <Widget
        className={"position-relative z-0 flex-1 d-flex overflow-hidden"}
        loading={loading}
        error={error}
        settings={widgetSettings}
        empty={buildings && buildings.length === 0}
      >
        {isListVisible && (
          <div
            className={`flex-1 d-flex flex-wrap w-100 overflow-auto align-content-start ${
              !compactListItem ? "x5-px-2 py-3" : ""
            }`}
          >
            {buildings
              ? buildings.map((building, index) => (
                  <div
                    key={`building-item-${building.name}-${index}`}
                    className={compactListItem ? "mb-n1" : "x5-px-2 py-3"}
                    style={{
                      width: `${itemWidth}%`,
                    }}
                  >
                    <PortfolioCard
                      {...building}
                      compact={compactListItem}
                      className="h-100"
                    />
                  </div>
                ))
              : null}
          </div>
        )}
        {isMapVisible && (
          <div className="flex-1 h-100">
            <Map {...mapProps} />
          </div>
        )}
      </Widget>
    </div>
  );
});
