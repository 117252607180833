/** @format */

import React, { memo, useEffect, useState } from "react";

export interface IconProps {
  /**
   * Icon name
   */
  name: string;
  /**
   * Icon color
   */
  fill?: string;
  /**
   * Icon size
   */
  size?: number;
  /**
   * Optional class name
   */
  className?: string;
}

export const svgPathNames = [
  "airquality",
  "askquestion",
  "automation",
  "back",
  "barchart",
  "batteries",
  "bell",
  "bulkgastanker",
  "cancel",
  "carradiator",
  "chargingsession",
  "chargingstation",
  "chevronleft",
  "calendar",
  "city",
  "citybuildings",
  "co2",
  "co2factor",
  "collapsearrow",
  "combochart",
  "dashboard",
  "delete",
  "deliverytime",
  "download",
  "downloadfromcloud",
  "dynamo",
  "edit",
  "electrical",
  "energymeter",
  "engine",
  "error",
  "event",
  "expandarrow",
  "export",
  "filter",
  "forward",
  "fullscreenadd",
  "fullscreenremove",
  "gasindustry",
  "gear",
  "grid",
  "geothermal",
  "hand",
  "heating",
  "help",
  "humidity",
  "info",
  "information",
  "layers",
  "lightningbolt",
  "loading",
  "marker",
  "menu",
  "menuvertical",
  "minus",
  "office",
  "oiltanker",
  "ok",
  "plusmath",
  "pressure",
  "radarplot",
  "redo",
  "search",
  "signout",
  "slider",
  "solarpanel",
  "sort",
  "sortdown",
  "sortleft",
  "sortright",
  "sortup",
  "star",
  "staroutlined",
  "temperatureinside",
  "timezoneglobe",
  "undo",
  "usdollarcircled",
  "usdollar",
  "user",
  "videorecord",
  "voltage",
  "watersteam",
  "windturbine",
  "youtubelive",
  "wifirouter",
];

// alt names for asset <-> icon mapping
const altPathNameMap: Record<string, string> = {
  pv: "solarpanel",
  ess: "batteries",
  ev: "chargingstation",
  wind: "windturbine",
  chp: "engine",
  chp_v2: "engine",
  tes: "heating",
  fc: "carradiator",
  eload: "energymeter",
  load: "energymeter", // old version, for compatibility
  invt: "dynamo",
  egrid: "voltage",
  nggen: "bulkgastanker",
  dslgen: "oiltanker",
  ngload: "gasindustry",
  stload: "watersteam",
  stdist: "watersteam",
  ngdist: "geothermal",
  dgbx: "wifirouter",
};

/**
 * Icon is a custom component, that returns svg element. The prop 'name' is used as a key to return the target svg path.
 */
export const Icon = memo((props: IconProps) => {
  const { name, size = 24, className = "", fill = "currentColor" } = props;

  // svgPath is an ES module
  const [svgPath, setSvgPath] = useState<any>();

  useEffect(() => {
    if (name) {
      import(`./svgPaths/${altPathNameMap[name] ?? name}`).then((path) => {
        setSvgPath(path);
      });
    }
  }, [name]);

  if (svgPath) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size || 24}
        height={size || 24}
        fill={fill || "currentColor"}
        className={className}
      >
        <path d={svgPath.default as string} />
      </svg>
    );
  }

  return null;
});
