import React, { memo, useCallback } from "react";
import { WidgetHeader } from "@utility/Widget";
import {
  BuildingListItem,
  BuildingListItemProps,
} from "../elements/BuildingListItem";
import { Error } from "@utility/Error";
import { Empty } from "@utility/Empty";
import { Button, ButtonGroup, Spinner } from "@bphxd/ds-core-react";

export type SelectedTab = "favorite" | "lastVisited";

const emptyListMessage: Record<SelectedTab, string> = {
  favorite: "There are no favorite buildings to show.",
  lastVisited: "There are no recently visited buildings to show.",
};

export interface BuildingsListWidgetProps {
  loading?: boolean;
  error?: string;
  buildings: Array<
    BuildingListItemProps & {
      id: string;
      siteId: string;
    }
  >;
  selectedTab: SelectedTab;
  onTabChange: (selectedTab: SelectedTab) => void;
  onBuildingClick: (buildingId: number | string, siteId: string) => void;
}

export const BuildingsListWidget = memo((props: BuildingsListWidgetProps) => {
  const {
    buildings = [],
    loading,
    error,
    selectedTab = "lastVisited",
    onTabChange,
    onBuildingClick,
  } = props;

  const handleTabChange = useCallback(
    (tab: SelectedTab) => {
      onTabChange(tab);
    },
    [onTabChange]
  );

  const handleBuildingClick = useCallback(
    (id, siteId) => {
      onBuildingClick(id, siteId);
    },
    [onBuildingClick]
  );

  return (
    <div className="bg-white shadow-sm h-100 d-flex flex-column overflow-hidden">
      <WidgetHeader
        title={`${
          selectedTab === "favorite" ? "Favorite" : "Last Visited"
        } Buildings`}
      />
      <div className="x5-px-4 py-4 border-bottom d-flex justify-content-center">
        <ButtonGroup
          rounded="0"
          color="shadow"
          size="sm"
          aria-label="Toggle Between Last Visited and Favorites Buildings List"
        >
          <Button
            onClick={() => handleTabChange("lastVisited")}
            active={selectedTab === "lastVisited"}
            style={{ width: "7.5rem" }}
          >
            Last Visited
          </Button>
          <Button
            onClick={() => handleTabChange("favorite")}
            active={selectedTab === "favorite"}
            style={{ width: "7.5rem" }}
          >
            Favorite
          </Button>
        </ButtonGroup>
      </div>

      {error ? (
        <Error text={error} />
      ) : loading ? (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <Spinner size="sm" label="Loading buildings..." labelDelay={2000} />
        </div>
      ) : (
        <ul aria-label="Buildings List" className="buildings-list">
          {buildings.length > 0 ? (
            buildings.map((building, index) => {
              const {
                id,
                siteId,
                imageUrl,
                name,
                siteName,
                timestamp,
                isFavorite,
              } = building;

              return (
                <BuildingListItem
                  id={id}
                  key={index}
                  imageUrl={imageUrl}
                  name={name}
                  siteName={siteName}
                  timestamp={timestamp}
                  isFavorite={isFavorite}
                  onClick={() => handleBuildingClick(id, siteId)}
                />
              );
            })
          ) : (
            <Empty text={emptyListMessage[selectedTab]} />
          )}
        </ul>
      )}
    </div>
  );
});
