/** @format */

import React, {
  memo,
  PureComponent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Icon } from "@elements/Icon";
import {
  Button,
  ButtonGroup,
  Form,
  Input,
  Label,
  Slider,
} from "@bphxd/ds-core-react";

export enum FilterType {
  TOGGLE = "toggle",
  RANGE = "range",
  SLIDER = "slider",
  RADIOBUTTONS = "radiobuttons",
}

export interface CommonFilterItemParams {
  /**
   * Shared filter item key identifier string
   */
  key: string;
  /**
   * Id for the group the filter belongs to - it is optional but if groups prop is provided, each filter has to have a group id else it won't be rendered
   */
  groupId?: string;
  /**
   * Filters are sorted in groups based on this number
   */
  sortIndex: number;
}

export interface ToggleFilterItem extends CommonFilterItemParams {
  /**
   * Icon key name from the icon library
   */
  icon: string;
  /**
   * Toggle item type
   */
  type: FilterType.TOGGLE;
  /**
   * Text label to display for the filter item [row]
   */
  label: string;
  /**
   * Callback for the toggle effect
   */
  onChange: (key: string, value: boolean) => void;
}

export interface SliderFilterItem extends CommonFilterItemParams {
  min: number;
  max: number;
  /**
   * Text label to display for the filter item [row]
   */
  label: string;
  valueFormatter?: (value: number) => string;
  type: FilterType.SLIDER;
  onChange: (key: string, value: number) => void;
}

export interface RangeFilterItem extends CommonFilterItemParams {
  min: number;
  max: number;
  /**
   * Text label to display for the filter item [row]
   */
  label: string;
  valueFormatter?: (value: number) => string;
  type: FilterType.RANGE;
  onChange: (key: string, value: number[]) => void;
}
export interface RadioButtonsFilterItem extends CommonFilterItemParams {
  buttons: Array<{
    key: string;
    text: string;
    icon?: string;
  }>;
  defaultKey: string;
  type: FilterType.RADIOBUTTONS;
  onChange: (key: string, value: string) => void;
}

export type FilterItem =
  | ToggleFilterItem
  | SliderFilterItem
  | RangeFilterItem
  | RadioButtonsFilterItem;

export interface ToggleFilter extends Omit<ToggleFilterItem, "onChange"> {
  value: boolean;
  onChange: (toggled: boolean) => void;
}

export interface SliderFilter extends Omit<SliderFilterItem, "onChange"> {
  value: number;
  onChange: (value: number) => void;
}

export interface RangeFilter extends Omit<RangeFilterItem, "onChange"> {
  value: number[];
  onChange: (value: number[]) => void;
}

export interface RadioButtonsFilter
  extends Omit<RadioButtonsFilterItem, "onChange"> {
  value: string;
  onChange: (key: string) => void;
}

export type Filter =
  | ToggleFilter
  | SliderFilter
  | RangeFilter
  | RadioButtonsFilter;

export type ValueMap = Record<Filter["key"], Filter["value"]>;

interface Group {
  id: string;
  title?: string;
  sortIndex: number;
}

export interface FilterViewProps {
  title?: string;
  /**
   * Filter item array
   */
  items: FilterItem[];
  /**
   * Filter component visibility state
   */
  visible?: boolean;
  /**
   * Optional css style class
   */
  className?: string;
  /**
   * Optional group mapping - if provided, you need to put all filters into groups
   */
  groups?: Group[];
  /**
   * Optional callback when the filter component changes
   */
  onChange?: (values: ValueMap, activeFilterCount: number) => void;
}

function Toggle({
  icon,
  label,
  value,
  onChange,
}: {
  icon: string;
  label: string;
  value: boolean;
  onChange: (toggled: boolean) => void;
}) {
  const handleChange = useCallback(
    (event) => {
      const checked = event.target.checked;
      onChange(checked);
    },
    [onChange]
  );

  return (
    <Form.Group check className="form-check-alt form-check-reverse mb-0 py-3">
      <Input
        checked={value}
        id={label}
        type="checkbox"
        onChange={handleChange}
      />
      <Label check for={label} className="d-flex align-items-center gap-3">
        <Icon name={icon} size={24} className="my-n1" /> {label}
      </Label>
    </Form.Group>
  );
}

function renderFilter(filter: Filter) {
  const { type, key } = filter;

  switch (type) {
    case FilterType.RADIOBUTTONS: {
      const { onChange, value, buttons } = filter as RadioButtonsFilter;

      return (
        <ButtonGroup
          key={key}
          aria-label="Toggle Between Last Visited and Favorite Buildings List"
          size="sm"
          color="shadow"
          className="w-100"
          rounded="0"
        >
          {buttons.map((button) => (
            <Button
              key={button.key}
              active={value === button.key}
              onClick={() => onChange(button.key)}
            >
              {button.text}
            </Button>
          ))}
        </ButtonGroup>
      );
    }
    case FilterType.TOGGLE: {
      const { onChange, value, label, icon } = filter as ToggleFilter;

      return (
        <Toggle
          key={key}
          label={label}
          onChange={onChange}
          value={value}
          icon={icon}
        />
      );
    }
    case FilterType.SLIDER: {
      const { onChange, value, label, min, max, valueFormatter } =
        filter as SliderFilter;

      return (
        <Slider
          key={key}
          min={min}
          max={max}
          value={value}
          onChange={onChange}
        />
      );
    }
    case FilterType.RANGE: {
      const { onChange, value, label, min, max, valueFormatter } =
        filter as RangeFilter;

      return (
        <div key={key}>
          {label ? <div className="fs-6 lh-sm mb-3">{label}</div> : null}
          <div className="w-100 d-flex gap-2 justify-content-between fs-6 text-secondary lh-sm fw-light mb-1">
            <span>{valueFormatter ? valueFormatter(value[0]) : value[0]}</span>
            <span>{valueFormatter ? valueFormatter(value[1]) : value[1]}</span>
          </div>
          <Slider min={min} max={max} value={value} onChange={onChange} />
        </div>
      );
    }
    default:
      return null;
  }
}

function renderFilters(filters: Filter[], groups?: Group[]) {
  if (!groups) {
    return filters
      .sort((filterA, filterB) => filterA.sortIndex - filterB.sortIndex)
      .map(renderFilter);
  }

  return groups
    .sort((groupA, groupB) => groupA.sortIndex - groupB.sortIndex)
    .map((group) => {
      const groupedFilters = filters.filter(
        (filter) => filter.groupId === group.id
      );

      return (
        <div key={group.id + group.title} className="x5-px-4 py-4">
          {group.title && <div className="fs-6 mb-3">{group.title}</div>}
          {groupedFilters
            .sort((filterA, filterB) => filterA.sortIndex - filterB.sortIndex)
            .map(renderFilter)}
        </div>
      );
    });
}

function getInitialValue(filterItem: FilterItem) {
  const { type } = filterItem;

  switch (type) {
    case FilterType.TOGGLE:
      return false;
    case FilterType.SLIDER: {
      const { min } = filterItem as SliderFilterItem;
      return min;
    }
    case FilterType.RANGE: {
      const { min, max } = filterItem as RangeFilterItem;
      return [min, max];
    }
    case FilterType.RADIOBUTTONS:
      const { defaultKey } = filterItem as RadioButtonsFilterItem;
      return defaultKey;
    default:
      throw new Error("Unknown filter type passed to getInitialValue!");
  }
}

export function getInitialValueMap(filterItems: FilterItem[]) {
  if (filterItems.length > 0) {
    return filterItems.reduce((acc, curr) => {
      const initialValue = getInitialValue(curr);

      return { ...acc, [curr.key]: initialValue };
    }, {} as ValueMap);
  }

  return {};
}

function getActiveFilterCount(filters: Filter[]) {
  return filters.reduce(
    (acc, curr) => acc + (checkIfFilterIsActive(curr) ? 1 : 0),
    0
  );
}

function checkIfFilterIsActive(filter: Filter) {
  const { type } = filter;

  switch (type) {
    case FilterType.TOGGLE: {
      const { value } = filter as ToggleFilter;
      return value === true;
    }
    case FilterType.SLIDER: {
      const { min, value } = filter as SliderFilter;
      return value > min;
    }
    case FilterType.RANGE: {
      const { min, max, value } = filter as RangeFilter;
      return value[0] > min || value[1] < max;
    }
    case FilterType.RADIOBUTTONS: {
      const { value, defaultKey } = filter as RadioButtonsFilter;
      return value !== defaultKey;
    }
    default:
      return false;
  }
}

function getFilters(
  items: FilterItem[],
  valueMap: ValueMap,
  handleChange: (key: Filter["key"]) => Filter["onChange"]
): Filter[] {
  return items.map((item) => {
    const { key } = item;

    return {
      ...item,
      value: valueMap[key],
      onChange: handleChange(key),
    } as Filter;
  });
}

const FilterView = memo(
  (
    props: FilterViewProps & {
      setValueMap: (
        key: string,
        value: string | number | boolean | number[]
      ) => void;
      valueMap: ValueMap;
    }
  ) => {
    const {
      items,
      groups,
      visible = true,
      onChange,
      className = "",
      setValueMap,
      title,
      valueMap,
    } = props;

    const handleChange = useCallback(
      (key: string) => {
        return (value: Filter["value"]) => {
          const filterItem = items.find((item) => item.key === key);

          if (typeof filterItem?.onChange === "function") {
            // @ts-ignore TS makes some weird assumption about value here
            filterItem.onChange(key, value);
          }

          setValueMap(key, value);
        };
      },
      [items, setValueMap]
    );

    const filters = useMemo(
      () => getFilters(items, valueMap, handleChange),
      [items, valueMap, handleChange]
    );

    useEffect(() => {
      const activeFilterCount = getActiveFilterCount(filters);

      if (typeof onChange === "function") {
        onChange(valueMap, activeFilterCount);
      }
    }, [filters, valueMap, onChange]);

    const renderedFilters = useMemo(
      () => renderFilters(filters, groups),
      [filters, groups]
    );

    return (
      <div className={`${visible ? "" : "d-none"} ${className}`}>
        {title ? (
          <div className="x5-px-4 py-4 border-bottom fs-base">{title}</div>
        ) : null}
        {renderedFilters}
      </div>
    );
  }
);

class FilterViewStateWrapper extends PureComponent<
  FilterViewProps,
  { valueMap: ValueMap }
> {
  state = {
    valueMap: getInitialValueMap(this.props.items),
  };

  setValueMap = (key: string, value: string | number | boolean | number[]) =>
    this.setState((prevState) => {
      return {
        ...prevState,
        valueMap: {
          ...prevState.valueMap,
          [key]: value,
        },
      };
    });

  reset = () => {
    const { items } = this.props;

    this.setState({
      valueMap: getInitialValueMap(items) as ValueMap,
    });
  };

  render() {
    const { valueMap } = this.state;

    return (
      <FilterView
        {...this.props}
        valueMap={valueMap}
        setValueMap={this.setValueMap}
      />
    );
  }
}

export { FilterViewStateWrapper as FilterView };
