/** @format */

import React, { memo } from "react";
import { Loader, LoaderProps } from "./Loader";
import { Error, ErrorProps } from "./Error";
import { Empty, EmptyProps } from "./Empty";
import { TooltipIcon } from "@elements/TooltipIcon";
import { Info24 } from "@bphxd/ds-core-react/lib/icons";

export interface WidgetSettings {
  empty?: EmptyProps;
  error?: ErrorProps;
  loader?: LoaderProps;
}
const defaultSettings: WidgetSettings = {
  empty: {
    overlay: true,
  },
  error: {
    overlay: true,
  },
  loader: {
    overlay: true,
  },
};

export interface WidgetProps {
  error?: boolean;
  loading?: boolean;
  empty?: boolean;
  settings?: WidgetSettings;
  children: React.ReactNode;
  className?: string;
}

export type WidgetHeaderProps = {
  title: string | JSX.Element;
  infoText?: string;
};

export const WidgetHeader = memo((props: WidgetHeaderProps) => {
  const { title, infoText } = props;

  return (
    <div className="d-flex justify-content-between align-items-center flex-shrink-0 border-primary border-bottom x5-px-4 py-4">
      <h2
        className="fs-base lh-1-5 fw-normal p-0 m-0"
        aria-label="Widget Title"
      >
        {title}
      </h2>
      {infoText ? (
        <TooltipIcon
          icon={<Info24 />}
          title={infoText}
          placement="left"
          className="ms-3"
        />
      ) : null}
    </div>
  );
});

export const Widget = memo((props: WidgetProps) => {
  const {
    error,
    loading,
    empty,
    settings = {},
    children,
    className = "h-100 w-100",
  } = props;

  return (
    <div className={className}>
      {loading ? (
        <Loader {...(settings.loader || defaultSettings.loader)} />
      ) : error ? (
        <Error {...(settings.error || defaultSettings.error)} />
      ) : empty ? (
        <Empty {...(settings.empty || defaultSettings.empty)} />
      ) : (
        children
      )}
    </div>
  );
});
