import React, { forwardRef, useCallback, useState, useMemo } from "react";

import {
  Badge,
  Modal,
  Navbar as BPNavbar,
  Avatar,
  Dropdown,
  Button,
  Logo,
  Popover,
} from "@bphxd/ds-core-react";

import {
  Question24,
  Hamburger32,
  AlertBell24,
  LogOut24,
  User24,
} from "@bphxd/ds-core-react/lib/icons";

import { NotificationList, Widget } from "ui-core";

import { MEDIUMBREAKPOINT as mediumBreakpoint } from "src/lib/constants";

import { useNotifications } from "src/lib/NotificationsProvider";
import useWindowDimensions from "src/lib/useWindowDimensions";
import { EventBuilder } from "src/lib/matomo/appMatomo";
import { trackEvent } from "src/lib/matomo/matomo";
import { Link } from "react-router-dom";

interface NavbarProps {
  avatar?: string;
  dateFormat?: string;
  logout?: (e) => void;
  onBurgerPress?: () => void;
  timeFormat?: string;
  username?: string;
}

const notificationsWidgetSettings = {
  loader: {
    text: "Loading notifications...",
    overlay: true,
  },
  empty: {
    text: "No notifications available.",
    overlay: true,
  },
  error: {
    text: "Error while loading notifications.",
    overlay: true,
  },
};

const NotificationIcon = ({ count }: { count: number }) => (
  <div className="position-relative">
    <AlertBell24 />
    {count > 0 && (
      <span className="navbar-notification position-absolute top-0 start-100 translate-middle-x ms-n1 mt-n1">
        {count >= 100 ? "99+" : count}{" "}
        <span className="visually-hidden">unread messages</span>
      </span>
    )}
  </div>
);

function Navbar(props: NavbarProps) {
  const { logout, avatar, onBurgerPress, username, dateFormat, timeFormat } =
    props;

  const { innerWidth } = useWindowDimensions();
  const showNotificationsInModal = innerWidth < mediumBreakpoint;
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const toggleModal = useCallback(() => {
    setShowModal((show) => !show);
  }, []);

  const breakpoint = "md";

  const {
    notifications,
    setNotificationReadState,
    setAllNotificationsReadState,
    unreadNotificationsCount,
    error: notificationError,
  } = useNotifications();

  const handleNotificationUpdate = setNotificationReadState;

  const handleAllNotificationsUpdate = useCallback(() => {
    setAllNotificationsReadState(true);
  }, [setAllNotificationsReadState]);

  const handleLogout = useCallback(
    (e) => {
      e.preventDefault();
      trackEvent(
        EventBuilder.withCategory("User profile")
          .withAction("Log-out from application")
          .create()
      );
      if (typeof logout === "function") {
        logout(e);
      }
    },
    [logout]
  );

  const handleKeyPress = useCallback(
    (k) => {
      if (k?.keyCode === 13) {
        if (typeof onBurgerPress === "function") {
          onBurgerPress();
        }
      }
    },
    [onBurgerPress]
  );

  const notificationsContent = useMemo(
    () => (
      <Widget
        error={!!notificationError}
        empty={!notifications.length}
        settings={notificationsWidgetSettings}
      >
        <NotificationList
          data={notifications}
          onToggleRead={handleNotificationUpdate}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
        />
      </Widget>
    ),
    [
      dateFormat,
      timeFormat,
      handleNotificationUpdate,
      notificationError,
      notifications,
    ]
  );

  return (
    <BPNavbar
      expand
      divider="border"
      className="p-0"
      fixed="top"
      aria-label="main-navigation"
    >
      <div className="ms-n4 me-4">
        <div className="ms-n2 me-2">
          <Button
            level="tertiary"
            theme="darker"
            className="square-lg rounded-0 border-end d-block btn-navbar-nine-dot shadow-none"
            onClick={onBurgerPress}
            onKeyPress={handleKeyPress}
          >
            <Hamburger32 />
          </Button>
        </div>
      </div>

      <Link
        to="/"
        aria-label="Navigate home"
        className={`me-auto me-${breakpoint}-5`}
      >
        <BPNavbar.Brand tag="div">
          <Logo className="mx-n3" />
          <span className="ms-5 d-none d-md-inline-block">Portal</span>
        </BPNavbar.Brand>
      </Link>

      <div className={`ms-auto d-flex align-items-center me-4`}>
        <Button
          className="rounded-0 x5-px-2"
          level="quartenary"
          theme="darker"
          onClick={() => {
            // @ts-expect-error Temporary hackfix for custom toggle for the Zendesk widget
            global.zE(() => {
              // @ts-expect-error
              global.zE.activate({ hideOnClose: true });
            });
          }}
        >
          <Question24 />
        </Button>

        {showNotificationsInModal ? (
          <>
            <Button
              className="rounded-0 x5-px-2"
              level="quartenary"
              theme="darker"
              onClick={openModal}
            >
              <NotificationIcon count={unreadNotificationsCount ?? 0} />
            </Button>
            <Modal
              size="lg"
              isOpen={showModal}
              onToggleOpen={toggleModal}
              centered
              scrollable
            >
              <Modal.Header
                onToggleOpen={toggleModal}
                className="d-flex justify-content-between align-items-center"
              >
                <span>Notifications</span>
                {unreadNotificationsCount !== null &&
                  unreadNotificationsCount > 0 && (
                    <Button
                      className="rounded-0"
                      level="quartenary"
                      theme="darker"
                      size="sm"
                      onClick={handleAllNotificationsUpdate}
                    >
                      Mark all as read
                    </Button>
                  )}
              </Modal.Header>
              <Modal.Body className="position-relative">
                {notificationsContent}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  className="rounded-0"
                  level="quartenary"
                  theme="darker"
                  onClick={toggleModal}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <>
            <Button
              id="notifications-popover-trigger"
              className="rounded-0 x5-px-2"
              level="quartenary"
              theme="darker"
            >
              <NotificationIcon count={unreadNotificationsCount ?? 0} />
            </Button>
            <Popover
              fade
              flip
              popperClassName="notifications-popover p-0"
              target="notifications-popover-trigger"
              placement="bottom"
              trigger="legacy"
            >
              <Popover.Header className="d-flex justify-content-between align-items-center x5-px-4 pt-4">
                <span>Notifications</span>
                {unreadNotificationsCount !== null &&
                  unreadNotificationsCount > 0 && (
                    <Button
                      className="rounded-0"
                      level="quartenary"
                      theme="darker"
                      size="sm"
                      onClick={handleAllNotificationsUpdate}
                    >
                      Mark all as read
                    </Button>
                  )}
              </Popover.Header>
              <Popover.Body className="position-relative opacity-100">
                {notificationsContent}
              </Popover.Body>
            </Popover>
          </>
        )}
      </div>

      <div className="me-n4 me-sm-0">
        <Dropdown inNavbar>
          <Avatar
            size="sm"
            image={avatar}
            className="me-3"
            // TODO: refactor cleaner solution for initials
            initials={`${username?.split(" ")[0][0] ?? ""}${
              username?.split(" ")[1][0] ?? ""
            }`}
            dropdown
          />
          <Dropdown.Menu end>
            <Dropdown.Item
              href={`${process.env.REACT_APP_KEYCLOAK_URL}/${process.env.REACT_APP_KEYCLOAK_PROFILE_PATH}?referrer=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`}
            >
              <User24 className="dropdown-icon-prefix" /> Update Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>
              <LogOut24 className="dropdown-icon-prefix" />
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </BPNavbar>
  );
}

export default Navbar;
