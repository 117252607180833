import { memo, useCallback, useMemo } from "react";

import {
  formatDistanceToNow,
  format,
  isSameYear,
  differenceInDays,
} from "date-fns";
import isEqual from "lodash-es/isEqual";
import { isBefore } from "date-fns";
import { Tooltip, ListGroup, Button } from "@bphxd/ds-core-react";
import SvgCircleFill from "@bphxd/ds-core-react/lib/icons/extra/CircleFill";
import { uniqueId } from "lodash-es";
import { Check16 } from "@bphxd/ds-core-react/lib/icons";

export interface NotificationItemProps {
  /**
   * A unique identifier. Is returned in onChange callback.
   */
  id: string;
  /**
   * Notification title.
   */
  title: string;
  /**
   * Notification content.
   */
  message: string;
  /**
   * Date a notification was recieved.
   */
  timestamp: Date;
  /**
   * Date format for notification timestamp tooltip (days months years)
   */
  dateFormat?: string;
  /**
   * Time format for notification timestamp tooltip
   */
  timeFormat?: string;
  /**
   * Whether or not notification is read.
   */
  read: boolean;
  /**
   * Render as an `a` tag instead of a `div` and adds the href attribute.
   */
  link?: {
    href: string;
    target?: "_blank" | "_self" | "_parent" | "_top";
  };
  /**
   * Called when a notification is clicked.
   */
  onClick?: (notificationId: string, read: boolean) => void;
  /**
   * Called when a notification read/unread flag is clicked.
   */
  onChange?: (id: string, read: boolean) => void;
}

export const NotificationItem = memo(
  (props: NotificationItemProps) => {
    const {
      id,
      title,
      message,
      link,
      timestamp,
      dateFormat = "MMM dd",
      timeFormat = "HH:mm",
      read,
      onChange,
      onClick,
    } = props;

    const handleClick = useCallback(() => {
      if (typeof onClick == "function") {
        onClick(id, !read);
      }
    }, [id, onClick, read]);

    const handleChange = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof onChange == "function") {
          onChange(id, !read);
        }
      },
      [id, onChange, read]
    );

    const notificationTime = useMemo(() => {
      const currentDate = new Date();

      if (!isSameYear(timestamp, currentDate)) {
        return format(timestamp, "MMM dd, yyyy");
      } else if (
        differenceInDays(currentDate, timestamp) <= 1 &&
        isBefore(timestamp, currentDate)
      ) {
        return `${formatDistanceToNow(timestamp)} ago`;
      } else {
        return format(timestamp, "MMM dd");
      }
    }, [timestamp]);

    const readStatusTooltipId = useMemo(
      () => uniqueId("read-status-tooltip"),
      []
    );

    const timeTooltipId = useMemo(
      () => uniqueId("notification-time-tooltip"),
      []
    );

    return (
      <div className="d-flex align-items-center position-relative ">
        <ListGroup.Item
          action={!!link || typeof onClick === "function"}
          href={link?.href ?? undefined}
          onClick={handleClick}
          className="py-3 notifications-list__item d-flex flex-column x5-pe-4 x5-ps-8"
        >
          <ListGroup.ItemTitle subtitle={message}>
            <div className="w-100 d-flex gap-3">
              <div className="flex-1">{title}</div>
              <div className="text-nowrap fs-6" id={timeTooltipId}>
                {notificationTime}
              </div>
              <Tooltip fade={false} target={timeTooltipId} delay={700}>
                {format(timestamp, `${dateFormat} ${timeFormat}`)}
              </Tooltip>
            </div>
          </ListGroup.ItemTitle>
        </ListGroup.Item>
        <div className="position-absolute z-3 ps-2">
          <Button
            size="sm"
            iconOnly
            level="quartenary"
            onClick={handleChange}
            id={readStatusTooltipId}
            className="d-flex align-items-center justify-content-center"
            Icon={
              read
                ? Check16
                : () => (
                    <SvgCircleFill className="text-info" height={8} width={8} />
                  )
            }
          />
          <Tooltip fade={false} target={readStatusTooltipId} delay={700}>
            <span>{`Mark as ${read ? "unread" : "read"}`}</span>
          </Tooltip>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);
