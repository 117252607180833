/** @format */

import React, { memo } from "react";
import { Alert16, Alert24, Alert32 } from "@bphxd/ds-core-react/lib/icons";
import { Button } from "@bphxd/ds-core-react";

export interface ErrorProps {
  text?: string;
  children?: React.ReactNode;
  size?: "sm" | "lg";
  overlay?: boolean;
  className?: string;
  action?: string;
  onActionClick?: () => void;
}

export const Error = memo((props: ErrorProps) => {
  const {
    children,
    text = "Error",
    size,
    overlay = false,
    className,
    action,
    onActionClick,
  } = props;

  return (
    <div
      className={`d-flex align-items-center justify-content-center p-4 text-center ${
        overlay ? "position-absolute top-0 start-0 end-0 bottom-0" : ""
      } ${className}`}
    >
      {overlay ? (
        <div className="position-absolute top-0 start-0 end-0 bottom-0 bg-overlay-blur z-1" />
      ) : null}
      <div className="d-flex flex-column align-items-center position-relative z-2 text-danger">
        {size === "sm" ? (
          <Alert16 />
        ) : size === "lg" ? (
          <Alert32 />
        ) : (
          <Alert24 />
        )}

        {children || (
          <p className="pt-2">
            {size === "sm" ? (
              <small>{text}</small>
            ) : size === "lg" ? (
              <span className="h5">{text}</span>
            ) : (
              text
            )}
          </p>
        )}
        {action ? (
          <Button
            className="mt-1"
            size={size}
            onClick={onActionClick}
            level="tertiary"
          >
            {action}
          </Button>
        ) : null}
      </div>
    </div>
  );
});
