/** @format */

import React, { memo, useMemo, useCallback, useState, SVGProps } from "react";
import {
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ComposedChart as ComposedChartR,
  Area,
  LineProps,
  AreaProps,
  XAxisProps,
  Legend,
  ReferenceArea,
  BarProps,
  LabelProps,
  LabelList,
  Label,
} from "recharts";
import { isBoolean, isEmpty, isNumber, uniqBy } from "lodash-es";
import { CustomTooltipWithPortal } from "./CustomTooltip";
import {
  ChartLayerSettings,
  ChartLayerType,
  ComposedChartProps,
  CustomLegendProps,
  CustomReferenceAreaProps,
  LineBarProps,
} from "./types";
import { defaultColor } from "./utils";
import { compareDates } from "src/lib/utils";
import { getChartColors } from "@bphxd/ds-core-react";
import { extractRightYAxisParams } from "src/lib/v2ConfigUtils";
import { defaultYAxisFormatter } from "src/lib/formatters";

const LineBar = (props: LineBarProps) => {
  const { fill, x, y, width, opacity } = props;

  return (
    <rect
      stroke="none"
      fill={fill}
      x={x - 5}
      y={y}
      width={width + 10}
      height={4}
      opacity={opacity}
    />
  );
};

const HatchedBar = (props: BarProps & { maskId: string }) => {
  const {
    fill,
    x,
    y: originalY,
    width,
    height: originalHeight,
    maskId,
    opacity,
  } = props;

  let y =
    typeof originalHeight === "number" &&
    typeof originalY === "number" &&
    originalHeight < 0
      ? originalY + originalHeight
      : originalY;
  let height =
    typeof originalHeight === "number"
      ? Math.abs(originalHeight)
      : originalHeight;

  return (
    <rect
      fill={fill}
      mask={`url(#${maskId})`}
      x={x}
      y={y}
      width={width}
      height={height}
      opacity={opacity}
    />
  );
};

const LabelWithChangeIndicator = (
  props: Omit<SVGProps<SVGTextElement>, "viewBox"> &
    LabelProps & {
      additionalText?: string;
      inverseLogic?: boolean;
    }
) => {
  const {
    x: xOriginal,
    y: yOriginal,
    value,
    width,
    height,
    fill,
    opacity,
    formatter,
  } = props;
  const { additionalText, inverseLogic, ...onlyLabelProps } = props;
  const fontSize = 10;

  if (!value) {
    return null;
  } else if (isNaN(Number(value))) {
    return Label(onlyLabelProps);
  }

  let valueIsNegative = Number(value) < 0;

  if (inverseLogic) {
    valueIsNegative = !valueIsNegative;
  }

  const x = Number(xOriginal) + Number(width) / 2;
  const y = Number(yOriginal) + (Number(height) < 0 ? Number(height) : 0);

  const indicator = valueIsNegative ? "▼" : "▲";

  const valueToShow = formatter ? formatter(Number(value)) : value;

  const additionalTextToShow = additionalText ? additionalText : "";

  const labelToShow = `${indicator} ${valueToShow} ${additionalTextToShow}`;

  if (labelToShow.length * (fontSize / 2) > Number(width) + 10) {
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      dy={-5}
      fontSize={fontSize}
      opacity={opacity}
      fill={fill}
      textAnchor="middle"
    >
      {labelToShow}
    </text>
  );
};

const defaultChartMargin = { top: 15, right: 10, bottom: 15, left: 10 };
const xAxisPadding = { left: 50, right: 10 };

const defaultValueFormatter = (value: number): number => {
  return Math.round(value * 100) / 100;
};

const defaultLineType: LineProps["type"] | AreaProps["type"] = "monotone";

const calculateDomainWithPadding = (
  domain: XAxisProps["domain"],
  data: Array<Record<string, string | number | null | undefined>> | undefined,
  xKey: string,
  numericAxisPadding: number
): XAxisProps["domain"] => {
  if (data && !isEmpty(data) && domain && typeof domain !== "function") {
    if (domain[0] === "dataMin" && domain[1] === "dataMax") {
      const domainPadding =
        data.length > 1
          ? (numericAxisPadding *
              (Number(data[data.length - 1][xKey]) - Number(data[0][xKey]))) /
            100
          : 0;

      // take maxDomain minus minDomain and add some space (numericAxisPadding%) to both sides
      return [`dataMin - ${domainPadding}`, `dataMax + ${domainPadding}`];
    } else if (typeof domain[0] === "number" && typeof domain[1] === "number") {
      const domainPadding =
        (numericAxisPadding * (domain[1] - domain[0])) / 100;
      return [domain[0] - domainPadding, domain[1] + domainPadding];
    }
  }
  return domain;
};

export const ComposedChart = memo(
  ({
    data,
    xKey = "name",
    unit,
    layers: layerList = [],
    axisValueFormatter = defaultValueFormatter,
    tooltipValueFormatter = axisValueFormatter,
    tooltipTitleFormatter,
    yAxisLeftProps = {},
    yAxisRightProps = {},
    xAxisProps = {},
    showLegend,
    referenceAreaProps,
    chartId,
    dateFormat,
    showTotalInTooltip = true,
    numericAxisPadding = 0,
    locale = navigator.language,
    additionalLegendItems,
    margin,
    syncId,
    onTooltipActive,
  }: ComposedChartProps) => {
    const chartColors = getChartColors(layerList.length);
    const layers = layerList.map((layer, index) => ({
      ...layer,
      color: chartColors[index % chartColors.length],
    }));

    const defaultYAxisRightProps = extractRightYAxisParams(layers) ?? {};

    const [layerVisibility, setLayerVisibility] = useState<
      Record<string, boolean>
    >({});

    const xAxisPropsRecalculated: XAxisProps = useMemo(
      () =>
        numericAxisPadding > 0
          ? {
              ...xAxisProps,
              domain: calculateDomainWithPadding(
                xAxisProps.domain,
                data,
                xKey,
                numericAxisPadding
              ),
            }
          : xAxisProps,
      [data, numericAxisPadding, xAxisProps, xKey]
    );

    // collect a list of xAxis datapoints used in referenceArea in case of references to missing datapoints in the chart data set
    const xRefs: Record<string, string | number>[] = useMemo(() => {
      const xList: string[] = [];
      if (!referenceAreaProps || !referenceAreaProps.length || !dateFormat)
        return [];
      // if dateFormat is not provided, try to infer it
      // let inferredDateFormat: string;
      // if (!dateFormat && referenceAreaProps[0].referenceArea.x1) {
      //   inferredDateFormat = getDateFormat({
      //     userDate: referenceAreaProps[0].referenceArea.x1.toString(),
      //   });
      // }
      // collect a list of all reference area points
      referenceAreaProps.forEach((refArea: CustomReferenceAreaProps) => {
        let refs = refArea.referenceArea;
        if (refs.x1) xList.push(refs.x1.toString());
        if (refs.x2) xList.push(refs.x2.toString());
      });
      // remove duplicates
      const uniqueXs = Array.from(new Set<string>(xList));
      uniqueXs.sort((a: string, b: string) => {
        if (a === b) return 0;
        return compareDates(a, b, dateFormat, -1) ? -1 : 1;
      });
      // return list of xKey formatted objects
      return uniqueXs.map((key) => {
        const datapoint: Record<string, string | number> = {};
        datapoint[xKey] = key;
        return datapoint;
      });
    }, [referenceAreaProps, dateFormat, xKey]);

    // if the value-part of input data is a number, it can be formatted
    const formattedData = useMemo(() => {
      if (!data) return [];
      // select all yKey-s from chart layer configuration that are actually in use
      const yKeys = new Set<string>();
      layers.forEach((layer) => {
        yKeys.add(layer.yKey);
      });

      // TODO: this should be refactored to make it more readable
      data?.forEach(
        (item: Record<string, string | number | null | undefined>, idx) => {
          if (xRefs.length && dateFormat?.length) {
            let peek = xRefs[0];
            if (peek[xKey] === item[xKey]) {
              xRefs.shift();
            } else if (
              compareDates(
                peek[xKey].toString(),
                item[xKey] ? (item[xKey] as string | number).toString() : null,
                dateFormat,
                idx <= data.length ? -1 : 1
              )
            ) {
              data.splice(idx++, 0, peek);
              xRefs.shift();
            }
          }
          idx++;
          if (idx > data.length - 1 && dateFormat?.length) {
            while (xRefs.length) {
              data.push(xRefs[0]);
              xRefs.shift();
            }
          }

          const formattedItem = { ...item };
          yKeys.forEach((yKey) => {
            formattedItem[yKey] = isNumber(item[yKey])
              ? axisValueFormatter(Number(item[yKey]))
              : item[yKey];
          });
          return formattedItem;
        }
      );

      return data;
    }, [layers, data, xRefs, dateFormat, xKey, axisValueFormatter]);

    const getUniqueLayerId = useCallback(
      (layer: ChartLayerSettings) =>
        `${chartId}${layer.type}${layer.yKey.replace(/\s/g, "")}`,
      [chartId]
    );

    const hatchedPatternMaskOptions = useMemo(() => {
      return {
        patternId: chartId + "hatched-pattern",
        maskId: chartId + "hatched-mask",
        legendPatternId: chartId + "hatched-pattern-legend",
        legendMaskId: chartId + "hatched-mask-legend",
      };
    }, [chartId]);

    // generate hatched pattern defs based on chartId
    const hatchedPattern = useMemo(() => {
      const { patternId, maskId, legendPatternId, legendMaskId } =
        hatchedPatternMaskOptions;

      return (
        <React.Fragment>
          <pattern
            id={patternId}
            width="8"
            height="8"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
          >
            <rect
              width="2"
              height="8"
              transform="translate(0,0)"
              fill="white"
            ></rect>
          </pattern>

          <pattern
            id={legendPatternId}
            width="4"
            height="4"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
          >
            <rect
              width="1"
              height="4"
              transform="translate(0,0)"
              fill="white"
            ></rect>
          </pattern>

          <mask id={maskId}>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill={`url(#${patternId})`}
            />
          </mask>

          <mask id={legendMaskId}>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill={`url(#${legendPatternId})`}
            />
          </mask>
        </React.Fragment>
      );
    }, [hatchedPatternMaskOptions]);

    const generateChartLayers = useCallback(
      (
        layers: ChartLayerSettings[],
        layerVisibility: Record<string, boolean>,
        dataCount: number
      ) => {
        return layers
          .filter((layer) => !layer.hidden)
          .sort((layer1, layer2) => {
            if (
              typeof layer1.order === "number" &&
              typeof layer2.order === "number"
            ) {
              return (layer1.order ?? 0) - (layer2.order ?? 0);
            }
            return 0;
          })
          .map(({ isAnimationActive = true, ...layer }, idx) => {
            switch (layer.type) {
              case ChartLayerType.BAR:
                return (
                  <Bar
                    key={`${getUniqueLayerId(layer)}-bar-${idx}`}
                    xAxisId={layer.xAxisId}
                    yAxisId={layer.yAxisId || "left"}
                    dataKey={layer.yKey}
                    stackId={layer.stackId}
                    fill={layer.color}
                    hide={layerVisibility[layer.yKey] === false}
                    shape={
                      layer.shape === "bar-line"
                        ? (props) => <LineBar {...props} />
                        : layer.shape === "hatched-bar"
                        ? (props) => {
                            const { maskId } = hatchedPatternMaskOptions;
                            return <HatchedBar {...props} maskId={maskId} />;
                          }
                        : undefined
                    }
                    /* NOTE: If:
                              1. only one bar is present in the chart, 
                              2. x-axis is numerical (<XAxis type="number" ... />),
                              3. the bar layer is hidden and maxBarSize is set (<Bar hide="true" maxBarSize={50} ... />).
                             Then recharts fails to calculate the bar's position and error is shown
                             Setting maxBarSize to "undefined" when the layer is hidden, is a hotfix until issue is resolved in recharts.
                    */
                    maxBarSize={
                      layerVisibility[layer.yKey] === false ? undefined : 50
                    }
                    isAnimationActive={isAnimationActive}
                    order={layer.order}
                  >
                    {layer.barLabel?.show &&
                    layerVisibility[layer.yKey] !== false ? (
                      <LabelList
                        dataKey={layer.yKey}
                        position={layer.barLabel?.position || "top"}
                        formatter={layer.barLabel?.valueFormatter}
                        fill={layer.color}
                        content={
                          layer.barLabel?.type === "withIndicator"
                            ? (props) => (
                                <LabelWithChangeIndicator
                                  {...props}
                                  additionalText={
                                    layer.barLabel?.additionalText
                                  }
                                  inverseLogic={layer.barLabel?.inverseLogic}
                                />
                              )
                            : undefined
                        }
                      />
                    ) : null}
                  </Bar>
                );
              case ChartLayerType.LINE:
                return (
                  <Line
                    id={layer.id}
                    key={`${getUniqueLayerId(layer)}-line-${idx}`}
                    yAxisId={layer.yAxisId || "left"}
                    dataKey={layer.yKey}
                    type={
                      (layer.lineType as LineProps["type"]) || defaultLineType
                    }
                    stroke={layer.color || defaultColor}
                    strokeWidth={layer.strokeWidth || 3}
                    strokeDasharray={layer.dashedLine ? "6 2" : undefined}
                    hide={layerVisibility[layer.yKey] === false}
                    connectNulls={layer.connectNulls}
                    dot={layer.dot}
                    isAnimationActive={isAnimationActive}
                    order={layer.order}
                  />
                );
              case ChartLayerType.AREA:
                return (
                  <Area
                    key={`${getUniqueLayerId(layer)}-area-${idx}`}
                    yAxisId={layer.yAxisId || "left"}
                    type={
                      (layer.lineType as AreaProps["type"]) || defaultLineType
                    }
                    dataKey={layer.yKey}
                    stroke={layer.color || defaultColor}
                    strokeWidth={layer.strokeWidth || 3}
                    fill={layer.color || defaultColor}
                    hide={layerVisibility[layer.yKey] === false}
                    connectNulls={layer.connectNulls}
                    isAnimationActive={isAnimationActive}
                    order={layer.order}
                  />
                );
              default:
                return null;
            }
          });
      },
      [getUniqueLayerId, hatchedPatternMaskOptions]
    );

    const datasetParamsFormattedForTooltip = useMemo(() => {
      return layers.reduce((accParams, layer) => {
        const dataForLayerExists = formattedData.some(
          (data) => data[layer.yKey]
        );

        const hidden =
          layer.hidden ||
          (!dataForLayerExists && layer.hiddenEmpty) ||
          undefined;

        return {
          ...accParams,
          [layer.yKey]: {
            color: layer.color || defaultColor,
            mask:
              layer.shape === "hatched-bar"
                ? `url(#${hatchedPatternMaskOptions.legendMaskId})`
                : undefined,
            dataLabel: layer.yLabel || layer.yKey,
            hidden,
            tooltipValueFormatter: layer.tooltipValueFormatter,
          },
        };
      }, {});
    }, [layers, formattedData, hatchedPatternMaskOptions.legendMaskId]);

    const isBarLabelShown = useMemo(
      () => layers.some((layer) => layer.barLabel?.show),
      [layers]
    );

    const chartMargin = useMemo(
      () => ({
        top: margin?.top ?? (isBarLabelShown ? 30 : defaultChartMargin.top),
        right: margin?.right ?? defaultChartMargin.right,
        bottom: margin?.bottom ?? defaultChartMargin.bottom,
        left: margin?.left ?? defaultChartMargin.left,
      }),
      [
        isBarLabelShown,
        margin?.bottom,
        margin?.left,
        margin?.right,
        margin?.top,
      ]
    );

    const renderLegend = useCallback(
      ({ payload = [], additionalLegendItems, onClick }: CustomLegendProps) => {
        return (
          <div
            className="recharts-legend"
            style={{
              marginLeft: chartMargin.left + 20,
              marginRight: chartMargin.right + 20,
              marginBottom: 15,
            }}
          >
            <div className="d-flex flex-wrap justify-content-end pb-2 pe-2">
              {payload.map((entry, index) => {
                const layer = layers.find(
                  (layer) => layer.yKey === entry.value
                );

                const dataForLayerExists = formattedData.some((data) =>
                  layer ? data[layer.yKey] : false
                );

                const isHidden =
                  layer?.hidden || (!dataForLayerExists && layer?.hiddenEmpty);

                return (
                  <div
                    key={`item-${index}`}
                    className={`recharts-legend__item align-items-center${
                      layers.length > 1 ? "recharts-legend__item--toggle" : ""
                    } ${
                      layerVisibility[entry.value] === false
                        ? "recharts-legend__item--hidden"
                        : ""
                    } ps-1 pe-3 pb-1 ${
                      isHidden ? "recharts-legend__item--completly-hidden" : ""
                    }`}
                    onClick={() =>
                      layers.length > 1 && onClick
                        ? // @ts-ignore TODO fix types
                          onClick(entry)
                        : null
                    }
                  >
                    {layer?.type === "line" || layer?.shape === "bar-line" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 2"
                      >
                        <line
                          x1="1"
                          y1="0"
                          x2="15"
                          y2="0"
                          strokeWidth="2"
                          strokeDasharray={layer.dashedLine ? "6 2" : undefined}
                          stroke={entry.color}
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="0"
                          ry="0"
                          width="14"
                          height="14"
                          strokeWidth="1"
                          fill={entry.color}
                          mask={
                            layer?.shape === "hatched-bar"
                              ? `url(#${hatchedPatternMaskOptions.legendMaskId})`
                              : undefined
                          }
                        />
                      </svg>
                    )}
                    <span className="ps-1">
                      <small>
                        {layer?.yLabel || entry.value}
                        {layer?.unit ? ` [${layer.unit}]` : ""}
                      </small>
                    </span>
                  </div>
                );
              })}
              {additionalLegendItems?.length
                ? additionalLegendItems.map(({ label, svgStyles }, index) => {
                    return (
                      <div
                        key={`item-${index}`}
                        className="recharts-legend__item ps-1 pe-3 pb-1 text-muted"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="0"
                            ry="0"
                            width="14"
                            height="14"
                            {...(svgStyles ?? {})}
                          />
                        </svg>
                        <span className="ps-1">
                          <small>{label}</small>
                        </span>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        );
      },
      [
        layers,
        layerVisibility,
        formattedData,
        hatchedPatternMaskOptions.legendMaskId,
        chartMargin,
      ]
    );

    const handleLegendItemClick = useCallback(({ dataKey }) => {
      setLayerVisibility((layerVisibility) => ({
        ...layerVisibility,
        [dataKey]: isBoolean(layerVisibility[dataKey])
          ? !layerVisibility[dataKey]
          : false,
      }));
    }, []);

    return (
      <div style={{ width: "100%", height: "100%" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChartR
            syncId={syncId}
            data={formattedData}
            margin={chartMargin}
          >
            <defs>{hatchedPattern}</defs>
            <CartesianGrid vertical={false} />
            {referenceAreaProps &&
              !isEmpty(referenceAreaProps) &&
              referenceAreaProps.map((areaProps) => (
                <ReferenceArea
                  yAxisId="left"
                  key={`area${areaProps.id}`}
                  {...areaProps.referenceArea}
                />
              ))}
            {generateChartLayers(layers, layerVisibility, formattedData.length)}
            <Tooltip
              wrapperStyle={{ outline: "none" }}
              filterNull={false}
              isAnimationActive={false}
              content={
                <CustomTooltipWithPortal
                  data={data}
                  xKey={xKey}
                  unit={unit}
                  onTooltipActive={onTooltipActive}
                  xAxisProps={xAxisPropsRecalculated}
                  valueParams={datasetParamsFormattedForTooltip}
                  valueFormatter={tooltipValueFormatter}
                  titleFormatter={tooltipTitleFormatter}
                  layers={layers}
                  referenceAreaProps={referenceAreaProps}
                  showTotal={showTotalInTooltip}
                  locale={locale}
                />
              }
            />
            {showLegend && (
              <Legend
                content={(props) =>
                  // @ts-ignore
                  renderLegend({ ...props, additionalLegendItems })
                }
                onClick={handleLegendItemClick as any}
                verticalAlign="top"
                layout="horizontal"
              />
            )}
            <XAxis
              axisLine={true}
              dataKey={xKey}
              padding={xAxisPadding}
              tickMargin={10}
              {...xAxisPropsRecalculated}
            />
            {uniqBy(
              layers.filter((item) => item.xAxisId),
              "xAxisId"
            ).map((layer, index) => (
              <XAxis
                key={index}
                xAxisId={layer.xAxisId}
                axisLine={true}
                dataKey={xKey}
                padding={xAxisPadding}
                {...xAxisPropsRecalculated}
                hide={true}
              />
            ))}
            <YAxis
              yAxisId="left"
              axisLine={true}
              minTickGap={-15}
              tickFormatter={defaultYAxisFormatter}
              {...yAxisLeftProps}
            />
            <YAxis
              yAxisId="right"
              axisLine={true}
              minTickGap={-15}
              orientation="right"
              tickFormatter={defaultYAxisFormatter}
              {...defaultYAxisRightProps}
              {...yAxisRightProps}
            />
          </ComposedChartR>
        </ResponsiveContainer>
      </div>
    );
  }
);
