import React from "react";
import { ComposedChartProps } from "ui-core";

export const xAxisPadding = { left: 50, right: 50 };

export const numericAxisPadding = 10;
export const numericXAxisProps: Partial<ComposedChartProps["xAxisProps"]> = {
  type: "number",
  domain: ["dataMin", "dataMax"],
  tickCount: 0,
  minTickGap: 8,
  padding: "no-gap",
  interval: "preserveStartEnd",
};

interface CustomLineDotProps {
  cy?: string | number;
  stroke?: string;
  opacity?: string | number;
}

// when an axis tick label is long, custom formatter splits the label into multiple lines at \n character
export function CustomizedMultilineAxisTick(props: any) {
  const { x, y, payload, fill, textAnchor } = props;
  const newLines = payload.value.split("\n");
  return (
    <g transform={`translate(${x},${y + 12})`}>
      <text x={0} y={0} fontSize={10} fill={fill} textAnchor={textAnchor}>
        {newLines.reverse().map((value: string, index: number) => (
          <tspan key={index} textAnchor="middle" x={0} dy={-12}>
            {value}
          </tspan>
        ))}
      </text>
    </g>
  );
}

export function CustomLineDot(props: CustomLineDotProps) {
  const { cy, stroke, opacity } = props;

  return (
    <svg
      x={xAxisPadding.left}
      y={cy}
      width={`calc(100% - ${xAxisPadding.left * 2}px)`}
      height={3}
      fill="red"
      viewBox="0 0 100% 3"
      opacity={opacity}
    >
      <line x1="0" y1="0" x2="100%" y2="0" stroke={stroke} strokeWidth="3" />
    </svg>
  );
}

interface CustomLineLabelProps {
  y?: string | number;
  stroke?: string;
  value: number;
  unit: string;
}

export function CustomLineLabel(props: CustomLineLabelProps) {
  const { y, stroke, value, unit } = props;

  return (
    <text
      x="100%"
      y={y}
      dx={-xAxisPadding.left + 5}
      dy={5}
      fill={stroke}
      fontSize={10}
      textAnchor="left"
    >
      {Math.round(value)} {unit}
    </text>
  );
}
