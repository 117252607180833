import { getLocaleNumber } from "src/lib/utils";

export enum EnergyAsset {
  "combinedHeatPower" = "chp",
  "solar" = "pv",
  "fuelCell" = "fc",
  "energyStorage" = "ess",
  "thermalStorage" = "tes",
  "electricVehicleCharging" = "ev",
  "wind" = "wind",
  "eload" = "eload",
  "load" = "load", // old version, for compatibility
  "dieselFiredGenerator" = "dslgen",
  "gasFiredGenerator" = "nggen",
  "inverter" = "invt",
  "electricGrid" = "egrid",
  "naturalGasDistribution" = "ngload",
  "steamDistribution" = "stload",
}

export const assetAcronymNameMap = new Map<EnergyAsset, string>([
  [EnergyAsset.combinedHeatPower, "Combined Heat and Power"],
  [EnergyAsset.solar, "Solar"],
  [EnergyAsset.fuelCell, "Fuel Cell"],
  [EnergyAsset.energyStorage, "Energy Storage"],
  [EnergyAsset.thermalStorage, "Thermal Storage"],
  [EnergyAsset.electricVehicleCharging, "Electric Vehicle Charging"],
  [EnergyAsset.wind, "Wind"],
  [EnergyAsset.eload, "Electrical Load"],
  [EnergyAsset.load, "Electrical Load"], // old version, for compatibility
  [EnergyAsset.dieselFiredGenerator, "Diesel Fired Generator"],
  [EnergyAsset.gasFiredGenerator, "Gas Fired Generator"],
  [EnergyAsset.inverter, "Inverter"],
  [EnergyAsset.electricGrid, "Electric Grid"],
  [EnergyAsset.naturalGasDistribution, "Natural Gas Distribution"],
  [EnergyAsset.steamDistribution, "Steam Distribution"],
]);

export interface AssetCapacityItem {
  energyCapacity?: number;
  powerCapacity?: number;
}

export interface AssetCapacity {
  [key: string]: AssetCapacityItem;
}

export const getAssetCapacity = (
  assetCapacity: AssetCapacity,
  asset: string,
  locale: string = navigator.language
): string => {
  let capacityString: any[] = [];
  if (assetCapacity && assetCapacity[asset]) {
    const powerCapacity = assetCapacity[asset].powerCapacity;
    const energyCapacity = assetCapacity[asset].energyCapacity;

    if (powerCapacity)
      capacityString.push(`${getLocaleNumber(locale, powerCapacity)}kW`);

    if (energyCapacity)
      capacityString.push(`${getLocaleNumber(locale, energyCapacity)}kWh`);
  }
  return capacityString.join(" / ");
};

export const filterUniqueAssets = (
  assets: EnergyAsset[],
  assetsMapping?: Map<string, string>
) => {
  const mappedUniqueAssets = new Set(
    assets.map(
      (originalAssetName) =>
        (assetsMapping?.get(originalAssetName) as EnergyAsset) ||
        originalAssetName
    )
  );

  return assets.reduce((uniqueAssets: EnergyAsset[], originalAssetName) => {
    const mappedAssetName =
      (assetsMapping?.get(originalAssetName) as EnergyAsset) ||
      originalAssetName;

    if (mappedUniqueAssets.has(mappedAssetName)) {
      uniqueAssets.push(originalAssetName);
      mappedUniqueAssets.delete(mappedAssetName);
    }

    return uniqueAssets;
  }, []);
};
