/** @format */

import React, { memo } from "react";
import {
  ChartLayerType,
  ComposedChart,
  ComposedChartProps,
  NumberFormatter,
} from "./ComposedChart";
import { XAxisProps } from "recharts";
import { TooltipTitleFormatter, TooltipValueFormatter } from "./ComposedChart";

export interface BarChartProps {
  /**
   * Unique ID of a chart, used when generating layer gradients
   */
  chartId: string;
  /**
   * Optional array of data objects
   */
  data?: ComposedChartProps["data"];
  /**
   * Optional string ID for referencing the layer in a stack
   */
  stackId?: string;
  /**
   * Optional string specification of the y variable
   */
  yKey?: string;
  /**
   * Optional string specification of the x variable
   */
  xKey?: string;
  /**
   * Optional tooltip text label for the y variable
   */
  yLabel?: string;
  /**
   * Optional tooltip unit text for the y values
   */
  unit?: string;
  /**
   * Optional numerical value formatter
   */
  axisValueFormatter?: NumberFormatter;
  /**
   * Optional tooltip numerical value formatter
   */
  tooltipValueFormatter?: TooltipValueFormatter;
  /**
   * Optional tooltip title value formatter
   */
  tooltipTitleFormatter?: TooltipTitleFormatter;
  /**
   * Optional date format string for labels
   */
  dateFormat?: string | undefined;
  /**
   * Optional object to customize the x-axis
   */
  xAxisProps?: XAxisProps;
  /**
   * TMPfix for recharts not showing numeric barcharts correctly
   * Number o % that will be added to the left and right to add some padding on numeric x axis (when dataMin, dataMax is used)
   */
  numericAxisPadding?: number;
}

export const BarChart = memo((props: BarChartProps) => {
  const {
    chartId,
    data,
    stackId,
    xKey = "name",
    yKey = "value",
    yLabel,
    unit,
    axisValueFormatter,
    tooltipValueFormatter,
    tooltipTitleFormatter,
    dateFormat,
    xAxisProps = {},
    numericAxisPadding = 0,
  } = props;

  return (
    <ComposedChart
      chartId={chartId}
      data={data}
      stackId={stackId}
      xKey={xKey}
      unit={unit}
      layers={[
        {
          type: ChartLayerType.BAR,
          yKey,
          yLabel,
        },
      ]}
      axisValueFormatter={axisValueFormatter}
      tooltipValueFormatter={tooltipValueFormatter}
      tooltipTitleFormatter={tooltipTitleFormatter}
      dateFormat={dateFormat}
      xAxisProps={xAxisProps}
      numericAxisPadding={numericAxisPadding}
    />
  );
});
