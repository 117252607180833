import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Loader } from "ui-core";
import { useKeycloak } from "./KeycloakProvider";

/**
 * Basically a copy of ProtectedRoute but used for wrapping
 */
function AuthWrapper(props: React.PropsWithChildren<{}>) {
  const { children } = props;

  let location = useLocation();

  const { authenticated, authenticating } = useKeycloak();

  if (authenticating) {
    return <Loader text="Authenticating..." overlay />;
  }

  return (
    <>
      {authenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/silent-login",
            state: { from: location },
          }}
        />
      )}
    </>
  );
}

export default AuthWrapper;
