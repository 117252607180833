export const headerHeight = 72;
export const footerHeight = 40;
export const breadcrumbsHeight = 39;

// breakpoints
export const XSBREAKPOINT = 0;
export const SMALLBREAKPOINT = 576;
export const MEDIUMBREAKPOINT = 768;
export const LARGEBREAKPOINT = 992;
export const XLARGEBREAKPOINT = 1200;
export const XXLARGEBREAKPOINT = 1400;

export const breakpoints = {
  xxl: XXLARGEBREAKPOINT,
  xl: XLARGEBREAKPOINT,
  lg: LARGEBREAKPOINT,
  md: MEDIUMBREAKPOINT,
  sm: SMALLBREAKPOINT,
  xs: XSBREAKPOINT,
};

export const gridColumns = { xxl: 12, xl: 12, lg: 12, md: 6, sm: 6, xs: 6 };
export const gridContainerPadding = 6;
export const gridRowHeight = 100;
export const initialGridWidth = 1280;

// https://getbootstrap.com/docs/5.0/layout/z-index/
export const zindexDropdown = 1000;
export const zindexSticky = 1020;
export const zindexFixed = 1030;
export const zindexModalBackdrop = 1040;
export const zindexOffcanvas = 1050;
export const zindexModal = 1060;
export const zindexPopover = 1070;
export const zindexTooltip = 1080;

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const electricBaselineLoadId = "electric-baseline-load";
export const electricTargetLoadId = "electric-target-load";

export const RADIAN = Math.PI / 180;
