/** @format */

import React, { memo } from "react";

export interface EmptyProps {
  text?: string;
  className?: string;
  overlay?: boolean;
}

export const Empty = memo((props: EmptyProps) => {
  const { text, className, overlay } = props;

  return (
    <div
      className={`d-flex align-items-center justify-content-center p-4 text-center ${
        overlay ? "position-absolute top-0 start-0 end-0 bottom-0" : ""
      } ${className}`}
    >
      <div>{text || "No data available"}</div>
    </div>
  );
});
