import { LabelWithDescription } from "@blocks/MarketsCard";
import {
  LineProps,
  AreaProps,
  YAxisProps,
  XAxisProps,
  LegendProps,
  ReferenceAreaProps,
  LabelProps,
} from "recharts";

export type NumberFormatter = (value: number) => number;

export type TooltipValueFormatter = (
  value: number,
  locale?: string
) => string | number;
export type TooltipTitleFormatter = (value: number | Date | string) => string;

export enum ChartLayerType {
  BAR = "bar",
  LINE = "line",
  AREA = "area",
}
export type CustomReferenceAreaProps = {
  id: string;
  label: ReferenceAreaProps["label"];
  value: string | number;
  description?: string;
  referenceArea: ReferenceAreaProps;
  hideInTooltip?: boolean;
  additionalInfo?: LabelWithDescription;
};

export interface BarLabelProps {
  /**
   * Boolean whether the label should be show. By default label is hidden.
   */
  show: boolean;
  /**
   * Custom label type, if not defined default albel is used.
   */
  type?: "withIndicator";
  /**
   * Label position.
   * Label positon for custom label type is always on the top of bar.
   */
  position?: LabelProps["position"];
  /**
   * Additional text to show in label, only used when type is defined.
   */
  additionalText?: string;
  /**
   * Inverse logic of label type "withIndicator".
   * When 'true' indicator icons will be showed inversed.
   * By default the "lower" triangle icon is shown for negative values.
   */
  inverseLogic?: boolean;
  /**
   * Formatter of the label value.
   */
  valueFormatter?: TooltipValueFormatter;
}

export interface ChartLayerSettings {
  /**
   * Type of chart: bar, line, or area
   */
  type: ChartLayerType;
  /**
   * Unique layer ID
   */
  id?: string;
  /**
   * Type of chart: bar, line, or area
   */
  shape?: "bar-line" | "hatched-bar";
  /**
   * Boolean whether the layer should be hidden
   */
  hidden?: boolean;
  /**
   * Boolean whether the layer should be hidden if data doesn't contains any data
   */
  hiddenEmpty?: boolean;
  /**
   * Optional string ID when referencing layers in the stack
   */
  stackId?: string;
  /**
   * Optional string ID referencing a y-axis for mixed axes
   */
  yAxisId?: string;
  /**
   * Optional string ID referencing a x-axis for mixed axes
   */
  xAxisId?: string;
  /**
   * Y variable key string of the input data
   */
  yKey: string;
  /**
   * Optional text label for the y variable
   */
  yLabel?: string;
  /**
   * Optional color hex string
   */
  color?: string;
  /**
   * Optional stroke width for line or area chart
   */
  strokeWidth?: number;
  /**
   * Optional specification of the line type (see Recharts for line types)
   */
  lineType?: LineProps["type"] | AreaProps["type"];
  /**
   * Optional value if line should be dashed, default is false
   */
  dashedLine?: boolean;
  /**
   * Optional unit text
   */
  unit?: string;
  /**
   * Optional text or element description
   */
  description?: string | HTMLSpanElement;
  /**
   * Boolean whether nulls should be connected
   */
  connectNulls?: boolean;
  /**
   * Toggle Line dot or customized it
   */
  dot?: boolean | object | React.ReactElement;
  /**
   * Props of bar label which should by showed on top of graph.
   */
  barLabel?: BarLabelProps;
  /**
   * Function to format value showed in chart tooltip.
   * Tooltip value fomratter defined for the whole chart is overloaded for the specific layer value.
   */
  tooltipValueFormatter?: TooltipValueFormatter;
  /**
   * Specify if the layer should be animated or not, defaults to `true`.
   * If animation is active, then the default recharts animation will be used.
   */
  isAnimationActive?: boolean;
  /**
   * The order in which the layer will be rendered on the chart
   */
  order?: number;
}

export type DataItem = Record<string, string | number | null | undefined>;

type AdditionalLegendItemType = {
  /**
   * Label text to display for legend item.
   */
  label: string;
  /**
   * Optional svg styles for the colored rectangel
   */
  svgStyles?: React.SVGAttributes<SVGRectElement>
};

export type CustomLegendProps = LegendProps & {
  additionalLegendItems?: Array<AdditionalLegendItemType>;
};

export interface ComposedChartProps {
  /**
   * Unique ID of a chart, used when generating layer gradients
   */
  chartId: string;
  /**
   * Array of data objects
   */
  data?: Array<DataItem>;
  /**
   * Optional string ID for the chart layer
   */
  stackId?: string;
  /**
   * Optional string specification of the x variable
   */
  xKey?: string;
  /**
   * Optional unit text of the charted values
   */
  unit?: string;
  /**
   * Optional array of chart layers for stacking
   */
  layers?: Array<Omit<ChartLayerSettings, "color">>;
  /**
   * Optional object to customize the right y-axis
   */
  yAxisRightProps?: YAxisProps;
  /**
   * Optional object to customize the left y-axis
   */
  yAxisLeftProps?: YAxisProps;
  /**
   * Optional object to customize the x-axis
   */
  xAxisProps?: XAxisProps;
  /**
   * TMPfix for recharts not showing numeric barcharts correctly
   * Number o % that will be added to the left and right to add some padding on numeric x axis (when dataMin, dataMax is used)
   */
  numericAxisPadding?: number;
  /**
   * Optional numerical formatter for axis values
   */
  axisValueFormatter?: NumberFormatter;
  /**
   * Optional numerical formatter for tooltip
   */
  tooltipValueFormatter?: TooltipValueFormatter;
  /**
   * Optional tooltip title value formatter
   */
  tooltipTitleFormatter?: TooltipTitleFormatter;
  /**
   * Show/hide Total value in tooltip
   */
  showTotalInTooltip?: boolean;
  /**
   * Optional boolean specifying legend visibility
   */
  showLegend?: boolean;
  /**
   * Optional settings for ReferenceArea
   */
  referenceAreaProps?: Array<CustomReferenceAreaProps>;
  /**
   * Enables and sets x-axis position for the Live indicator reference line
   */
  liveIndicatorXValue?: string | number;
  /**
   * Optional date format string for labels
   */
  dateFormat?: string | undefined;
  /**
   * Language used for number formating, defaults to user's browser language
   */
  locale?: string;
  /**
   * Secondary chart legend config
   */
  additionalLegendItems?: Array<AdditionalLegendItemType>;
  /**
   * Custom chart margin. Every of the defined value rewrites chart default margin value.
   */
  margin?: { top?: number; right?: number; bottom?: number; left?: number };
  /**
   * If any two categorical charts(LineChart, AreaChart, BarChart, ComposedChart) have the same syncId, these two charts can sync the position tooltip, and the startIndex, endIndex of Brush.
   */
  syncId?: string;
  /**
   * Optional on tooltip active callback
   */
  onTooltipActive?: (active: boolean) => void;
}

export type LineBarProps = {
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
  opacity?: number;
};
