/**
 * Inspired by:
 * https://github.com/jaq316/leaflet-header/blob/master/index.js
 * https://github.com/PaulLeCam/react-leaflet/blob/master/src/TileLayer.js
 */

import {
  LayerProps,
  createTileLayerComponent,
  updateGridLayer,
  withPane,
} from "@react-leaflet/core";
import {
  Coords,
  DoneCallback,
  TileLayer as LeafletTileLayer,
  TileLayerOptions,
} from "leaflet";

export interface TileLayerProps extends TileLayerOptions, LayerProps {
  url: string;
  headersGetter: () => Record<string, any>;
}

// NOTE: abort functionality is not yet enabled - add later if needed
function fetchImage(
  url: string,
  callback: (blob: Blob) => void,
  headersGetter: () => Record<string, any>
  // abort: any
) {
  // const controller = new AbortController();
  // const signal = controller.signal;

  // if (abort) {
  //   abort.subscribe(() => {
  //     controller.abort();
  //   });
  // }

  fetch(url, {
    method: "GET",
    headers: headersGetter(),
    // signal,
  })
    .then((response) => response.blob())
    .then((blob) => callback(blob))
    .catch((error: any) => {
      console.error("Error:", error);
    });
}

const TileLayerWithHeaders = LeafletTileLayer.extend({
  initialize: function (
    url: string,
    options: TileLayerOptions,
    headersGetter: () => Record<string, any>,
    abort: any
  ) {
    // @ts-ignore NOTE: complains about TileLayer not having the intialize method
    LeafletTileLayer.prototype.initialize.call(this, url, options);
    this.headersGetter = headersGetter;
    // this.abort = abort;
  },
  createTile(coords: Coords, done: DoneCallback) {
    const url = this.getTileUrl(coords);
    const img = document.createElement("img");

    img.setAttribute("role", "presentation");

    fetchImage(
      url,
      (resp) => {
        const reader = new FileReader();

        reader.onload = () => {
          img.src = reader.result as string;
        };

        reader.readAsDataURL(resp);

        done(undefined, img);
      },
      this.headersGetter
      // this.abort
    );
    return img;
  },
});

const TileLayer = createTileLayerComponent<LeafletTileLayer, TileLayerProps>(
  function createTileLayer({ url, headersGetter, ...options }, context) {
    return {
      instance: new TileLayerWithHeaders(
        // @ts-ignore the default inferred type doesn't seem to know about args
        url,
        withPane(options, context),
        headersGetter
      ),
      context,
    };
  },
  updateGridLayer
);

export default TileLayer;
