import React, {
  useEffect,
  createContext,
  useCallback,
  useContext,
  Component,
  useState,
  forwardRef,
  useRef,
} from "react";
import reactUseWebSocket, { Options, ReadyState } from "react-use-websocket";
import sortBy from "lodash-es/sortBy";

export interface WebSocketWrapperProps {
  onClose?: Options["onClose"];
  onError?: Options["onError"];
  onOpen?: Options["onOpen"];
  shouldReconnect?: Options["shouldReconnect"];
  url: string;
}

enum ConnectionStaus {
  Connecting = "connecting",
  Open = "open",
  Closing = "closing",
  Closed = "closed",
  Uninstantiated = "uninstantiated",
}

type WebSocketHookProps<M> = Pick<
  ReturnType<typeof reactUseWebSocket>,
  "sendJsonMessage"
> & {
  connectionStatus: ConnectionStaus;
  lastJsonMessage: M;
  open: () => void;
  close: () => void;
  error: Event | null;
};

export interface NotificationsProviderRefElement extends JSX.Element {
  /**
   * Before we open the socket we have to authenticate with the access token
   */
  authenticate: NotificationsProviderCore["authenticate"];
  /**
   * Closes the socket
   */
  closeConnection: NotificationsProviderCore["closeConnection"];
  /**
   * Opens the socket
   */
  openConnection: NotificationsProviderCore["openConnection"];
  /**
   * Marks a single notification as read/unread
   */
  setNotificationReadState: NotificationsProviderCore["setNotificationReadState"];
  /**
   * Marks all notifications as read/unread
   */
  setAllNotificationsReadState: NotificationsProviderCore["setAllNotificationsReadState"];
}

interface NotificationsContextValue {
  notifications: Notification[];
  unreadNotificationsCount: number | null;
  setNotificationReadState: (notificationId: string, read: boolean) => void;
  setAllNotificationsReadState: (read: boolean) => void;
  error: Event | null;
}

interface NotificationMessage {
  /**
   * A unique ID
   */
  id: string;
  /**
   * Link that should be opened when the notification item is clicked (opens in a new tab by default)
   */
  link: string;
  /**
   * The actual text that will be shown in the notification item
   */
  message: string;
  /**
   * If the notification was already marked as read by the user
   */
  read: boolean;
  /**
   * Timestamp in a string format, will be later formatted as Date
   */
  timestamp: string;
  /**
   * Title of the notification item
   */
  title: string;
}

interface Notification extends Omit<NotificationMessage, "link" | "timestamp"> {
  /**
   * Link config (from the original link string)
   */
  link: {
    href: NotificationMessage["link"];
    target: "_blank";
  };
  /**
   * Timestamp as a Date object
   */
  timestamp: Date;
  /**
   * Click handler that is used for marking/unmarking the notification as read
   */
  onClick: (notificationId: string, read: boolean) => void;
}

export type NotificationsProviderProps =
  React.PropsWithChildren<WebSocketWrapperProps>;
type NotificationsProviderCoreProps<M> = React.PropsWithChildren<
  WebSocketHookProps<M>
>;

interface NotificationProviderCoreState {
  /**
   * When was the last time we got a notification form the backend
   */
  lastUpdated: ReturnType<typeof Date.now> | null;
  /**
   * List of the processed notifications
   */
  notifications: Notification[];
  /**
   * Number of unread notifications that we show in the app
   */
  unreadNotificationsCount: number | null;
}

type NotificationUpdateStatus = "new" | "readUpdated" | "skip";

const notificationsContext = createContext({} as NotificationsContextValue);

const { Provider } = notificationsContext;

const useNotifications = () => useContext(notificationsContext);

const connectionStatus = {
  [ReadyState.CONNECTING]: ConnectionStaus.Connecting,
  [ReadyState.OPEN]: ConnectionStaus.Open,
  [ReadyState.CLOSING]: ConnectionStaus.Closing,
  [ReadyState.CLOSED]: ConnectionStaus.Closed,
  [ReadyState.UNINSTANTIATED]: ConnectionStaus.Uninstantiated,
};

/**
 * We use a custom HOC to wrap the notifications provider as that's a class component
 * and we want to use the library for websockets that only provides a hook
 *
 * @param WrappedComponent A class component that gets the props from this wrapper
 * @returns WrappedComponent
 */
function withWebSocketHook<P extends WebSocketWrapperProps, M>(
  WrappedComponent: React.ComponentType<
    Omit<
      React.PropsWithChildren<P>,
      "onClose" | "onError" | "onOpen" | "shouldReconnect" | "url"
    > &
      WebSocketHookProps<M>
  >
) {
  return forwardRef<JSX.Element, P>((props, ref) => {
    const { url, shouldReconnect, onError, onClose, onOpen, ...rest } = props;
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState<Event | null>(null);
    const reconnect = useRef(true);

    const defaultShouldReconnect = useCallback(() => {
      return reconnect.current;
    }, []);

    useEffect(() => {
      return () => {
        // Don't reconnect on unmount
        reconnect.current = false;
      };
    }, []);

    const handleError = useCallback(
      (event: Event) => {
        if (typeof onError === "function") {
          onError(event);
        }

        setError(event);
      },
      [onError]
    );

    const {
      sendJsonMessage,
      lastJsonMessage,
      readyState,
    }: Omit<ReturnType<typeof reactUseWebSocket>, "lastJsonMessage"> & {
      lastJsonMessage: M;
    } = reactUseWebSocket(
      url,
      {
        onOpen,
        shouldReconnect: shouldReconnect ?? defaultShouldReconnect,
        onClose,
        onError: handleError,
      },
      isConnected
    );

    useEffect(() => {
      if (error !== null && readyState === 1) {
        setError(null);
      }
    }, [error, readyState]);

    const close = useCallback(() => {
      // Skip reconnect on close from client
      reconnect.current = false;
      setIsConnected(false);
    }, []);

    const open = useCallback(() => {
      setIsConnected(true);
    }, []);

    return (
      <WrappedComponent
        {...rest}
        sendJsonMessage={sendJsonMessage}
        lastJsonMessage={lastJsonMessage}
        connectionStatus={connectionStatus[readyState]}
        close={close}
        open={open}
        error={error}
        ref={ref}
      />
    );
  });
}

class NotificationsProviderCore extends Component<
  NotificationsProviderCoreProps<NotificationMessage | NotificationMessage[]>,
  NotificationProviderCoreState
> {
  state: NotificationProviderCoreState = {
    lastUpdated: null,
    notifications: [],
    unreadNotificationsCount: null,
  };

  componentDidUpdate(
    _prevProps: NotificationsProviderCoreProps<NotificationMessage>,
    prevState: NotificationProviderCoreState
  ) {
    if (prevState.lastUpdated !== this.state.lastUpdated) {
      // NOTE: A perf. optimization so it won't start processing data after the state changes (only if props change)
      // We could have done it with deep compare but this is more efficient.
      return;
    }

    // If there is a new message, process it
    if (this.props.lastJsonMessage) {
      // Backend can send either a single notification or and array of notifications
      if (Array.isArray(this.props.lastJsonMessage)) {
        this.bulkProcessNotifications();
      } else {
        this.processSingleNotification();
      }
    }
  }

  /**
   * In case the message is only a single notification object
   */
  processSingleNotification() {
    const notificationUpdateStatus = this.getNotificationUpdateStatus(
      this.props.lastJsonMessage as NotificationMessage
    );

    if (notificationUpdateStatus !== "skip") {
      this.setState((prevState) => {
        const updatedNotifications = this.updateNotifications(
          this.props.lastJsonMessage as NotificationMessage,
          prevState.notifications,
          notificationUpdateStatus
        );

        return {
          ...prevState,
          lastUpdated: Date.now(),
          notifications: updatedNotifications,
          unreadNotificationsCount: updatedNotifications.filter(
            (notification) => !notification.read
          ).length,
        };
      });
    }
  }

  /**
   * In case the message has multiple notifications in it as an array
   */
  bulkProcessNotifications() {
    const notifications = (
      this.props.lastJsonMessage as NotificationMessage[]
    ).reduce((acc, curr) => {
      const notificationUpdateStatus = this.getNotificationUpdateStatus(curr);

      if (notificationUpdateStatus !== "skip") {
        const updatedNotifications = this.updateNotifications(
          curr,
          acc ?? this.state.notifications,
          notificationUpdateStatus
        );

        return updatedNotifications;
      }

      return acc;
    }, null as Notification[] | null);

    if (notifications !== null) {
      this.setState((prevState) => ({
        ...prevState,
        lastUpdated: Date.now(),
        notifications,
        unreadNotificationsCount: notifications.filter(
          (notification) => !notification.read
        ).length,
      }));
    }
  }

  /**
   * A method that tells us what to do with the notification (if it's a new one
   * or if the read status of an existing one was just updated)
   *
   * @param notification
   * @returns Notification update status
   */
  getNotificationUpdateStatus(
    notification: NotificationMessage
  ): NotificationUpdateStatus {
    const { notifications } = this.state;

    if (notifications.length === 0) return "new";

    const existingNotification = notifications.find(
      (notification_) => notification_.id === notification.id
    );

    if (!existingNotification) return "new";

    if (existingNotification.read !== notification.read) return "readUpdated";

    // Returning "skip" means that the notification won't be processed
    return "skip";
  }

  /**
   * This method updates the locally stored notifications (eihter a new message
   * arrived or and existing notification's read state was updated)
   *
   * @param lastJsonMessage Current message from the BE
   * @param notifications Locally stored notifications
   * @param updateStatus Update status for the notification
   * @returns List of updated notifications
   */
  updateNotifications(
    lastJsonMessage: NotificationMessage,
    notifications: Notification[],
    updateStatus: NotificationUpdateStatus
  ) {
    const { id, title, message, link, timestamp, read } = lastJsonMessage;

    if (updateStatus === "new") {
      return sortBy(
        [
          {
            id,
            title,
            message,
            link: {
              href: link,
              target: "_blank",
            },
            timestamp: new Date(timestamp),
            read,
            onClick: () => this.setNotificationReadState(id, true),
          } as Notification,
          ...notifications,
        ],
        "timestamp"
      ).reverse();
    } else if (updateStatus === "readUpdated") {
      return notifications.map((notification) => {
        return notification.id === id
          ? {
              ...notification,
              read,
            }
          : notification;
      });
    } else {
      return notifications;
    }
  }

  /**
   * The first message has to be always an "auth message" containing the token
   *
   * @param token Access token from KC
   */
  authenticate = (token: string) => {
    if (token) {
      this.props.sendJsonMessage({ token });
    }
  };

  /**
   * Closes the socket
   */
  closeConnection = () => {
    this.props.close();
  };

  /**
   * Opens the socket
   */
  openConnection = () => {
    this.props.open();
  };

  setNotificationReadState = (notificationId: string, read: boolean) => {
    if (notificationId && typeof read === "boolean") {
      this.props.sendJsonMessage({ id: notificationId, read });
    }
  };

  setAllNotificationsReadState = (read: boolean) => {
    if (typeof read === "boolean") {
      this.props.sendJsonMessage(
        this.state.notifications.map((notification) => ({
          id: notification.id,
          read,
        }))
      );
    }
  };

  render() {
    const { children, error } = this.props;

    const { notifications, unreadNotificationsCount } = this.state;

    return (
      <Provider
        value={{
          notifications,
          unreadNotificationsCount,
          setNotificationReadState: this.setNotificationReadState,
          setAllNotificationsReadState: this.setAllNotificationsReadState,
          error,
        }}
      >
        {children}
      </Provider>
    );
  }
}

const NotificationsProvider = withWebSocketHook<
  NotificationsProviderProps,
  NotificationMessage | NotificationMessage[]
>(NotificationsProviderCore);

export { NotificationsProvider, useNotifications };
