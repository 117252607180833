// Defined based on IDs provided from Matomo custom dimensiosn settings
export enum MatomoVisitCustomDimension {
  userType = 1, // Based on the matomo profile settings
}

// Defined based on IDs provided from Matomo custom dimensiosn settings
export enum MatomoActionCustomDimension {
  exportDataParameters = 2,
  siteName = 3,
  buildingName = 4,
  liveMarketProgramEvent = 5,
  dateRangeDuration = 6,
  dateRangeInterval = 7,
}

/** This enum is used as the value for the userType custom dimension */
export enum MatomoUserType {
  internalUser = "Internal user",
  externalUser = "External user",
}

export enum MatomoContentName {
  chartWidget = "Chart Widget",
}

export enum MatomoContentInteractionName {
  ChangeMeasurementType = "Change measurement type",
  changeSelectedMarket = "Change selected market",
  activateTooltip = "Activate tooltip",
}

export enum PageName {
  yourData = "YourData",
  markets = "Markets",
  overview = "Overview",
  portfolio = "Portfolio",
  portfolioSite = "PortfolioSite",
  portfolioBuilding = "PortfolioBuilding",
  submetering = "Submetering",
}

export const matomoDownloadIntervals = {
  "5min": "5 minutes",
  "15min": "15 minutes",
  "1hour": "1 hour",
  "1day": "1 day",
  "1week": "1 week",
  "1month": "1 month",
  "1year": "1 year",
} as const;

export type MatomoDurationType =
  | "less than hour"
  | "not yet announced"
  | `${number} seconds`
  | `${number} hours`
  | `${number} days`
  | `${number} weeks`
  | `${number} months`
  | `${number} years`;
