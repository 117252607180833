import React, { useLayoutEffect } from "react";
import {
  electricBaselineLoadId,
  electricTargetLoadId,
} from "src/lib/constants";
import { setAttributes } from "src/lib/utils";

const arrowAspectRatio = 55 / 105; // NOTE: arrow pattern width / height

import { CustomEventWindowProps } from "./CustomEventWindow";

type CustomErcotEventAnimatedWindowProps = Omit<
  CustomEventWindowProps,
  "showLeftBorder" | "showRightBorder"
> & {
  showBaselineGhostLine?: boolean;
  showTargetGhostLine?: boolean;
};

const CustomErcotEventAnimatedWindow = (props: any) => {
  const {
    width,
    x,
    y,
    height,
    stroke,
    strokeWidth,
    strokeDasharray,
    fill,
    showBaselineGhostLine,
    showTargetGhostLine,
  } = props as CustomErcotEventAnimatedWindowProps;

  const commonStrokeProps = {
    stroke,
    strokeWidth,
    strokeDasharray,
  };

  const arrowYOffset = height * 0.1;
  const arrowsY = arrowYOffset / 2;
  const arrowsHeight = height - arrowYOffset;
  const arrowWidth = arrowsHeight * arrowAspectRatio;

  useLayoutEffect(() => {
    const baselineObserver =
      showBaselineGhostLine && observeLine(electricBaselineLoadId);
    const targetlineObserver =
      showTargetGhostLine && observeLine(electricTargetLoadId);

    return () => {
      if (baselineObserver) {
        baselineObserver.disconnect();
      }
      if (targetlineObserver) {
        targetlineObserver.disconnect();
      }
      showBaselineGhostLine && removeGhostLine(electricBaselineLoadId);
      showTargetGhostLine && removeGhostLine(electricTargetLoadId);
    };
  }, [x, y, width, height, showBaselineGhostLine, showTargetGhostLine]);

  return (
    <svg x={x} y={y} width={width} height={height}>
      <style>
        {`
          .animated-event-arrow {
            transform: translateX(${arrowWidth}px);
            animation: arrows 3s linear infinite;
          }
          @keyframes arrows {
            from {
              transform: translateX(0px);
            }
            to {
              transform: translateX(${arrowWidth}px);
            }
          }
        `}
      </style>
      <defs>
        <pattern
          id="pattern-arrows"
          x={0}
          y={arrowsY}
          width={height > 350 ? arrowWidth / 2 : arrowWidth}
          height={height > 350 ? arrowsHeight / 2 : arrowsHeight}
          viewBox="0 0 55 105"
          patternUnits="userSpaceOnUse"
        >
          <path
            d="M3 93.75L8.81395 100L53 52.5L8.81395 5L3 11.25L41.3721 52.5L3 93.75Z"
            fill={fill}
          />
        </pattern>

        <linearGradient id="windowGradient">
          <stop stopColor={fill} offset="0%" stopOpacity={1} />
          <stop stopColor={fill} offset="100%" stopOpacity={0.1} />
        </linearGradient>
      </defs>

      {/* Animated Arrows */}
      <rect
        x={arrowWidth * -1}
        width={width + arrowWidth}
        y={arrowsY}
        height={arrowsHeight}
        fill="url(#pattern-arrows)"
        className="animated-event-arrow"
      />

      {/* Fill */}
      <rect
        x={0}
        width={width}
        y={0}
        height={height}
        fill={"url(#windowGradient)"}
      />

      {/* NOTE: By design both top border and bottom border should have defined transition */}
      {/* Top border */}
      <line x1={0} x2={width} y1={0} y2={0} {...commonStrokeProps} />

      {/* Bottom border */}
      <line x1={0} x2={width} y1={height} y2={height} {...commonStrokeProps} />
    </svg>
  );
};

const getGhostLineId = (lineId: string) => `ghost-${lineId}`;
const getGradientId = (lineId: string) => `gradient-${lineId}`;

const drawGhostLine = (
  linePathEl: HTMLElement,
  lineId: string,
  lineLength = 62
) => {
  const ghostLineId = getGhostLineId(lineId);
  const gradientId = getGradientId(lineId);
  const d = linePathEl.getAttribute("d");
  const stroke = linePathEl.getAttribute("stroke");
  const opacity = linePathEl.getAttribute("opacity");

  if (!d) return;

  const pathPoints = d.split("L");
  const lastPoint = pathPoints[pathPoints.length - 1];
  const [lastPointX, lastPointY] = lastPoint
    .split(",")
    .map((value) => Number(value));
  const x1 = lastPointX + 3;
  const x2 = x1 + lineLength;
  const newD = `M${x1}, ${lastPointY}L${x2}, ${lastPointY}`;

  const lineargradient = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "linearGradient"
  );
  setAttributes(lineargradient, {
    id: gradientId,
    x1: `${x1}`,
    x2: `${x2}`,
    y1: "0%",
    y2: "0%",
    gradientUnits: "userSpaceOnUse",
  });
  lineargradient.innerHTML = `
    <stop offset="0%" style="stop-color: ${stroke}"></stop>
    <stop offset="100%" style="stop-color: ${stroke}" stop-opacity="0"></stop>
  `;

  const ghostPathEl = linePathEl.cloneNode(true) as Element;
  setAttributes(ghostPathEl, {
    d: newD,
    "stroke-dasharray": "6 3",
    stroke: `url(#${gradientId})`,
    id: ghostLineId,
    class: opacity !== "0" ? "animate-pulse" : "",
  });

  if (linePathEl.parentNode) {
    document.getElementById(ghostLineId)?.remove();
    document.getElementById(gradientId)?.remove();
    linePathEl.parentNode.append(ghostPathEl);
    linePathEl.parentNode.append(lineargradient);
  }
};

const removeGhostLine = (lineId: string) => {
  const ghostLineId = getGhostLineId(lineId);
  const gradientId = getGradientId(lineId);

  document.getElementById(ghostLineId)?.remove();
  document.getElementById(gradientId)?.remove();
};

/**
 * Function creates initial ghostLine and observes for change on the line with specified lineId.
 * Used for updating the ghostLine elemnt when Recharts animations run after the initial element render.
 * This function should be called only once inside of the useEffect react hooks.
 * Don't forget to dicsonnect observer at the end of an lifecycle.
 * @param lineId
 * @returns observer instance for the specified lineId
 */
const observeLine = (lineId: string) => {
  const targetNode = document.getElementById(lineId);
  if (!targetNode) return;

  drawGhostLine(targetNode, lineId);

  const config = { attributes: true, childList: false, subtree: false };

  // MutationObserver handles changes after the animations render
  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "opacity"
      ) {
        drawGhostLine(targetNode, lineId);
      }
    }
  });
  observer.observe(targetNode, config);

  return observer;
};

export default CustomErcotEventAnimatedWindow;
