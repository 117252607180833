import { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Loader } from "ui-core";
import { useKeycloak } from "src/lib/KeycloakProvider";
import { EventBuilder } from "src/lib/matomo/appMatomo";
import { trackEvent } from "src/lib/matomo/matomo";

/**
 * This is a dummy page that handles the auth status (KC auth will redirect here)
 */
function SilentLogin() {
  const { login, authenticated, authenticating } = useKeycloak();
  const location = useLocation<{
    from?: ReturnType<typeof useLocation>;
  }>();

  useEffect(() => {
    if (!authenticated) {
      trackEvent(
        EventBuilder.withCategory("User profile")
          .withAction("Redirect to log-in page")
          .create()
      );
    }
  }, [authenticated]);

  useEffect(() => {
    if (!(authenticated || authenticating)) {
      const redirectUri = location?.state?.from?.pathname
        ? `${window.location.origin}${location.state.from.pathname}${
            location.state.from.search ?? ""
          }`
        : undefined;

      login(redirectUri);
    }
  }, [authenticated, authenticating, location, login]);

  if (!authenticated) {
    return <Loader text="Redirecting to login..." overlay />;
  }

  if (authenticating) {
    return <Loader text="Validating sign-in..." overlay />;
  }

  if (authenticated) {
    return <Redirect to="/overview" />;
  }

  return null;
}

export default SilentLogin;
