/**
 * Constants and configuration related to how data from v2 endpoints are handled
 */

import { isArray, isEmpty } from "lodash-es";
import {
  CustomizedMultilineAxisTick,
  xAxisPadding,
} from "src/components/widgets/CustomChartComponents";
import { ChartGroup } from "src/components/Portfolio/PortfolioBuilding";
import { AssetV1Fragment, AssetV2Fragment, Maybe } from "src/graphql/code";
import { absoluteValueFormatter, inverseValueFormatter } from "./formatters";

const conversionConstantText =
  "This value was converted from volume to energy using the conversion constant ";

// list of asset param types that indicate that asset has some v1 readings
export const v1paramTypes = new Map([
  ["energy_v1", "Energy"],
  ["power_v1", "Power"],
]);

// code names of the param types that have cumulative measurements
export const v2CumulativeParamTypes = ["gas", "steam", "energy_v2"];

// code names used in v2 readings API
export const measurementParams = {
  gasConsumption: "Gas consumption",
};

// in case some chart doesn't have multiple datasets - 'defaultDatasetKey' is used as a key
export const defaultKey = "__default__";

export const electricBaselineLayer = {
  sourceType: "asset",
  description: "",
  dot: false,
  lineType: "stepAfter",
  type: "line",
  unit: "kW",
  yAxisId: null,
  yKey: "baseline",
  yLabel: "Baseline Load",
  isAnimationActive: false, //If enalbed animations are causing problem when rendering ghostLine - drawGhostLine functio. This is related to ERCOT events.
  dashedLine: true,
};

export const electricTargetlineLayer = {
  sourceType: "asset",
  description: "",
  dot: false,
  lineType: "stepAfter",
  type: "line",
  unit: "kW",
  yAxisId: null,
  yKey: "targetline",
  yLabel: "Target Load",
  isAnimationActive: false, //If enalbed animations are causing problem when rendering ghostLine - drawGhostLine functio. This is related to ERCOT events.
};

export const gasBaselineLayer = {
  description: "",
  dot: false,
  lineType: "stepAfter",
  sourceType: "asset",
  type: "line",
  unit: "therms",
  yAxisId: null,
  yKey: "baseline",
  yLabel: "Baseline Load",
  isAnimationActive: false, //If enalbed animations are causing problem when rendering ghostLine - drawGhostLine functio. This is related to ERCOT events.
  dashedLine: true,
};

// used in chart config: to identify which layer shows values on the right chart axis
const chartRightAxisId = "right";

/* Carbon emissions common settings */
const carbonEmissionsSettigs = {
  barLayerProps: {
    type: "bar",
  },
  lineLayerProps: {
    type: "line",
    strokeWidth: 3,
    lineType: "step",
    dot: false,
  },
};

enum LabelType {
  masterMeter = "MASTER_METER",
}

interface Label {
  showLabels: boolean;
  source: string; // asset, derived, custom
  allowedAssetTypes?: Array<string>; // works only if source 'asset' is used, e.g pv, ess, chp, dgbox, stload
  type?: LabelType;
  property?: string;
  titleProperty?: string;
  variant?: string;
  unit?: string;
  prefix?: string;
  customLabels?: Array<{
    title: string;
    variant?: string;
    prefix?: string;
    postfix?: string;
  }>;
}

interface PowerDataset {
  key: string;
  title: string;
  enabled: boolean;
  chartLayers: {
    key: string;
    showStatistics: boolean;
    data: {
      parameterTypeCodeName: string;
      measurementParameterCodeName: string;
      titleProperty: string;
    };
    chartProps: {
      type: string;
    };
  }[];
  labels: Array<Label>;
}

const masterMeterLabel: Label = {
  showLabels: true,
  source: "custom",
  type: LabelType.masterMeter,
  customLabels: [
    {
      title: "Master meter",
      variant: "dark",
    },
  ],
};

const marketAssetLabel: Label = {
  showLabels: true,
  source: "asset",
  property: "participatesInMarkets",
  titleProperty: "name",
  variant: "info-subtle",
  prefix: "Market",
};

/* Load power/energy common settings */
const loadPowerDataset: PowerDataset = {
  key: "power",
  title: "Power",
  enabled: true,
  chartLayers: [
    {
      key: "power",
      showStatistics: true,
      data: {
        parameterTypeCodeName: "power_v1",
        measurementParameterCodeName: "power_v1",
        titleProperty: "measurementTitle",
      },
      chartProps: {
        type: "bar",
      },
    },
  ],
  labels: [
    marketAssetLabel,
    {
      showLabels: true,
      source: "asset",
      allowedAssetTypes: ["pv", "ess"],
      property: "installedPowerCapacity",
      variant: "light",
      unit: "kW",
    },
  ],
};

interface EnergyDataset {
  key: string;
  title: string;
  enabled: boolean;
  chartLayers: {
    key: string;
    showStatistics: boolean;
    isCumulative: boolean;
    data: {
      parameterTypeCodeName: string;
      measurementParameterCodeName: string;
      titleProperty: string;
    };
    chartProps: {
      type: string;
    };
  }[];
  labels: Array<Label>;
}

const loadEnergyDataset: EnergyDataset = {
  key: "energy",
  title: "Energy",
  enabled: true,
  chartLayers: [
    {
      key: "energy",
      showStatistics: true,
      isCumulative: true,
      data: {
        parameterTypeCodeName: "energy_v1",
        measurementParameterCodeName: "energy_v1",
        titleProperty: "measurementTitle",
      },
      chartProps: {
        type: "bar",
      },
    },
  ],
  labels: [
    marketAssetLabel,
    {
      showLabels: true,
      source: "asset",
      allowedAssetTypes: ["pv", "ess"],
      property: "installedEnergyCapacity",
      variant: "light",
      unit: "kWh",
    },
  ],
};

/* Load gas common settings */
const loadGasDataset = () => ({
  key: "gas",
  enabled: true,
  title: "Gas",
  chartLayers: [
    {
      key: "gas_consumption",
      showStatistics: true,
      isCumulative: true,
      data: {
        parameterTypeCodeName: "gas",
        measurementParameterCodeName: "gas_consumption",
        titleProperty: "measurementTitle",
      },
      description: {
        text: conversionConstantText,
        valueProperty: "conversionConstant",
      },
      chartProps: {
        type: "bar",
      },
    },
    // TODO baseline is pushed by force in the portfolio building component, this should not be here anymore but still is - for backwards compatibility. Refactor needed.
    {
      key: "baseline",
      condition: "gasEventsExist",
      data: {},
    },
  ],
});

/** Configuration for v2 charts: definition of what measurements, layers, labels etc will
 *  the chart consist of and where to find them. Fields marked * are required.
 *  Indexed by the codeName of building calculation or asset type or any ID.
 *  Result is a function that will return chart config (based on asset type) - usually it is just
 *    a constant, but in some cases asset type influences some props, e.g. chart color
 *
 *  **Possible fields and values:**
 *  title: chart title; used in derived charts where title can't be taken from asset's name
 *  iconName: chart iconName: used in derived/calculation charts where iconName can't be taken from asset's type
 *  showChartAssetIcon: whether to show asset icon before the asset's chart title, have to be `true` to show icon.
 *  labels: List of labels. Config for how to get the labels displayed next to chart's title.
 *   - showLabels: true/false: toggle displaying of labels. 'false' has the same effect as
 *                 not providing the 'labels' field at all
 *   - source: "asset" or "derived". if it is "asset", label's value will be taken from property.
 *                                   if "derived", it will be any other value, see
 *                                   'property' below
 *   - property: name of the property (of the asset or derivedChartLabels)
 *               which contains the list of labels (e.g. 'assets') or one label/value
 *   - titleProperty: if property is an object - name of the property which contains the text
 *                    displayed on a label (e.g. 'assetName')
 *   - variant: style of the label; default value: 'light'
 *   - unit: optional suffix for the labels
 *
 *  datasets: list of datasets displayed in the chart
 *   - key*: unique key
 *   - enabled: true/false in case we don't want to show the dataset right now
 *   - title*: text displayed on the toggle button
 *   - additionalChartProps: extra props to add to the chart (stored in additionalChartProps)
 *                             (e.g. to display market event windows = ReferenceArea props)
 *   - chartLayers: list of layers (bars, lines, etc)
 *     - key*: unique key
 *     - showStatistics: true if aggregate values from this layer are used for displaying
 *                       statistics; only one layer in dataset should have this flag=true
 *     - isCumulative: true if layer's data is cumulative in nature (consumption or energy),
 *                     the 'Cumulative' field will then appear in the statistics
 *     - condition: display this layer only if condition is true (prop of layerConditions)
 *     - source: where do the data come from? list of assets will be filtered by this criteria
 *                only one asset should be the result. Not necessary when chart is generated for
 *                particular asset; only for other non-asset data
 *       - type*: "asset" or "derived"
 *       - codeName: for "derived", the object key (in derivedChartData) where we find the values
 *       - includeTypes: for "asset", similar logic to listOfChartGroups
 *       - excludeTypes: for "asset", similar logic to listOfChartGroups
 *       - flags: for "asset", similar logic to listOfChartGroups
 *     - data*:
 *       - parameterTypeCodeName*,
 *       - measurementParameterCodeName*: to specify which set of readings from source asset will be used
 *       - title or titleProperty*: one of them is required. If 'title' is provided, that text will be
 *                                  used as layer title. Otherwise title is taken from titleProperty of
 *                                  of data about measurements - see extractMeasurementTypeInfo
 *     - description: text that will be displayed on the chart tooltip
 *       - text: any string
 *       - valueProperty: where to look for some dynamic value, e.g. conversion constant (works
 *                        similar to data/titleProperty)
 *     - chartProps: any props that ComposedChart's layer accepts
 *     - rightYAxis: true/false if values from this layer should be displayed on the right axis
 *     - labels: config for how to get the labels displayed next to chart's title - only if this
 *               dataset is active - see the definition ^ (same as labels defined on chart
 *               level. if both are defined, chart labels take preference)
 *  chartProps: any props that ComposedChart accepts
 *  showRightAxis: true/false - will display right axis from values of the layer that has rightYAxis=true
 */

// TODO: Replace any with something specific, unified
type DataSourceFormatter = (assetType?: string) => Record<string, any>;

export const chartDatasourceConfig = new Map<string, DataSourceFormatter>([
  [
    "main_load_asset",
    (assetType) => {
      return {
        datasets: [
          {
            ...loadPowerDataset,
            chartLayers: [
              ...loadPowerDataset.chartLayers,
              // TODO baseline and targetline is pushed by force in the portfolio building component, this should not be here anymore but still is - for backwards compatibility. Refactor needed.
              {
                key: "baseline",
                data: {},
                condition: "electricEventsExist",
              },
              {
                key: "targetline",
                data: {},
                condition: "electricEventsExist",
              },
            ],
            additionalChartProps: ["marketEventsElectric"],
            labels: [masterMeterLabel, ...loadPowerDataset.labels],
          },
          {
            ...loadEnergyDataset,
            labels: [masterMeterLabel, ...loadEnergyDataset.labels],
          },
        ],
        chartProps: {
          showLegend: true,
        },
      };
    },
  ],
  [
    "load",
    () => ({
      datasets: [loadPowerDataset, loadEnergyDataset],
      chartProps: {
        showLegend: true,
      },
    }),
  ],
  [
    "all_assets_v1",
    () => ({
      datasets: [loadPowerDataset, loadEnergyDataset],
      chartProps: {
        showLegend: true,
      },
      showChartAssetIcon: true,
    }),
  ],
  [
    "schneider_assets",
    () => ({
      labels: [marketAssetLabel],
      datasets: [
        {
          key: "power",
          enabled: true,
          title: "Power",
          chartLayers: [
            {
              key: "power_v2",
              showStatistics: true,
              data: {
                parameterTypeCodeName: "power_v2",
                measurementParameterCodeName: "power_v2",
                titleProperty: "measurementTitle",
              },
              chartProps: {
                type: "bar",
              },
            },
          ],
        },
        {
          key: "energy",
          enabled: true,
          title: "Energy",
          chartLayers: [
            {
              key: "energy_v2",
              showStatistics: true,
              isCumulative: true,
              data: {
                parameterTypeCodeName: "energy_v2",
                measurementParameterCodeName: "energy_v2",
                titleProperty: "measurementTitle",
              },
              chartProps: {
                type: "bar",
              },
            },
          ],
        },
      ],
      chartProps: {
        showLegend: true,
      },
      showChartAssetIcon: true,
    }),
  ],
  [
    "carbon_emissions",
    () => ({
      labels: [
        {
          showLabels: true,
          source: "asset",
          property: "assetName",
          variant: "dark",
        },
        {
          showLabels: true,
          source: "custom",
          customLabels: [
            {
              title: "Electricity",
              variant: "info-subtle",
            },
          ],
        },
      ],
      disableMasterMeterLabel: true,
      datasets: [
        {
          key: "carbon_emissions_v2",
          enabled: true,
          title: "Carbon Emissions",
          chartLayers: [
            {
              key: "carbon_emissions_v2",
              showStatistics: true,
              isCumulative: true,
              data: {
                parameterTypeCodeName: "carbon_emissions",
                measurementParameterCodeName: "carbon_emissions_v2",
                titleProperty: "paramTitle",
              },
              chartProps: carbonEmissionsSettigs.barLayerProps,
            },
          ],
        },
      ],
      chartProps: {
        title: "Carbon Emissions",
        showLegend: true,
      },
    }),
  ],
  [
    "emission_factors",
    () => ({
      labels: [
        {
          showLabels: true,
          source: "asset",
          property: "assetName",
          variant: "dark",
        },
        {
          showLabels: true,
          source: "custom",
          customLabels: [
            {
              title: "Electricity",
              variant: "info-subtle",
            },
          ],
        },
      ],
      disableMasterMeterLabel: true,
      datasets: [
        {
          key: "emission_factor_v2",
          enabled: true,
          title: "Emission Factors",
          chartLayers: [
            {
              key: "emission_factor_v2",
              showStatistics: true,
              data: {
                parameterTypeCodeName: "emission_factors",
                measurementParameterCodeName: "emission_factor_v2",
                titleProperty: "paramTitle",
              },
              chartProps: carbonEmissionsSettigs.barLayerProps,
            },
          ],
        },
      ],
      chartProps: {
        tooltipValueFormatter: undefined,
        title: "Emission Factors",
        showLegend: true,
      },
    }),
  ],
  [
    "dgbx",
    () => ({
      labels: [marketAssetLabel],
      datasets: [
        {
          key: "inside_temperature",
          enabled: true,
          title: "Temperature", // TODO dynamically from asset's paramType?
          chartLayers: [
            {
              key: "temp1",
              showStatistics: true,
              data: {
                parameterTypeCodeName: "inside_temperature",
                measurementParameterCodeName: "temp1",
                titleProperty: "paramTitle",
              },
              chartProps: {
                type: "bar",
              },
            },
          ],
        },
        {
          key: "inside_humidity",
          enabled: true,
          title: "Humidity",
          chartLayers: [
            {
              key: "humid1",
              showStatistics: true,
              data: {
                parameterTypeCodeName: "inside_humidity",
                measurementParameterCodeName: "humid1",
                titleProperty: "paramTitle",
              },
              chartProps: {
                type: "bar",
              },
            },
          ],
        },
      ],
      chartProps: {
        showLegend: true,
      },
      showChartAssetIcon: true,
    }),
  ],
  [
    "stload",
    () => ({
      labels: [marketAssetLabel],
      datasets: [
        {
          key: "steam",
          enabled: true,
          title: "Steam",
          chartLayers: [
            {
              key: "steam_consumption",
              showStatistics: true,
              isCumulative: true,
              data: {
                parameterTypeCodeName: "steam",
                measurementParameterCodeName: "steam_consumption",
                titleProperty: "measurementTitle",
              },
              description: {
                text: conversionConstantText,
                valueProperty: "conversionConstant",
              },
              chartProps: {
                type: "bar",
              },
            },
          ],
        },
      ],
      chartProps: {
        showLegend: true,
      },
      showChartAssetIcon: true,
    }),
  ],
  [
    "ngloadWithEvents",
    () => {
      const gasDataset = loadGasDataset();

      return {
        labels: [
          marketAssetLabel,
          {
            showLabels: true,
            source: "derived",
            property: "gasChart",
            variant: "light",
          },
        ],
        datasets: [
          {
            ...gasDataset,
            additionalChartProps: ["marketEventsGas"],
          },
        ],
        chartProps: {
          showLegend: true,
        },
      };
    },
  ],
  [
    "ngload",
    () => {
      const gasDataset = loadGasDataset();

      return {
        labels: [
          marketAssetLabel,
          {
            showLabels: true,
            source: "derived",
            property: "gasChart",
            variant: "light",
          },
        ],
        datasets: [gasDataset],
        chartProps: {
          showLegend: true,
        },
      };
    },
  ],
  [
    "gasEventTotalUsage",
    () => ({
      title: "Gas",
      labels: [
        marketAssetLabel,
        {
          showLabels: true,
          source: "derived",
          property: "gasEventSummaryChart",
          variant: "light",
        },
      ],
      iconName: "ngload",
      datasets: [
        {
          key: "totalUsage",
          enabled: true,
          title: "Total Consumption",
          chartLayers: [
            {
              key: "target",
              source: {
                type: "derived",
                codeName: "gasEventTarget",
              },
              chartProps: {
                type: "bar",
                shape: "bar-line",
                xAxisId: "hidden2",
                unit: "therms",
                yLabel: "Target Load",
                order: 2,
              },
            },
            {
              key: "baseline",
              source: {
                type: "derived",
                codeName: "gasEventTotalBaseline",
              },
              chartProps: {
                type: "bar",
                shape: "bar-line",
                xAxisId: "hidden",
                unit: "therms",
                yLabel: "Baseline Load",
                order: 3,
              },
            },
            {
              key: "usage",
              source: {
                type: "derived",
                codeName: "gasEventUsage",
              },
              chartProps: {
                type: "bar",
                unit: "therms",
                yLabel: "Consumption",
                stackId: "gasTotal",
                order: 0,
              },
            },
            {
              key: "actualPerformance",
              source: {
                type: "derived",
                codeName: "gasEventActualPerformance",
              },
              chartProps: {
                type: "bar",
                shape: "hatched-bar",
                unit: "therms",
                yLabel: "Actual Performance",
                stackId: "gasTotal",
                hiddenEmpty: true,
                barLabel: {
                  show: true,
                  type: "withIndicator",
                  additionalText: "therms",
                  inverseLogic: true,
                  valueFormatter: absoluteValueFormatter,
                },
                tooltipValueFormatter: inverseValueFormatter,
                order: 1,
              },
            },
          ],
        },
      ],
      chartProps: {
        showLegend: true,
        syncId: undefined,
        xAxisProps: {
          padding: xAxisPadding,
          tick: <CustomizedMultilineAxisTick />,
          tickMargin: 47,
        },
        yAxisLeftProps: {
          domain: [0, "dataMax"],
          tickFormatter: (value: number) => Math.round(value).toString(),
        },
        showTotalInTooltip: false,
        margin: {
          bottom: 28,
        },
      },
    }),
  ],
]);
/** Loop through list of assets and return those that
 *  - match includeTypes and don't belong to excludeTypes
 *  - have the desired flags + values and don't have exclude tags
 */
export function pickAssetsByFeatures(
  assets: ({
    __typename?: "AssetV2" | undefined;
  } & AssetV2Fragment)[],
  features: ChartGroup
) {
  if (!isEmpty(assets)) {
    const filteredAssets = assets.filter((asset) => {
      // asset type belongs to the includeTypes list, or list is undefined
      const includeTypeMatch =
        !features.includeTypes ||
        !features.includeTypes.length ||
        features.includeTypes.indexOf(asset.assetType) >= 0;

      // asset type doesn't belong to the excludeTypes list, or list is undefined
      const excludeTypeMatch =
        !features.excludeTypes ||
        !features.excludeTypes.length ||
        features.excludeTypes.indexOf(asset.assetType) < 0;

      // asset has all the flags (or attributes) specified with desired value, or list is undefined
      const flagsMatch =
        !features.flags ||
        Object.entries(features.flags).every(([flagName, flagValue]) => {
          if (flagName === undefined) {
            return true;
          }

          if (Array.isArray(flagValue)) {
            return flagValue.some(
              (valueItem: string) => asset[flagName] === valueItem
            );
          } else {
            return asset[flagName] === flagValue;
          }
        });

      const excludeFlagsMatch =
        !features.excludeFlags ||
        !Object.entries(features.excludeFlags).some(([flagName, flagValue]) => {
          if (!flagName || !flagValue) {
            return false;
          }

          if (Array.isArray(flagValue)) {
            return flagValue.some(
              (valueItem: string) => asset[flagName] === valueItem
            );
          } else {
            return asset[flagName] === flagValue;
          }
        });

      return (
        includeTypeMatch && excludeTypeMatch && flagsMatch && excludeFlagsMatch
      );
    });
    return filteredAssets;
  }
  return [];
}

/** From a given asset - get the list of readings from a particular
 *  param type and measurement (only 1 should match)
 *  Result: { aggregate, measurementParameterCodeName, measurementParameterName, records }
 *          or null/undefined if not found
 */
// TODO:
export function extractReadingsFromAsset(
  assetWithReadings: any,
  parameterTypeCodeName: string,
  measurementParamCodeName: string
) {
  if (assetWithReadings && !isEmpty(assetWithReadings.readings)) {
    const paramTypeGroup = assetWithReadings.readings.find(
      (paramTypeGroup: any) =>
        paramTypeGroup.paramTypeCodeName === parameterTypeCodeName
    );

    const readings = paramTypeGroup?.readings?.find(
      (measurementParamGroup: any) =>
        measurementParamGroup.measurementParameterCodeName ===
        measurementParamCodeName
    );

    return readings;
  }

  return null;
}

const getLabel = (
  text: string,
  variant = "light",
  prefix?: string,
  postfix?: string
) => {
  return {
    text: `${prefix ? prefix + " " : ""}${text}${postfix ? " " + postfix : ""}`,
    variant: variant,
  };
};

/** Based on provided config (see chartDatasourceConfig) extract the data needed
 *  for chart labels and put them into correct format
 *
 *  dataObject: source of data - calculation or asset
 *  labelsConfig: taken from chartConfig - either for entire chart or per-dataset
 */
export function extractLabels(
  labelsConfigs: Array<Label>,
  asset?: any,
  otherDataObject?: any,
  disableMasterMeterLabel?: boolean
) {
  const masterLabels: Array<{ text: string; variant: string }> = [];

  // add master meter labels
  if (
    !disableMasterMeterLabel &&
    asset &&
    (asset.isMainGas || asset.isMainLoad)
  ) {
    const labels = masterMeterLabel.customLabels?.map((customLabel) => {
      return getLabel(
        customLabel.title,
        customLabel.variant,
        customLabel.prefix,
        customLabel.postfix
      );
    });
    labels?.length && masterLabels.push(...labels);
  }

  if (!labelsConfigs || !isArray(labelsConfigs)) {
    return masterLabels.length ? masterLabels : [];
  }

  return labelsConfigs.reduce(
    (labels: Array<{ text: string; variant: string }>, labelsConfig) => {
      const dataObject =
        labelsConfig.source === "asset" ? asset : otherDataObject;

      if (
        labelsConfig.source === "asset" &&
        labelsConfig.allowedAssetTypes &&
        !labelsConfig.allowedAssetTypes.includes(dataObject.assetType)
      ) {
        return labels;
      }

      if (
        !labelsConfig?.showLabels ||
        (labelsConfig.source === "custom" &&
          labelsConfig.type === LabelType.masterMeter &&
          masterLabels.length)
      ) {
        return labels;
      }

      if (labelsConfig.source === "custom") {
        const customLabels = labelsConfig.customLabels?.map((customLabel) => {
          return getLabel(
            customLabel.title,
            customLabel.variant,
            customLabel.prefix,
            customLabel.postfix
          );
        });

        customLabels && labels.push(...customLabels);
      } else if (
        labelsConfig &&
        labelsConfig.showLabels === true &&
        labelsConfig.property &&
        dataObject &&
        dataObject[labelsConfig.property]
      ) {
        // if there is an array of labels...
        if (isArray(dataObject[labelsConfig.property])) {
          const newLabels = dataObject[labelsConfig.property].reduce(
            (newLabels: typeof labels, labelData: any) => {
              if (labelData === "object" && !labelsConfig.titleProperty) {
                return newLabels;
              }

              // labels can come in the object form or as a pure string/number
              const title =
                typeof labelData === "object" && labelsConfig.titleProperty
                  ? labelData[labelsConfig.titleProperty]
                  : labelData;

              newLabels.push(
                getLabel(
                  title,
                  labelsConfig.variant,
                  labelsConfig.prefix,
                  labelsConfig.unit
                )
              );

              return newLabels;
            },
            []
          );

          newLabels && labels.push(...newLabels);
        } else {
          // labels can come in the object form or as a pure string/number
          const title =
            typeof dataObject[labelsConfig.property] === "object" &&
            labelsConfig.titleProperty
              ? dataObject[labelsConfig.property][labelsConfig.titleProperty]
              : dataObject[labelsConfig.property];

          if (
            !(
              dataObject[labelsConfig.property] === "object" &&
              !labelsConfig.titleProperty
            )
          ) {
            labels.push(
              getLabel(
                title,
                labelsConfig.variant,
                labelsConfig.prefix,
                labelsConfig.unit
              )
            );
          }
        }
      }

      return labels;
    },
    [...masterLabels]
  );
}

/** Based on provided config (see chartDatasourceConfig) extract the data needed
 *  for the list of datasets and put them into correct format
 */
// TODO:
export function extractDatasets(
  datasetsConfig: any
): { key: string; label: string }[] {
  if (datasetsConfig && !isEmpty(datasetsConfig)) {
    return datasetsConfig
      .filter((dataset: any) => dataset.enabled)
      .map((dataset: any) => ({
        key: dataset.key,
        label: dataset.title,
      }));
  }
  return [] as { key: string; label: string }[];
}

/** From dataObject (typically an asset) that contains the structure of
 *  parameterType >> measurementParameter - find the desired measurement params and
 *  return info about it (object).
 *  Return {} if measurement param is not found
 */
// TODO:
export function extractMeasurementTypeInfo(
  dataObject: any,
  parameterTypeCodeName: string,
  measurementParamCodeName: string
) {
  const info: Record<string, any> = {};
  if (dataObject && !isEmpty(dataObject.parameterTypes)) {
    const paramType = dataObject.parameterTypes.find(
      (paramType: any) => paramType.codeName === parameterTypeCodeName
    );
    const measurementParam = paramType?.measurementParameters?.find(
      (measurementParam: any) =>
        measurementParam.codeName === measurementParamCodeName
    );
    if (measurementParam) {
      info.unit = paramType.unit;
      info.paramTitle = paramType.name;
      info.measurementTitle = measurementParam.name;
      info.conversionConstant = paramType.conversionConstant;
    }
  }
  return info;
}

/** Based on provided assets and config (see chartDatasourceConfig)
 *  extract the data needed
 *  for the list of chart layers (for each dataset) and put them into correct format.
 *  Result: object; indexed by dataset key, each containing list of layers
 */
// TODO:
export function extractLayersSettings(
  assets: any,
  datasetsConfig: any,
  layerConditions: any,
  resolution?: string
) {
  /** go through the list of datasets in the config
   *  in each dataset - through list of layers
   *  for each layer - pick additional params from source asset's measurement types
   *                   (like title and units) and construct config
   */
  const layers: Record<string, any> = {};
  if (datasetsConfig && !isEmpty(datasetsConfig)) {
    datasetsConfig.forEach((dataset: any) => {
      const datasetLayers: any[] = [];
      if (dataset.chartLayers && !isEmpty(dataset.chartLayers)) {
        dataset.chartLayers
          .filter(
            (layer: any) => !layer.condition || layerConditions[layer.condition]
          )
          .forEach((layer: any) => {
            // if layer's data come from assets...
            if (
              (!layer.source || layer.source.type === "asset") &&
              assets &&
              !isEmpty(assets)
            ) {
              // find source asset - should be only 1 - defined in dataset config
              const matchingAssets = layer.source
                ? pickAssetsByFeatures(assets, layer.source)
                : assets;
              const sourceAsset =
                matchingAssets && !isEmpty(matchingAssets)
                  ? matchingAssets[0]
                  : {};

              // find additional layer info
              const layerInfo = extractMeasurementTypeInfo(
                sourceAsset,
                layer.data.parameterTypeCodeName,
                layer.data.measurementParameterCodeName
              );

              const description = layer.description
                ? `${layer.description.text}${
                    layerInfo[layer.description.valueProperty] ?? ""
                  }`
                : "";

              // put all data together
              if (!isEmpty(layerInfo)) {
                const yLabel =
                  layer.data.title ?? layerInfo[layer.data.titleProperty];
                datasetLayers.push({
                  yKey: layer.key,
                  unit: layerInfo.unit,
                  yLabel:
                    yLabel === "Power" && resolution !== "5min"
                      ? "Average Power"
                      : yLabel,
                  description,
                  sourceType: layer.source?.type ?? "asset",
                  sourceId: sourceAsset.assetId, // not for the charts, but later for getting readings for each layer
                  yAxisId: layer.rightYAxis ? chartRightAxisId : null,
                  ...layer.chartProps,
                });
              }
            } else if (layer.source.type === "derived") {
              // layer's data are not from assets, but some other calculated value(s)
              datasetLayers.push({
                yKey: layer.key,
                sourceType: layer.source.type,
                sourceId: layer.source.codeName, // not for the charts, but later for getting readings for each layer
                yAxisId: layer.rightYAxis ? chartRightAxisId : null,
                ...layer.chartProps,
              });
            }
          });
      }
      layers[dataset.key] = datasetLayers;
    });
  }
  return layers;
}

/** From a list of layers (chart widget config) pick the one that is supposed to have
 *  values displayed on right axis and use it to generate the object with yAxis chart props
 */
export function extractRightYAxisParams(layers: any) {
  if (layers && !isEmpty(layers)) {
    const rightAxisLayer = layers.find(
      (layer: any) => layer.yAxisId === chartRightAxisId
    );
    if (rightAxisLayer) {
      return {
        // unit: rightAxisLayer.unit,
        tick: { fill: rightAxisLayer.color },
        stroke: rightAxisLayer.color,
      };
    }
  }
  return null;
}

// if at least one asset param belongs to the list of v1 param types
export function assetHasV1Data(
  asset: {
    __typename?: "AssetV2" | undefined;
  } & AssetV2Fragment
) {
  if (asset && asset.parameterTypes) {
    return (
      isEmpty(asset.parameterTypes) ||
      asset.parameterTypes.some((paramType) =>
        v1paramTypes.has(paramType.codeName)
      )
    );
  }
  return false;
}

// if at least one asset param doesn't belong to the list of v1 param types
// TODO: This is not used
export function assetHasV2Data(asset: any) {
  if (asset && asset.parameterTypes && !isEmpty(asset.parameterTypes)) {
    return asset.parameterTypes.some(
      (paramType: any) => !v1paramTypes.has(paramType.codeName)
    );
  }
  return false;
}

export function isMarketTypeElectric(market: {
  energyService?: Maybe<string>;
}) {
  return market.energyService === "E";
}

export function isMarketTypeGas(market: { energyService?: Maybe<string> }) {
  return market.energyService === "NG";
}

/**
 * Take the list of v1 assets with readings (power, energy) and extract the target value
 * and format into a "v2" shape:
 *    {
 *      aggregate,
 *      measurementParameterName,
 *      measurementParameterCodeName,
 *      records
 *    }
 * , see formatters > combineAssetsWithReadings
 *
 * @param measurementCodeName anything from v1ParamTypes (power_v1, energy_v1)
 */
export function convertV1ReadingsToV2Format(
  assetWithReadings: {
    __typename?: "AssetV1" | undefined;
  } & AssetV1Fragment,
  measurementCodeName: string
) {
  const targetValue = v1paramTypes.get(measurementCodeName);
  const assetAggregate = assetWithReadings.readings?.aggregate;
  const aggregate = assetAggregate
    ? [
        {
          average: assetAggregate[`average${targetValue}`],
          maximum: assetAggregate[`maximum${targetValue}`],
          minimum: assetAggregate[`minimum${targetValue}`],
          sum: assetAggregate[`sum${targetValue}`],
        },
      ]
    : [];
  const records = isEmpty(assetWithReadings.readings?.readings)
    ? []
    : // @ts-expect-error
      assetWithReadings?.readings?.readings.map((reading) => ({
        // @ts-expect-error
        value: reading[targetValue.toLowerCase()],
        timestamp: reading.timestamp,
        parameterType: measurementCodeName,
      }));
  return {
    aggregate,
    measurementParameterName: targetValue,
    measurementParameterCodeName: measurementCodeName,
    records,
  };
}

/**
 * Extract only the config for current dataset from chart config
 */
// TODO:
export function getActiveDatasetConfig(chartConfig: any, activeDataset: any) {
  if (chartConfig && !isEmpty(chartConfig.datasets)) {
    return chartConfig.datasets.find(
      (dataset: any) => dataset.key === activeDataset
    );
  }
  return null;
}
