import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  roundToNearestMinutes,
} from "date-fns";
import {
  matomoDownloadIntervals,
  MatomoDurationType,
  MatomoUserType,
  PageName,
} from "src/lib/matomo/appMatomo.types";
import { MarketEventStatus } from "ui-core";
import { useKeycloak } from "../KeycloakProvider";
import { MarketEventProgress } from "../marketUtils";

export const getUserType = (
  userInfo?: ReturnType<typeof useKeycloak>["userInfo"]
) => {
  const internalCondition =
    process.env.REACT_APP_MATOMO_INTERNAL_USER_CONDITION;

  return internalCondition && userInfo?.email?.includes(internalCondition)
    ? MatomoUserType.internalUser
    : MatomoUserType.externalUser;
};

export function getChartWidgetDescription(
  assetOrTitle?: string,
  assetMeasurementType?: string | undefined,
  chartType?: "main" | "not main"
) {
  if (!assetOrTitle) {
    return "Chart details not specified";
  }

  const assetMeasurementTypeText = assetMeasurementType
    ? `, Asset measurement type: ${assetMeasurementType}`
    : "";
  const chartTypeText = chartType ? `, Chart type: ${chartType}` : "";

  return `Chart asset or title: ${assetOrTitle}${assetMeasurementTypeText}${chartTypeText}`;
}

export function getMarketProgramEventName(
  marketProgramName: string,
  duration: MatomoDurationType,
  status: MarketEventStatus,
  progress: MarketEventProgress
) {
  return `Market program: ${marketProgramName}, Duration: ${duration}, Status: ${status}, Progress: ${progress}` as const;
}

export function getNameForMarketProgramParticipationWidget(page: PageName) {
  return `Market program participation widget [${page} page]` as const;
}

export function getNameForBuildingsCharts(page: PageName) {
  return `All building’s charts [${page} page]` as const;
}

export function getNameForTotalMonthlyMarketParticipationChart(page: PageName) {
  return `Total Monthly Market Participation Revenue Chart [${page} page]` as const;
}

export function getNameforAllDataChange(page: PageName) {
  return `All data [${page} page]` as const;
};

export function getDownloadZipDescription(
  duration: MatomoDurationType,
  dataInterval: typeof matomoDownloadIntervals[keyof typeof matomoDownloadIntervals],
  groupOption:
    | "group buildings into one file"
    | "separate buildings into different files",
  numberOfBuildings: number,
  dataTypes: Array<string> | "all"
) {
  const dataTypesString = Array.isArray(dataTypes)
    ? dataTypes.join(", ")
    : dataTypes;

  return `Duration: ${duration}, Data interval: ${dataInterval}, Group option: ${groupOption}, Number of buildings: ${numberOfBuildings}, Data types: [${dataTypesString}]`;
}

export function getMatomoDurationFromDate(
  firstDate: Date | string,
  secondDate?: Date | string | undefined
): MatomoDurationType {
  if (!secondDate) {
    return "not yet announced";
  }

  const date1 = typeof firstDate === "string" ? new Date(firstDate) : firstDate;
  const date2original =
    typeof secondDate === "string" ? new Date(secondDate) : secondDate;
  const date2 = roundToNearestMinutes(date2original, {
    roundingMethod: "ceil",
  });

  const diffInSeconds = Math.abs(differenceInSeconds(date1, date2original));
  const diffInMinutes = Math.abs(differenceInMinutes(date1, date2));
  const diffInHours = Math.abs(differenceInHours(date1, date2));
  const diffInDays = Math.abs(differenceInDays(date1, date2));
  const diffInWeeks = Math.abs(differenceInWeeks(date1, date2));
  const diffInMonths = Math.abs(differenceInMonths(date1, date2));
  const diffInYears = Math.abs(differenceInYears(date1, date2));

  if (diffInMinutes < 60) {
    return "less than hour";
  } else if (diffInHours < 24) {
    return `${diffInHours} hours`;
  } else if (diffInDays < 7) {
    return `${diffInDays} days`;
  } else if (diffInWeeks < 4) {
    return `${diffInWeeks} weeks`;
  } else if (diffInMonths < 12) {
    return `${diffInMonths} months`;
  } else if (diffInYears >= 1) {
    return `${diffInYears} years`;
  }

  return `${diffInSeconds} seconds`;
}
