import { trackEvent, trackPageView as trackPageViewGeneral } from "./matomo";

import {
  PageName,
  MatomoActionCustomDimension,
} from "src/lib/matomo/appMatomo.types";
import {
  getMarketProgramEventName,
  getNameForBuildingsCharts,
  getNameForMarketProgramParticipationWidget,
  getNameForTotalMonthlyMarketParticipationChart,
  getNameforAllDataChange,
} from "./matomoUtils";

export function trackPageView(page: PageName) {
  trackPageViewGeneral(page);
}

export const EventBuilder = {
  withCategory<C extends CategoryType>(category: C) {
    return new MatomoEventSender1(category);
  },
};

type EventType = {
  "User profile": {
    "User logged-in": undefined;
    "Redirect to log-in page": undefined;
    "Log-out from application": undefined;
  };
  Download: {
    "Export buildings data": "Create JSON report" | "Create CSV report";
  };
  "Date range": {
    "Change date range": ReturnType<
      | typeof getNameForMarketProgramParticipationWidget
      | typeof getNameForBuildingsCharts
      | typeof getNameForTotalMonthlyMarketParticipationChart
      | typeof getNameforAllDataChange
    >;
  };
  "Market program event": {
    "System identified live event": ReturnType<
      typeof getMarketProgramEventName
    >;
    "Acknowledge market program event": ReturnType<
      typeof getMarketProgramEventName
    >;
  };
};

type CategoryType = keyof EventType;

class MatomoEventSender1<C extends CategoryType> {
  constructor(private category: C) {}
  withAction<A extends keyof EventType[C] & string>(
    action: A
  ): MatomoEventSender2Type<C, A> {
    return new MatomoEventSender2(this.category, action);
  }
}

type MatomoEventSender2Type<
  C extends CategoryType,
  A extends keyof EventType[C] & string
> = EventType[C][A] extends undefined
  ? Omit<MatomoEventSender2<C, A>, "withName">
  : Pick<MatomoEventSender2<C, A>, "withName">;

class MatomoEventSender2<
  C extends CategoryType,
  A extends keyof EventType[C] & string
> {
  private dimensions: Array<[number, string]> = [];
  private name?: string;
  private value?: number;

  constructor(private readonly category: C, private readonly action: A) {}

  withName(
    name: EventType[C][A] & string
  ): Omit<MatomoEventSender2<C, A>, "withName"> {
    this.name = name;
    return this;
  }

  withValue(
    value: number
  ): Pick<MatomoEventSender2<C, A>, "addDimension" | "create"> {
    this.value = value;
    return this;
  }

  addDimension(
    name: keyof typeof MatomoActionCustomDimension,
    value: string
  ): Pick<MatomoEventSender2<C, A>, "addDimension" | "create"> {
    this.dimensions.push([MatomoActionCustomDimension[name], value]);
    return this;
  }

  create(): Parameters<typeof trackEvent>[0] {
    return {
      category: this.category,
      action: this.action,
      name: this.name,
      value: this.value,
      customDimensions: this.dimensions,
    };
  }
}
