/** @format */

import React, { memo, useRef, useState, useCallback, useEffect } from "react";
import {
  FilterView,
  FilterItem,
  FilterViewProps,
  getInitialValueMap,
} from "./FilterView";
import { Filter24, Remove24, Search24 } from "@bphxd/ds-core-react/lib/icons";
import { Badge, Button, Input, InputGroup } from "@bphxd/ds-core-react";

export interface SearchBarProps {
  /**
   * Callback for input changes
   */
  onChange: (text?: string) => void;
  /**
   * Callback for filter dropdown changes
   */
  onFilterChange?: FilterViewProps["onChange"];
  /**
   * Optional text placeholder in search input
   */
  placeholder?: string;
  /**
   * Filter item array
   */
  filters: FilterItem[];
  /**
   * Optional filter group mapping - if provided, you need to put all filters into groups
   */
  filterGroups?: FilterViewProps["groups"];
  /**
   * Main title for filter panel
   */
  title?: string;
  /**
   * Callback that gets triggered after the filters and search box values are reset
   */
  onReset?: () => void;
}

export const SearchBar = memo((props: SearchBarProps) => {
  const {
    onChange,
    placeholder = "Search",
    filters,
    onFilterChange,
    filterGroups,
    title,
    onReset,
  } = props;

  const [filterViewVisible, setFilterViewVisible] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [text, setText] = useState("");
  const filterViewRef = useRef<FilterView>(null);

  const filterWrapperRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);
  const resetButtonRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        !filterWrapperRef?.current?.contains(event.target) &&
        !filterButtonRef?.current?.contains(event.target) &&
        !resetButtonRef?.current?.contains(event.target)
      ) {
        setFilterViewVisible(false);
      }
    },
    [filterButtonRef, filterWrapperRef, resetButtonRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const toggleFilterView = useCallback(() => {
    setFilterViewVisible((prevFilterViewVisible) => !prevFilterViewVisible);
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === "function") {
        onChange(event.target.value);
      }

      setText(event.target.value);
    },
    [onChange]
  );

  const handleFilterChange = useCallback(
    (values, activeFilterCount) => {
      if (typeof onFilterChange === "function") {
        onFilterChange(values, activeFilterCount);
      }

      setActiveFilterCount(activeFilterCount);
    },
    [onFilterChange]
  );

  const handleReset = useCallback(() => {
    if (typeof filterViewRef.current?.reset === "function") {
      filterViewRef.current.reset();

      if (typeof onFilterChange === "function") {
        onFilterChange(getInitialValueMap(filters), 0);
      }
    }

    setText("");

    if (typeof onChange === "function") {
      onChange("");
    }

    if (typeof onReset === "function") {
      onReset();
    }
  }, [filters, onFilterChange, onChange, onReset]);

  const isDirty = activeFilterCount > 0 || !!text;

  return (
    <div className="search-bar d-flex flex-wrap gap-3 me-n3 justify-content-end align-items-center">
      <div>
        <InputGroup className="input-group-merge">
          <Input
            className="form-control-appended"
            placeholder={placeholder}
            value={text}
            onChange={handleChange}
            role="searchbox"
          />
          <div className="input-group-append">
            <InputGroup.Text>
              <Search24 />
            </InputGroup.Text>
          </div>
        </InputGroup>
      </div>

      {isDirty && (
        <div ref={filterViewVisible ? resetButtonRef : undefined}>
          <Button
            level="quartenary"
            onClick={handleReset}
            aria-label="Reset Filter"
            Icon={Remove24}
            iconPosition="end"
          >
            Reset
          </Button>
        </div>
      )}

      <div
        className="position-relative"
        ref={filterViewVisible ? filterButtonRef : undefined}
      >
        <Button
          rounded="0"
          level="tertiary"
          onClick={toggleFilterView}
          aria-label="Filter"
          Icon={Filter24}
          iconPosition="end"
        >
          Filter
        </Button>
        {activeFilterCount > 0 && (
          <span className="navbar-notification position-absolute top-0 start-100 translate-middle-x ms-n2 mt-n3">
            {activeFilterCount}{" "}
            <span className="visually-hidden">active filters</span>
          </span>
        )}
      </div>

      <div ref={filterViewVisible ? filterWrapperRef : undefined}>
        <FilterView
          ref={filterViewRef}
          className="search-bar__filters"
          title={title}
          items={filters}
          groups={filterGroups}
          visible={filterViewVisible}
          onChange={handleFilterChange}
        />
      </div>
    </div>
  );
});
