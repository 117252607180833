import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Loader } from "ui-core";
import { useKeycloak } from "./KeycloakProvider";

type ProtectedRouteProps = React.PropsWithChildren<{
  component: React.ComponentType<any>;
}> &
  Omit<RouteProps, "component">;

/**
 * This component is the main access safeguard for the pages
 * and works in conjunction with the KeycloakProvider
 */
function ProtectedRoute(props: ProtectedRouteProps) {
  const { children, component: Component, ...rest } = props;

  const { authenticated, authenticating } = useKeycloak();

  if (authenticating) {
    return <Loader text="Authenticating..." overlay />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          Component ? (
            <Component />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/silent-login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute;
