/**
 *  Inspired by https://github.com/rehooks/window-size/blob/master/index.js
 */

import { useCallback, useEffect, useState } from "react";

function getWindowDimensions() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

export default function useWindowDimensions() {
  let [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const handleWindowResize = useCallback(() => {
    setWindowDimensions(getWindowDimensions());
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return windowDimensions;
}
