/** @format */

import { lazy, memo, Suspense, useMemo } from "react";
import { StatisticsItemProps } from "./StatisticsBar";
import { DatasetItemProps } from "./DatasetSwitch";
import {
  DateRangeParam,
  DateRangeType,
  TimeIntervalSwitchProps,
} from "../../blocks/TimeIntervalSwitch";

import { Icon } from "@elements/Icon";
import { useResizeDetector } from "react-resize-detector";
import { Badge, BadgeProps, Popover } from "@bphxd/ds-core-react";
import { uniqueId } from "lodash-es";

export interface ChartToolbarProps {
  /**
   * A text or element label for the toolbar
   */
  title: string | JSX.Element;
  /**
   * Optional text or element to be displayed as a subtitle
   */
  subtitle?: string | JSX.Element;
  /**
   * Optional icon name to show before title.
   */
  iconName?: string;
  /**
   * Optional array of objects for displaying statistics items
   */
  statistics?: Array<StatisticsItemProps>;
  /**
   * Language used for number formating, defaults to user's browser language
   */
  locale?: string;
  /**
   * Optional array of button labels displayed in the center of the toolbar
   */
  labels?: Array<{
    text: string;
    variant?: BadgeProps["color"];
  }>;
  /**
   * Optional data array or a 'no data available' message will be displayed
   */
  datasets?: Array<DatasetItemProps>;
  /**
   * Optional string specifying the variable key of the selected dataset
   */
  activeDatasetKey?: string;
  /**
   * Optional loading state boolean
   */
  loading?: boolean;
  /**
   * Optional callback for when a dataset is selected
   */
  onDatasetChange?: (key: string) => void;
  /**
   * Optional array of toggle buttons
   */
  toggleButtons?: Array<{
    value: string | number;
    name: string;
  }>;
  /**
   * Callback executed when a toggle button is changed
   */
  onToggleButtonChange?: (value: string) => void;
  /**
   * Specify the currently selected toggle button
   */
  selectedToggleButton?: string;
  /**
   * Optional minimum start date
   */
  minDate?: Date;
  /**
   * Optional maximum end date
   */
  maxDate?: Date;
  /**
   * Optional object containing start, end, interval
   */
  dateRange?: DateRangeType;
  /**
   * Optional format string for any timestamp displays
   */
  dateFormatMap?: Record<string, string>;
  /**
   * Optional format string for any timestamp displays
   */
  timeFormat?: string;
  /**
   * Optional last updated timestamp
   */
  lastUpdated?: Date;
  /**
   * Callback that is run when a date selection changes
   */
  onDateRangeChange?: (dateRange: DateRangeParam) => void;
  /**
   * A filter for date interval buttons. If left undefined, all of the buttons will be visible.
   * If set to null, none of them will be visible.
   */
  timeIntervals?: TimeIntervalSwitchProps["intervals"];
  /**
   * Boolean to specify whether the datepicker is displayed
   */
  showDatePicker?: boolean;
  /**
   * Boolean to specify whether to open datepicker as modal or by default as popup
   */
  showDatePickerAsModal?: boolean;
  /**
   * Multiselect dropdown filter props.
   */
}

const StatisticsBar = lazy(() => import("./StatisticsBar"));
const DatasetSwitch = lazy(() => import("./DatasetSwitch"));
const TimeIntervalSwitch = lazy(
  () => import("../../blocks/TimeIntervalSwitch")
);
const ToggleButtons = lazy(() => import("./ToggleButtons"));

export const ChartToolbar = memo((props: ChartToolbarProps) => {
  const {
    iconName,
    title = "",
    subtitle,
    labels,
    statistics,
    locale = navigator.language,
    datasets,
    activeDatasetKey = "power",
    minDate,
    maxDate,
    dateFormatMap,
    timeFormat,
    dateRange,
    onDateRangeChange,
    onDatasetChange,
    loading = false,
    showDatePicker = true,
    showDatePickerAsModal,
    lastUpdated,
    timeIntervals,
    toggleButtons = [],
    selectedToggleButton,
    onToggleButtonChange,
  } = props;

  const { width, ref } = useResizeDetector();

  const isWrap = width && width < 768 ? true : false;

  const maxNumOfLabels = labels && labels.length === 3 ? 3 : 2;
  const visibleLabels = labels ? labels.slice(0, maxNumOfLabels) : [];
  const popoverLabels = labels ? labels.slice(maxNumOfLabels) : [];

  const showToggleButtons = !!(toggleButtons && toggleButtons.length);

  const showTimeIntervalSwitch = !!(
    showDatePicker && typeof onDateRangeChange === "function"
  );

  const showDatasetSwitch = !!(
    !showDatePicker &&
    datasets &&
    datasets.length > 1
  );

  const hiddenLabelsUniqueId = useMemo(
    () => uniqueId("hidden-labels-trigger"),
    []
  );

  return (
    <div ref={ref} className="chart-toolbar bg-white w-full d-flex flex-column">
      <div
        className={`d-flex x5-px-5 py-4 ${
          isWrap ? "flex-wrap" : ""
        } justify-content-between`}
      >
        <div
          className={`d-flex flex-wrap flex-column align-self-start ${
            isWrap ? "w-50 pe-2" : "pe-3"
          } align-items-start flex-1`}
        >
          <div className="d-flex flex-wrap gap-2">
            <div className="d-flex align-items-center x5-pe-1">
              {iconName ? (
                <Icon
                  name={iconName}
                  fill="currentColor"
                  className="me-2 flex-shrink-0  align-self-start"
                  size={20}
                />
              ) : null}
              <span className="text-break lh-1-5">{title}</span>
            </div>
            {visibleLabels.map((label, index) => (
              <span key={index} className="d-inline-flex">
                <Badge color={label.variant || "light"}>{label.text}</Badge>
                {index === maxNumOfLabels - 1 && popoverLabels?.length > 0 ? (
                  <>
                    <Badge
                      className="x5-ms-1"
                      color="light"
                      id={hiddenLabelsUniqueId}
                    >
                      ...
                    </Badge>
                    <Popover trigger="hover" target={hiddenLabelsUniqueId}>
                      <div className="m-n2">
                        {popoverLabels.map((hiddenLabel, hiddenLableIndex) => (
                          <div key={hiddenLableIndex} className="p-2">
                            <Badge color={hiddenLabel.variant || "light"}>
                              {hiddenLabel.text}
                            </Badge>
                          </div>
                        ))}
                      </div>
                    </Popover>
                  </>
                ) : null}
              </span>
            ))}
          </div>
          {subtitle ? (
            <div className={`fs-6 text-secondary lh-1.5 pt-3`}>
              <span className="text-break">{subtitle}</span>
            </div>
          ) : null}
        </div>
        <div className={`${isWrap ? "w-100 order-3" : `order-2`}`}>
          {showToggleButtons ? (
            <Suspense fallback={null}>
              <ToggleButtons
                width={width || null}
                toggleButtons={toggleButtons}
                selectedToggleButton={selectedToggleButton}
                onToggleButtonChange={onToggleButtonChange}
                loading={loading}
              />
            </Suspense>
          ) : null}
        </div>
        {showTimeIntervalSwitch || showDatasetSwitch || showToggleButtons ? (
          <div
            className={`${
              isWrap ? "w-50 ps-2 order-2" : " ps-3 order-3 flex-1"
            } `}
          >
            {showTimeIntervalSwitch ? (
              <Suspense fallback={null}>
                <TimeIntervalSwitch
                  minDate={minDate}
                  maxDate={maxDate}
                  dateRange={dateRange}
                  dateFormatMap={dateFormatMap}
                  timeFormat={timeFormat}
                  onChange={onDateRangeChange}
                  loading={loading}
                  lastUpdated={lastUpdated}
                  intervals={timeIntervals}
                  showDatePickerAsModal={showDatePickerAsModal}
                />
              </Suspense>
            ) : null}
            {showDatasetSwitch ? (
              <Suspense fallback={null}>
                <div className="d-flex justify-content-end">
                  <DatasetSwitch
                    datasets={datasets}
                    activeDatasetKey={activeDatasetKey}
                    onChange={onDatasetChange}
                  />
                </div>
              </Suspense>
            ) : null}
          </div>
        ) : null}
      </div>

      {statistics || (showDatePicker && datasets && datasets.length > 1) ? (
        <div className="d-flex flex-wrap-reverse justify-content-between align-items-start x5-px-4 py-4 border-top">
          {statistics ? (
            <Suspense fallback={null}>
              <StatisticsBar
                statistics={statistics}
                loading={loading}
                locale={locale}
              />
            </Suspense>
          ) : null}
          {showDatePicker && datasets && datasets.length > 1 ? (
            <Suspense fallback={null}>
              <DatasetSwitch
                loading={loading}
                datasets={datasets}
                activeDatasetKey={activeDatasetKey}
                onChange={onDatasetChange}
              />
            </Suspense>
          ) : null}
        </div>
      ) : null}
    </div>
  );
});
