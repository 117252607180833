import React, { Component, createRef, useCallback, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Error } from "ui-core";

import {
  KeycloakProvider,
  KeycloakProviderProps,
  useKeycloak,
} from "src/lib/KeycloakProvider";
import errorMessage from "src/lib/errorMessage";
import FullPageWrapper from "src/components/widgets/FullPageWrapper";
import AppContent from "./AppContent";
import SilentLogin from "./SilentLogin";
import {
  NotificationsProvider,
  NotificationsProviderRefElement,
} from "src/lib/NotificationsProvider";
import {
  KeycloakOnLoad,
  KeycloakPkceMethod,
  KeycloakResponseMode,
} from "keycloak-js";
import "@sass/main.scss";
import { getUserType } from "src/lib/matomo/matomoUtils";
import { MatomoVisitCustomDimension } from "src/lib/matomo/appMatomo.types";
import {
  setCustomDimension,
  setUserId,
  trackEvent,
} from "src/lib/matomo/matomo";
import { EventBuilder } from "src/lib/matomo/appMatomo";
import { AppToaster } from "./AppToaster";

export const keycloakConfiguration = {
  url: process.env.REACT_APP_KEYCLOAK_URL as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  onLoad: "check-sso" as KeycloakOnLoad,
  loginRedirectUri: window.location.origin + "/",
  logoutRedirectUri: window.location.origin + "/silent-login",
  promiseType: "native",
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
  pkceMethod: "S256" as KeycloakPkceMethod,
  responseMode: "fragment" as KeycloakResponseMode,
  scope: "email profile",
  enableLogging: process.env.NODE_ENV === "development",
  redirectToLoginOnAuthLogout: true,
  useIdTokenForUserInfo: true, // user Login event track works only if idToken is parsed, only if this value is true
  // Use session status iFrame only if it configured to be used or when the app is in production
  checkLoginIframe:
    typeof process.env.REACT_APP_KEYCLOAK_USE_SESSION_STATUS_IFRAME ===
    "undefined"
      ? process.env.NODE_ENV === "production"
      : process.env.REACT_APP_KEYCLOAK_USE_SESSION_STATUS_IFRAME.toLowerCase() ===
        "true",
};

function MatomoUserTracker({ children }: React.PropsWithChildren<{}>) {
  const { userInfo } = useKeycloak();

  useEffect(() => {
    const userType = getUserType(userInfo);

    if (userInfo.id) {
      setUserId(userInfo.id);
      setCustomDimension(MatomoVisitCustomDimension.userType, userType);
    }
  }, [userInfo]);

  return <React.Fragment>{children}</React.Fragment>;
}

const KeycloakConfigProvider = React.forwardRef<
  KeycloakProvider,
  React.PropsWithChildren<Omit<KeycloakProviderProps, "ref">>
>(({ children, ...keycloakProviderProps }, ref) => {
  const handleLoginSuccess = useCallback(() => {
    trackEvent(
      EventBuilder.withCategory("User profile")
        .withAction("User logged-in")
        .create()
    );
  }, []);

  return (
    <KeycloakProvider
      {...keycloakProviderProps}
      ref={ref}
      onLoginSuccess={handleLoginSuccess}
    >
      {children}
    </KeycloakProvider>
  );
});

// NOTE: Would be better to refactor to functional component in order we want to use hooks in the component directly
export default class App extends Component {
  state = { hasError: false, error: undefined };
  notificationsAuthRetryTimer: ReturnType<typeof setInterval> | undefined;

  keycloakProviderRef = createRef<KeycloakProvider>();
  notificationsProviderRef = createRef<NotificationsProviderRefElement>();

  componentWillUnmount() {
    if (this.notificationsAuthRetryTimer) {
      clearInterval(this.notificationsAuthRetryTimer);
    }
  }

  handleLogout = () => {
    if (this.notificationsProviderRef.current) {
      this.notificationsProviderRef.current.closeConnection();
    }
  };

  handleAuthSuccess = () => {
    if (this.notificationsProviderRef.current) {
      this.notificationsProviderRef.current.openConnection();
    }
  };

  handleNotificationsConnectionOpen = () => {
    if (this.keycloakProviderRef.current) {
      const { token } = this.keycloakProviderRef.current.getTokens();

      if (token) {
        if (this.notificationsProviderRef.current) {
          this.notificationsProviderRef.current.authenticate(token);
        }
      } else {
        // NOTE: to mitigate race conditions
        this.notificationsAuthRetryTimer = setInterval(() => {
          if (this.keycloakProviderRef.current) {
            const { token } = this.keycloakProviderRef.current.getTokens();

            if (token) {
              if (this.notificationsProviderRef.current) {
                this.notificationsProviderRef.current.authenticate(token);

                if (this.notificationsAuthRetryTimer) {
                  clearInterval(this.notificationsAuthRetryTimer);
                }
              }
            }
          }
        }, 200);
      }
    }
  };

  // Global catch-all handler
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  render() {
    const { hasError, error } = this.state;

    return hasError ? (
      <FullPageWrapper>
        <Error
          text={errorMessage(
            "The application experienced an error - please try refreshing the page and if the issue persists, contact support.",
            error
          )}
        />
      </FullPageWrapper>
    ) : (
      <KeycloakConfigProvider
        {...keycloakConfiguration}
        ref={this.keycloakProviderRef}
        onLogout={this.handleLogout}
        onAuthSuccess={this.handleAuthSuccess}
      >
        <React.StrictMode>
          <MatomoUserTracker>
            <NotificationsProvider
              url={process.env.REACT_APP_WS_URL as string}
              ref={this.notificationsProviderRef}
              onOpen={this.handleNotificationsConnectionOpen}
            >
              <AppToaster />
              <Router>
                <Switch>
                  <Route exact path="/silent-login" component={SilentLogin} />
                  <Route exact path="*" component={AppContent} />
                </Switch>
              </Router>
            </NotificationsProvider>
          </MatomoUserTracker>
        </React.StrictMode>
      </KeycloakConfigProvider>
    );
  }
}
