import React, { lazy, memo, Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import ProtectedRoute from "src/lib/ProtectedRoute";
import FullPageWrapper from "src/components/widgets/FullPageWrapper";
import { Loader } from "ui-core";

const Overview = lazy(
  () =>
    import(
      /* webpackChunkName: "overview", webpackPreload: true */ "../Overview/Overview"
    )
);
const PortfolioBuilding = lazy(
  () =>
    import(
      /* webpackChunkName: "portfolio-building" */ "../Portfolio/PortfolioBuilding"
    )
);
const DataExport = lazy(
  () => import(/* webpackChunkName: "data-export" */ "../DataExport/DataExport")
);
const Portfolio = lazy(
  () => import(/* webpackChunkName: "portfolio" */ "../Portfolio/Portfolio")
);
const PortfolioSite = lazy(
  () =>
    import(
      /* webpackChunkName: "portfolio-site" */ "../Portfolio/PortfolioSite"
    )
);
const Markets = lazy(
  () => import(/* webpackChunkName: "markets" */ "../Markets/Markets")
);
const Submetering = lazy(
  () =>
    import(/* webpackChunkName: "submetering" */ "../Submetering/Submetering")
);

function Routes() {
  return (
    <Suspense
      fallback={
        <FullPageWrapper>
          <Loader text="Rendering page..." />
        </FullPageWrapper>
      }
    >
      <Switch>
        {/* <Route exact path="/silent-login" component={SilentLogin} /> */}
        <Redirect exact from="/" to="/overview" />
        <ProtectedRoute exact path="/overview" component={Overview} />
        <ProtectedRoute exact path="/portfolio" component={Portfolio} />
        <ProtectedRoute
          exact
          path="/portfolio/:siteId"
          component={PortfolioSite}
        />
        <ProtectedRoute
          exact
          path="/portfolio/:siteId/markets"
          component={Markets}
        />
        <ProtectedRoute
          exact
          path="/portfolio/:siteId/:buildingId"
          component={PortfolioBuilding}
        />
        <ProtectedRoute
          exact
          path="/portfolio/:siteId/:buildingId/submetering"
          component={Submetering}
        />
        <ProtectedRoute exact path="/data" component={DataExport} />
      </Switch>
    </Suspense>
  );
}

export default memo(Routes);
