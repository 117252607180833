import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { TooltipIcon } from "@elements/TooltipIcon";
import ContentLoader from "react-content-loader";
import { Error } from "@utility/Error";
import { Loader } from "@utility/Loader";
import TimeIntervalSwitch, {
  DateRangeType,
  DateRangeParam,
  IntervalType,
} from "./TimeIntervalSwitch";
import { format, formatDuration, intervalToDuration } from "date-fns";
import { isNumber, uniqueId } from "lodash-es";
import { WidgetHeader } from "@utility/Widget";
import { useResizeDetector } from "react-resize-detector";
import { getLocaleNumber, getLocaleUSDCurrency } from "src/lib/utils";
import {
  Accordion,
  Alert,
  Badge,
  BadgeProps,
  Button,
  Progress,
  Spinner,
  Tooltip,
} from "@bphxd/ds-core-react";
import {
  Alert24,
  Check16,
  Check24,
  Info16,
  Right16,
  TailRight16,
} from "@bphxd/ds-core-react/lib/icons";

export interface MarketsCardProps {
  /**
   * text header at the top of the card
   */
  widgetTitle: string;
  /**
   * text description to be displayed in the "more info" hover tooltip
   */
  widgetInfoText: string;
  /**
   * loading state triggers spinner effects
   */
  loading?: boolean;
  /**
   * error message to display
   */
  error?: string;
  /**
   * total market revenue
   */
  totalRevenue?: number;
  /**
   * optional entry of the monthly revenue
   */
  monthlyRevenue?: number;
  /**
   * optional entry of the monthly revenue
   */
  dateRange?: DateRangeType;
  /**
   * onChange callback for the date range picker
   */
  onDateRangeChange: (dateRange: DateRangeParam) => void;
  /**
   * optional date format string for market events; default: "M/d/yyyy"
   */
  dateFormat?: string;
  /**
   * optional date format map for time switch interval
   */
  dateFormatMap?: Record<string, string>;
  /**
   * optional time format string for market events; default: "h:mm aaa"
   */
  timeFormat?: string;
  /**
   * optional array of market data objects; default: "h:mm aaa"
   */
  markets?: Array<Market>;
  /**
   * Include a button to link to the Markets page?
   */
  showMarketsButton?: boolean;
  /**
   * onClick callback for the markets button
   */
  maxDate?: Date;
  onMarketsButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /**
   * Language used for number formating, defaults to user's browser language
   */
  locale?: string;
  /**
   * Boolean to specify whether to open datepicker as modal or by default as popup
   */
  showDatePickerAsModal?: boolean;
  /**
   * ID of the market which should be opened by default in the markets accordion
   */
  initialMarketId?: string;
}

export type MarketEventItemProps = MarketEvent;

type Market = {
  id: string;
  title: string;
  events?: React.ReactNodeArray;
  revenue?: number;
  reservationPayment?: number;
  reservationPaymentTrueUpExplanation?: boolean;
  performancePayment?: number;
  performanceFactor?: number;
  locale?: string;
};

export enum MarketEventStatus {
  Announced = "Announced",
  Confirmed = "Confirmed",
  Acknowledged = "Acknowledged",
  Cancelled = "Cancelled",
  Closed = "Closed",
}

export const eventStatusVariantMap = new Map<
  MarketEventStatus,
  BadgeProps["color"]
>([
  [MarketEventStatus.Announced, "info"],
  [MarketEventStatus.Confirmed, "success"],
  [MarketEventStatus.Acknowledged, "dark"],
  [MarketEventStatus.Cancelled, "danger"],
  [MarketEventStatus.Closed, "light"],
]);

export type LabelWithDescription = {
  label: string;
  descriptionLines: string[];
};

type MarketEvent = {
  id: string;
  locale?: string;
  baseline?: number;
  response?: number;
  targetLoad?: number;
  performancePayment?: number;
  endTime?: Date;
  startTime: Date;
  dateFormat?: string;
  dateFormatMap?: Record<string, string>;
  timeFormat?: string;
  siteName?: string;
  buildingName?: string;
  eventStatus: MarketEventStatus;
  onEventClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onStatusClick?: (event: React.MouseEvent<HTMLElement>) => void;
  loading?: boolean;
  success?: boolean;
  error?: boolean;
  successMessage?: string;
  errorMessage?: string;
  unit?: string;
  eventCategory?: LabelWithDescription;
};

const performanceFactorDescription =
  "Performance factor is the monthly average response of a site normalized by the pledged reduction for all mandatory DR events and is used to determine the reservation payment for the program. The performance factor of a month with DR events may be used in determining the reservation payment for months where no DR events are called.";

const MarketItem = ({
  title,
  events,
  revenue,
  reservationPayment,
  reservationPaymentTrueUpExplanation = false,
  performancePayment,
  performanceFactor,
  locale = navigator.language,
}: Market) => {
  const eventsCount = !events ? 0 : events.length;

  return (
    <div
      aria-label="Market list item"
      className="position-relative w-100 pe-7"
      role="listitem"
    >
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="pb-3" style={{ minWidth: "8rem" }}>
          <div aria-label="Market item title" className="pb-2 fs-base">
            {title}
          </div>
          {performanceFactor && performanceFactor > 0 ? (
            <div>
              <div className="pb-1 d-flex align-items-center">
                <span
                  className="text-nowrap fs-6 fw-light text-secondary lh-sm pe-1"
                  title={performanceFactorDescription}
                >
                  Performance factor
                </span>
                <TooltipIcon
                  icon={<Info16 />}
                  title={performanceFactorDescription}
                  placement="right"
                />
              </div>
              <div className="d-flex align-items-center">
                <div className="fs-base lh-sm pe-2">{`${
                  Math.round(performanceFactor * 10) / 10
                }%`}</div>
                <div style={{ minWidth: "5.5rem" }}>
                  <Progress color="light" value={performanceFactor} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex-1 pb-3">
          <div className="d-flex flex-column align-items-center">
            {typeof revenue === "number" && (
              <div
                aria-label="Market item revenue amount"
                className="fw-medium fs-base text-nowrap text-center pb-1"
              >
                {revenue > 0 ? (
                  <span className="text-success">+ </span>
                ) : revenue < 0 ? (
                  <span className="text-danger">- </span>
                ) : null}
                {getLocaleUSDCurrency(locale, revenue)}
              </div>
            )}

            <div className="d-flex px-3">
              {typeof reservationPayment === "number" && (
                <div className="border-end border-primary text-end pe-3">
                  <div className="fs-6 fw-light text-secondary lh-sm pb-1">
                    Reservation
                  </div>
                  <div
                    className={`fs-6 lh-sm d-flex text-nowrap d-flex align-items-center`}
                  >
                    {reservationPaymentTrueUpExplanation && (
                      <TooltipIcon
                        icon={<Info16 />}
                        title={
                          "Because there has been no events, this revenue is determined based on the previous season’s performance factor (for sites that were not previously enrolled, performance factor is assumed to be 50%) and the true-up revenue will be adjusted once the first event occurs"
                        }
                        placement="right"
                        className="pe-1"
                      />
                    )}
                    <span>
                      {getLocaleUSDCurrency(locale, reservationPayment)}
                    </span>
                  </div>
                </div>
              )}
              {typeof performancePayment === "number" && (
                <div className="ps-3">
                  <div className="fs-6 fw-light text-secondary lh-sm pb-1">
                    Performance
                  </div>
                  <div className="fs-6 lh-sm text-nowrap">
                    {getLocaleUSDCurrency(locale, performancePayment)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {events && (
          <div className="position-absolute end-0 top-0 d-flex flex-column h-100 justify-content-center">
            <div className="fs-6 fw-light text-secondary lh-sm">Events</div>
            <div className="d-flex align-items-center justify-content-end pb-3">
              <div className="pe-1 fs-base">
                <Badge color="dark">{eventsCount}</Badge>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const formatEventDurationAsString = (startTime: Date, endTime: Date) => {
  const durationObject = intervalToDuration({
    start: startTime,
    end: endTime,
  });
  const { years, months, weeks, days, hours = 0 } = durationObject;

  if (!years && !months && !weeks && days && days === 1) {
    durationObject.days = 0;
    durationObject.hours = 24 + hours;
  }

  const durationString = formatDuration(durationObject, {
    format: ["years", "months", "weeks", "days", "hours", "minutes"],
    delimiter: " ",
  })
    .replace(/ years?\b/, "y")
    .replace(/ months?\b/, "m")
    .replace(/ weeks?\b/, "w")
    .replace(/ days?\b/, "d")
    .replace(/ hours?\b/, "h")
    .replace(/ minutes?\b/, "m");

  return durationString;
};

export const MarketEventItem = memo(
  ({
    id,
    baseline,
    targetLoad,
    response,
    startTime,
    endTime,
    performancePayment,
    dateFormat = "M/dd/yyyy",
    eventCategory,
    timeFormat = "h:mm aaa",
    siteName,
    buildingName,
    eventStatus,
    onEventClick,
    onStatusClick,
    loading = false,
    success = false,
    successMessage = "You have successfully acknowledged this event.",
    error = false,
    errorMessage = "Event acknowledgment failed! Please try again.",
    locale = navigator.language,
    unit = "kW",
  }: MarketEventItemProps) => {
    const { width, ref } = useResizeDetector();
    const isCompact = width && width <= 730;
    const showActionRow =
      eventStatus === MarketEventStatus.Announced || success || error;
    const durationString =
      startTime && endTime && formatEventDurationAsString(startTime, endTime);

    const eventCategoryId = useMemo(() => uniqueId("category-tooltip"), []);

    return (
      <div
        ref={ref}
        aria-label="Market event list item"
        className="market-event border-bottom border-secondary"
      >
        <div
          className={`d-flex ${isCompact ? "flex-column" : ""} px-1`}
          role="listitem"
        >
          <div
            className={`d-flex flex-column flex-1 ${
              isCompact ? "border-bottom" : "border-end"
            }`}
          >
            <div className="border-bottom d-flex x5-px-4 py-3 justify-content-between flex-wrap">
              <div className="d-flex">
                <div aria-label="Market event start date" className="pe-1">
                  <div className="fs-6 fw-light text-secondary lh-sm pb-1">
                    <span>Event start</span>
                  </div>
                  <div className="fs-6 lh-sm">
                    {format(startTime, dateFormat)}{" "}
                    {format(startTime, timeFormat)}
                  </div>
                </div>

                <div className="d-flex align-items-center x5-px-2">
                  <div
                    aria-label="Market event duration"
                    className="market-event__duration text-nowrap"
                  >
                    <span className="text-nowrap">
                      {durationString || "---"}
                    </span>
                  </div>
                </div>

                <div aria-label="Market event end date" className="ps-1">
                  <div className="fs-6 fw-light text-secondary lh-sm pb-1">
                    <span>Event end</span>
                  </div>
                  <div className="fs-6 lh-sm">
                    {endTime &&
                      `${format(endTime, dateFormat)} 
                    ${format(endTime, timeFormat)}`}

                    {!endTime && (
                      <span className="animate-pulse-text-info">
                        TO BE ANNOUNCED
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {eventCategory ? (
                <div className="pt-3">
                  <Badge color="info-subtle" id={eventCategoryId}>
                    <Info16 />
                    {eventCategory.label}
                  </Badge>
                  <Tooltip fade={false} target={eventCategoryId}>
                    {eventCategory.descriptionLines.map((line, i) => (
                      <div key={i}>{line}</div>
                    ))}
                  </Tooltip>
                </div>
              ) : null}
            </div>
            <div className="d-flex x5-px-4 py-3">
              <div className="flex-1 d-flex justify-content-between">
                <div className="flex-1">
                  <div className="fs-6 lh-sm fw-light text-secondary lh-sm pb-1">
                    <span>Site/Building</span>
                  </div>
                  <div className="fs-6 lh-sm">
                    {!siteName && !buildingName ? (
                      "--- "
                    ) : (
                      <div>
                        {siteName} /{" "}
                        {!onEventClick ? (
                          buildingName
                        ) : (
                          <a
                            className="link-border"
                            role="button"
                            onClick={onEventClick}
                          >
                            {buildingName}
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {onEventClick ? (
                  <div className="d-flex align-items-center">
                    <Button
                      aria-label="Market event building detail"
                      size="sm"
                      rounded="0"
                      level="tertiary"
                      className="bg-white"
                      onClick={onEventClick}
                      iconPosition="end"
                      Icon={Right16}
                    >
                      See event
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-1">
            <div className="d-flex flex-1">
              <div
                aria-labelledby="baselineLabel"
                className="border-bottom border-end d-flex flex-1 x5-px-4 py-3"
              >
                <div>
                  <div
                    className="fs-6 fw-light text-secondary lh-sm pb-1 text-nowrap"
                    id="baselineLabel"
                  >
                    <span>Baseline load</span>
                  </div>
                  <div className="fs-base lh-sm d-flex flex-wrap align-items-baseline">
                    {isNumber(baseline)
                      ? getLocaleNumber(locale, baseline)
                      : "--- "}
                    <span className="fs-6 fw-light ps-1">{unit}</span>
                  </div>
                </div>
              </div>

              <div
                aria-labelledby="tagetLoadLabel"
                className="border-bottom border-end d-flex flex-1 x5-px-4 py-3"
              >
                <div>
                  <div
                    className="fs-6 fw-light text-secondary lh-sm pb-1 text-nowrap"
                    id="tagetLoadLabel"
                  >
                    <span>Target load</span>
                  </div>
                  <div className="fs-base lh-sm d-flex flex-wrap align-items-baseline">
                    {isNumber(targetLoad)
                      ? getLocaleNumber(locale, targetLoad)
                      : "--- "}
                    <span className="fs-6 fw-light ps-1">{unit}</span>
                  </div>
                </div>
              </div>

              <div
                aria-labelledby="reductionLabel"
                className="border-bottom d-flex flex-1 x5-px-4 py-3"
              >
                <div>
                  <div
                    className="fs-6 fw-light text-secondary lh-sm pb-1 text-nowrap"
                    id="reductionLabel"
                  >
                    <span>Actual reduction</span>
                  </div>
                  <div className="fs-base lh-sm d-flex flex-wrap align-items-baseline">
                    {isNumber(response)
                      ? getLocaleNumber(locale, response)
                      : "--- "}
                    <span className="fs-6 fw-light ps-1">{unit}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-1">
              <div
                aria-labelledby="performancePaymentLabel"
                className="border-end d-flex flex-1 x5-px-4 py-3"
              >
                <div>
                  <div
                    className="fs-6 fw-light text-secondary lh-sm pb-1"
                    id="performancePaymentLabel"
                  >
                    <span>Perfomance payment</span>
                  </div>
                  <div className="fs-base lh-sm">
                    {isNumber(performancePayment) ? (
                      <span>
                        {performancePayment > 0 ? (
                          <span className="text-success">+ </span>
                        ) : performancePayment < 0 ? (
                          <span className="text-danger">- </span>
                        ) : null}
                        {getLocaleUSDCurrency(locale, performancePayment)}
                      </span>
                    ) : (
                      "---"
                    )}
                  </div>
                </div>
              </div>

              <div
                aria-labelledby="eventStatusLabel"
                className="d-flex flex-1 x5-px-4 py-3"
              >
                <div>
                  <div
                    className="fs-6 fw-light text-secondary lh-sm pb-1"
                    id="eventStatusLabel"
                  >
                    <span>Event status</span>
                  </div>
                  <Badge color={eventStatusVariantMap.get(eventStatus)}>
                    {eventStatus}
                  </Badge>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showActionRow && (
          <div className="border-top d-flex flex-wrap justify-content-end align-items-center x5-px-4 py-4">
            {success && successMessage && !loading && (
              <Alert
                color="success"
                shadow={false}
                Icon={Check24}
                className="my-3"
              >
                {successMessage}
              </Alert>
            )}
            {error && errorMessage && !loading && (
              <Alert
                color="danger"
                shadow={false}
                Icon={Alert24}
                className="my-3"
              >
                {errorMessage}
              </Alert>
            )}
            {loading ? <Spinner size="sm" className="ms-3" /> : null}
            {eventStatus === MarketEventStatus.Announced && (
              <div className="ms-3">
                <Button
                  level="primary"
                  rounded="0"
                  disabled={loading}
                  size="sm"
                  onClick={onStatusClick}
                  aria-label="Acknowledge Market Event"
                  Icon={Check16}
                >
                  Acknowledge
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

export const MarketsCard = memo((props: MarketsCardProps) => {
  const {
    loading,
    error,
    dateRange = {
      intervalType: IntervalType.Month,
      startDate: new Date(),
      endDate: null,
    },
    onDateRangeChange,
    dateFormatMap,
    timeFormat = "HH:mm",
    widgetTitle,
    widgetInfoText,
    totalRevenue = 0,
    monthlyRevenue,
    markets,
    showMarketsButton,
    maxDate,
    onMarketsButtonClick = () => null,
    locale = navigator.language,
    showDatePickerAsModal,
    initialMarketId,
  } = props;

  const [accordionOpenId, setAccordionOpenId] = useState<string | undefined>(
    undefined
  );

  const listRef = useRef<HTMLDivElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const handleToggleOpen = (id: string) => {
    if (accordionOpenId !== id) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        const el = document.getElementById(`accordion-body-${id}`);
        const itemRect = el?.getBoundingClientRect();
        const listRect = listRef?.current?.getBoundingClientRect();

        // NOTE: In case the market is expanded at the bottom of the list, and the DR event can not be seen, because it is overflowing, then scroll the event into view.
        if (
          itemRect &&
          listRect &&
          itemRect.top > listRect.top + listRect.height - 40 // Offset bottom of the list by 40px, so that we have sufficient space to see if something has been expanded.
        ) {
          el?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }, 300);
    }

    setAccordionOpenId((currentId) => (currentId === id ? undefined : id));
  };

  const defaultOpenAccordionId = markets?.some(
    ({ id }) => initialMarketId === id
  )
    ? initialMarketId
    : undefined;

  return (
    <div className="markets-widget shadow-sm bg-white h-100 w-100 d-flex flex-column overflow-hidden">
      <WidgetHeader title={widgetTitle} infoText={widgetInfoText} />
      {error ? (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Error text={error} />
        </div>
      ) : (
        <>
          {loading || (markets && markets.length) ? (
            <div className="x5-px-4 py-4 border-bottom border-primary d-flex flex-wrap-reverse justify-content-between">
              {loading ? (
                <ContentLoader
                  width={120}
                  height={48}
                  uniqueKey="markets-content-loader"
                >
                  <rect x="0" y="0" rx="0" ry="0" width="120" height="26" />
                  <rect x="0" y="34" rx="0" ry="0" width="100" height="16" />
                </ContentLoader>
              ) : (
                <div className="d-flex flex-wrap mb-n3">
                  <div
                    aria-label="Total market participation revenue sum"
                    className="pe-4 pb-3"
                  >
                    <div className="fs-base lh-base fw-medium">
                      {getLocaleUSDCurrency(locale, totalRevenue)}
                    </div>
                    <div className="fs-6 lh-sm text-secondary fw-light">
                      All markets total
                    </div>
                  </div>
                  {isNumber(monthlyRevenue) ? (
                    <div
                      aria-label="Monthly market participation revenue sum"
                      className="pe-4 pb-3"
                    >
                      <div className="fw-medium fs-base lh-base">
                        {getLocaleUSDCurrency(locale, monthlyRevenue)}
                      </div>
                      <div className="fs-6 lh-sm text-secondary fw-light">
                        {format(dateRange.startDate, "MMMM")} Markets total
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              <div
                aria-label="Market participation month selector"
                className="flex-1 d-flex justify-content-end align-items-start"
              >
                <TimeIntervalSwitch
                  dateRange={dateRange}
                  dateFormatMap={dateFormatMap}
                  timeFormat={timeFormat}
                  onChange={onDateRangeChange}
                  loading={loading}
                  intervals={null}
                  maxDate={maxDate}
                  showDatePickerAsModal={showDatePickerAsModal}
                />
              </div>
            </div>
          ) : (
            <div className="fs-6 fw-light text-secondary lh-sm d-flex justify-content-center align-items-center h-100">
              No markets available
            </div>
          )}
          <div
            aria-label="Market list"
            className="overflow-auto scroll-gradient flex-grow-1 position-relative"
            role="list"
            ref={listRef}
          >
            {markets && markets.length > 0 ? (
              <Accordion
                square
                open={accordionOpenId}
                onToggleOpen={handleToggleOpen}
                defaultOpen={defaultOpenAccordionId}
              >
                {markets.map((market) => (
                  <Accordion.Item
                    key={`${market.id}-market`}
                    aria-label="Market item content"
                  >
                    <Accordion.Header
                      targetId={market.id}
                      className="border-end-0 border-start-0"
                    >
                      <MarketItem {...market} locale={locale} />
                    </Accordion.Header>
                    <Accordion.Body
                      accordionId={market.id}
                      className="bg-secondary p-0"
                      id={`accordion-body-${market.id}`}
                    >
                      {Array.isArray(market.events) &&
                      market.events.length > 0 ? (
                        <>{market.events}</>
                      ) : (
                        <div className="text-secondary fs-6 fw-light text-center xp-px-4 py-4">
                          No events for selected period
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            ) : loading ? (
              <Loader text="Loading Markets..." size="sm" overlay />
            ) : null}
          </div>
          {showMarketsButton && (
            <div className="border-top d-flex justify-content-end flex-shrink-0 x5-px-4 py-3">
              <Button
                size="sm"
                rounded="0"
                onClick={onMarketsButtonClick}
                Icon={TailRight16}
                iconPosition="end"
              >
                Markets detail
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
});
