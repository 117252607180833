import { memo, useEffect, useState, useCallback } from "react";
import useWindowDimensions from "src/lib/useWindowDimensions";
import { Button } from "@bphxd/ds-core-react";
import { Up24 } from "@bphxd/ds-core-react/lib/icons";

type ScrollTopButtonProps = {
  onVisibilityChange?: (visible: boolean) => void;
};

export default memo(function ScrollTopButton(props: ScrollTopButtonProps) {
  const { onVisibilityChange = () => null } = props;
  const [scrollTop, setScrollTop] = useState(0);

  const { innerHeight } = useWindowDimensions();

  useEffect(() => {
    // TODO:
    const onScroll = (e: Event) => {
      if (e.target) {
        // @ts-expect-error TODO: Check the validity of this type
        setScrollTop(e.target.documentElement.scrollTop);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const isVisible = scrollTop * 1.2 > innerHeight;

  useEffect(() => {
    onVisibilityChange(isVisible);
  }, [isVisible, onVisibilityChange]);

  return (
    <Button
      onClick={scrollToTop}
      size="lg"
      rounded="circle"
      className={`scroll-top-button m-4 position-fixed right-0 ${
        isVisible ? "" : "hidden"
      }`}
      iconOnly
      Icon={Up24}
      aria-label="Scroll to Top"
    />
  );
});
