/** @format */

import { memo, useCallback, useMemo } from "react";
import range from "lodash-es/range";
import { getYear, getMonth, getDate } from "date-fns";
import ReactDatePicker, {
  type DatePickerProps as ReactDatePickerProps,
} from "react-datepicker";
import { Button, Input, InputGroup } from "@bphxd/ds-core-react";
import { Left24, Right24 } from "@bphxd/ds-core-react/lib/icons";

export type DatePickerProps = Omit<
  ReactDatePickerProps,
  "selectsMultiple" | "selectsRange" | "showMonthYearDropdown"
> & {
  defaultHeader?: boolean;
  showHeaderMonthSelect?: boolean;
  showHeaderYearSelect?: boolean;
  onChange?: (date: Date | null) => void;
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DatePicker = memo((props: DatePickerProps) => {
  const {
    defaultHeader = false,
    showHeaderMonthSelect = true,
    showHeaderYearSelect = true,
    startDate,
    endDate,
    minDate,
    maxDate,
    selectsEnd,
    ...rest
  } = props;
  const years = useMemo(
    () =>
      range(
        minDate ? getYear(minDate) : 1950,
        getYear(maxDate || new Date()) + 1,
        1
      ),
    [maxDate, minDate]
  );

  const renderCustomHeader = useCallback(
    ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      decreaseYear,
      increaseYear,
      nextYearButtonDisabled,
      prevYearButtonDisabled,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    }) => {
      return (
        <div className="mb-3">
          <InputGroup size="sm">
            <Button
              level="quartenary"
              onClick={showHeaderMonthSelect ? decreaseMonth : decreaseYear}
              disabled={
                showHeaderMonthSelect
                  ? prevMonthButtonDisabled
                  : prevYearButtonDisabled
              }
              Icon={Left24}
              iconOnly
            />
            {showHeaderMonthSelect && (
              <Input
                className="fs-6 fw-medium text-center border-0"
                type="select"
                value={months[getMonth(date)]}
                onChange={({ currentTarget: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Input>
            )}
            {showHeaderYearSelect && (
              <Input
                style={{
                  width: !showHeaderMonthSelect ? "auto" : undefined,
                  flex: !showHeaderMonthSelect ? "none" : undefined,
                }}
                className="fs-6 fw-medium text-center border-0 mx-auto"
                type="select"
                value={getYear(date)}
                onChange={({ currentTarget: { value } }) =>
                  changeYear(parseInt(value))
                }
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Input>
            )}
            {!showHeaderMonthSelect && !showHeaderYearSelect && (
              <div className="fs-6 fw-medium flex-1 d-flex align-items-center justify-content-center">
                {/* TODO: */}
                {`${years[0]} - ${years[years.length - 1]}`}
              </div>
            )}

            <Button
              level="quartenary"
              onClick={showHeaderMonthSelect ? increaseMonth : increaseYear}
              disabled={
                showHeaderMonthSelect
                  ? nextMonthButtonDisabled
                  : nextYearButtonDisabled ||
                    getYear(date) === getYear(maxDate || new Date()) // nextYearButtonDisabled, not working correctly, manual check needed
              }
              Icon={Right24}
              iconOnly
            />
          </InputGroup>
        </div>
      );
    },
    [maxDate, showHeaderMonthSelect, showHeaderYearSelect, years]
  );

  const renderDayContents = (_, date) => {
    // Note: span element required for css styling
    return <span>{getDate(date)}</span>;
  };

  return (
    <ReactDatePicker
      {...rest}
      calendarClassName="bp-datepicker"
      renderDayContents={renderDayContents}
      minDate={minDate}
      maxDate={maxDate}
      startDate={startDate}
      endDate={endDate}
      selected={selectsEnd ? endDate : startDate}
      selectsEnd={selectsEnd}
      renderCustomHeader={!defaultHeader ? renderCustomHeader : undefined}
    />
  );
});

export default DatePicker;
