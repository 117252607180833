/** @format */

import { Spinner, SpinnerProps } from "@bphxd/ds-core-react";
import React, { memo } from "react";

export interface LoaderProps {
  text?: string;
  size?: SpinnerProps["size"];
  overlay?: boolean;
}

export const Loader = memo((props: LoaderProps) => {
  const { text = "Loading...", size, overlay = false } = props;

  return (
    <div
      className={`d-flex align-items-center justify-content-center p-4 text-center ${
        overlay ? "position-absolute top-0 start-0 end-0 bottom-0" : ""
      }`}
    >
      {overlay ? (
        <div className="position-absolute top-0 start-0 end-0 bottom-0 bg-overlay-blur z-1" />
      ) : null}
      <div className="d-flex flex-column align-items-center position-relative z-2">
        <Spinner size={size} label={text} labelDelay={500} />
      </div>
    </div>
  );
});
