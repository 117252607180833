/** @format */
import L from "leaflet";

import markerIcon from "./marker.svg";
import markerSelectedIcon from "./marker-selected.svg";
import markerUnselectedIcon from "./marker-unselected.svg";
import markerShadowIcon from "./marker-shadow.svg";

export const markerLIcon = new L.Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadowIcon,
  iconSize: new L.Point(32, 32),
  shadowSize: new L.Point(32, 32),
  iconAnchor: [14, 40],
  shadowAnchor: [14, 40],
  popupAnchor: [0, -40],
});

export const markerSelectedLIcon = new L.Icon({
  iconUrl: markerSelectedIcon,
  shadowUrl: markerShadowIcon,
  iconSize: new L.Point(32, 32),
  shadowSize: new L.Point(32, 32),
  iconAnchor: [14, 40],
  shadowAnchor: [14, 40],
  popupAnchor: [0, -40],
});

export const markerUnselectedLIcon = new L.Icon({
  iconUrl: markerUnselectedIcon,
  shadowUrl: markerShadowIcon,
  iconSize: new L.Point(32, 32),
  shadowSize: new L.Point(32, 32),
  iconAnchor: [14, 40],
  shadowAnchor: [14, 40],
  popupAnchor: [0, -40],
});
