import React, { Component } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  InMemoryCacheConfig,
  from,
  NextLink,
  NormalizedCacheObject,
  Operation,
  ServerError,
  ServerParseError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { RestLink } from "apollo-link-rest";
import camelCase from "lodash-es/camelCase";
import snakeCase from "lodash-es/snakeCase";

type EnhancedApolloProviderProps = React.PropsWithChildren<{
  onAuthError: (
    networkError: ServerError | ServerParseError,
    operation: Operation,
    forward: NextLink
  ) => void;
  onAuthRequest: () => { token?: string };
  onInit?: (client: ApolloClient<NormalizedCacheObject>) => void;
  cacheConfig?: InMemoryCacheConfig;
  uri: string;
}>;

function isNetworkErrorWithStatusCode(
  networkError: Error | ServerError | ServerParseError
): networkError is ServerError | ServerParseError {
  // @ts-expect-error This won't break but TS won't let it pass
  if (!networkError?.statusCode) {
    return false;
  }
  return true;
}

class EnhancedApolloProvider extends Component<EnhancedApolloProviderProps> {
  client: ApolloClient<NormalizedCacheObject>;

  constructor(props: EnhancedApolloProviderProps) {
    super(props);

    const { onAuthRequest, uri, cacheConfig, onAuthError, onInit } = props;

    const restLink = new RestLink({
      // Alias for the v2 endpoint that is then used in the queries/mutations
      endpoints: { v2: `${uri}/v2` },
      /**
       * Since the convention is to use camel case in JS we
       * convert the fields in the request to snake case and
       * then convert the fields in the response back to camel case.
       */
      fieldNameDenormalizer: (key) => snakeCase(key),
      fieldNameNormalizer: (key) => camelCase(key),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      uri,
    });

    /**
     * Middleware for adding auth to the headers
     */
    const authLink = setContext((_request, { headers }) => {
      const { token } = onAuthRequest();

      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token || ""}`,
        },
      };
    });

    /**
     * Middleware for error handling - it is also used for refreshing the token after a 401
     */
    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );

        if (networkError) {
          console.log(`[Network error]: ${networkError}`);

          if (
            isNetworkErrorWithStatusCode(networkError) &&
            networkError.statusCode === 401
          ) {
            // Handler that usually triggers re-authentication (but it is fully configurable)
            return onAuthError(networkError, operation, forward);
          }
        }
      }
    );

    this.client = new ApolloClient({
      cache: new InMemoryCache(cacheConfig),
      // We can implement retryLink if a need for it would arise
      link: from([/* retryLink, */ errorLink, authLink, restLink]),
      connectToDevTools: true,
    });

    if (typeof onInit === "function") onInit(this.client);
  }

  render() {
    const { children } = this.props;

    return <ApolloProvider client={this.client}>{children}</ApolloProvider>;
  }
}

export default EnhancedApolloProvider;
