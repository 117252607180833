import { memo } from "react";
import {
  NotificationItem,
  NotificationItemProps,
} from "../elements/NotificationItem";
import { ListGroup } from "@bphxd/ds-core-react";

export interface NotificationListProps {
  /**
   * List of Notifications
   */
  data: NotificationItemProps[];
  /**
   * Called when a notification read/unread flag is clicked. Returning notification ID.
   */
  onToggleRead?: (notificationId: string, read: boolean) => void;
  /**
   * Date format for notification timestamp tooltip (days months years)
   */
  dateFormat?: string;
  /**
   * Time format for notification timestamp tooltip
   */
  timeFormat?: string;
}

export const NotificationList = memo((props: NotificationListProps) => {
  const {
    data,
    onToggleRead,
    dateFormat = "MMM dd",
    timeFormat = "HH:mm",
  } = props;

  return (
    <ListGroup className="notifications-list" tag="div" twoTier>
      {data.map((notification) => {
        const {
          id,
          title,
          message,
          link,
          timestamp,
          read = false,
          onClick,
        } = notification;

        return (
          <NotificationItem
            id={id}
            key={id}
            title={title}
            message={message}
            link={link}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            timestamp={timestamp}
            read={read}
            onChange={onToggleRead}
            onClick={onClick}
          />
        );
      })}
    </ListGroup>
  );
});
